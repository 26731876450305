export default {
  state: {
    dd_name: '',
    dd_opened: false,
    private_flag: false,

    overlay: false,
    position_target: null, //данные для позиционирования попапа

    dd_messages: null,
    dd_chats: null,
    dd_letters: null,

    dd_notifications: null,
    dd_favorites: null,
  },
  mutations: {
    SetDDData(state, data) {
      console.log('SetDDData: ', data);
      if (data.dd_name) {
        state.dd_name = data.dd_name;
      }
      if (data.dd_opened !== undefined) {
        state.dd_opened = data.dd_opened;
        state.overlay = data.dd_opened;
      }
      if (data.private_flag !== undefined) {
        state.private_flag = data.private_flag;
      }
      if (data.position_target !== undefined) {
        state.position_target = {};
        state.position_target = {...data.position_target};
      }

      if (data.dd_messages !== undefined) {

        state.dd_messages = [];
        if (data.dd_messages.chats !== undefined) {
          state.dd_chats = data.dd_messages.chats.map((e) => {
            const r = e;
            r.type = 'chat'
            return r;
          })
        }
        if (state.dd_chats) state.dd_messages = state.dd_messages.concat(state.dd_chats);

        if (data.dd_messages.letters !== undefined) {
          state.dd_letters = data.dd_messages.letters.map((e) => {
            const r = e;
            r.type = 'letter'
            return r;
          })
        }
        if (state.dd_letters) state.dd_messages = state.dd_messages.concat(state.dd_letters);

        state.dd_messages.sort((a, b) => {
          if (a._created_at > b._created_at) {
            return -1
          }
          if (a._created_at < b._created_at) {
            return 1
          }
          if (a._created_at === b._created_at) {
            return 0
          }
        })
      }

      // if (data.dd_messages_update !== undefined) {
      //   state.dd_messages = Array.from(data.dd_messages_update);
      /*if (state.dd_messages === undefined || state.dd_messages === null) {
        state.dd_messages = [];
      }

      state.dd_messages = state.dd_messages.filter(e => e.type !== data.dd_messages_update.type);

      if (data.dd_messages_update.items && data.dd_messages_update.items.length > 0) {
        state.dd_messages = state.dd_messages.concat(data.dd_messages_update.items);
      }
      state.dd_messages.sort((a, b) => {
        if (a._created_at > b._created_at) {
          return -1
        }
        if (a._created_at < b._created_at) {
          return 1
        }
        if (a._created_at === b._created_at) {
          return 0
        }
      })*/
      // }

      if (data.dd_message_del !== undefined) {
        if (state.dd_messages && state.dd_messages.length > 0) {
          state.dd_messages.forEach((value, index) => {
            if (data.dd_message_del.type === 0) {
              if (value.id === data.dd_message_del.id) {
                state.dd_messages.splice(index, 1);
              }
            }
            if (data.dd_message_del.type === 1) {
              if (value.id === data.dd_message_del.id) {
                state.dd_messages.splice(index, 1);
              }
            }
            if (data.dd_message_del.type === 2) {
              if (value.users_letters_id === data.dd_message_del.id) {
                state.dd_messages.splice(index, 1);
              }
            }
          });
        }
      }


      if (data.dd_favorites !== undefined) {
        state.dd_favorites = [];
        state.dd_favorites = state.dd_favorites.concat(data.dd_favorites);
      }
      if (data.dd_favorite !== undefined) {
        if (state.dd_favorites === undefined || state.dd_favorites === null) {
          state.dd_favorites = [];
        }

        let finded = false;
        state.dd_favorites.forEach((value, index) => {
          if (value.user_id === data.dd_favorite.user_id) {
            state.dd_favorites[index] = {};
            state.dd_favorites[index] = {...state.dd_favorites[index], ...data.dd_favorite};
            finded = true;
          }
        });

        if (!finded) {
          state.dd_favorites.push(data.dd_favorite);
        }
      }
      if (data.dd_favorite_del !== undefined) {
        if (state.dd_favorites && state.dd_favorites.length > 0) {
          state.dd_favorites.forEach((value, index) => {
            if (value.user_id === data.dd_favorite_del) {
              state.dd_favorites.splice(index, 1);
            }
          });
        }
      }


      /*if ( data.dd_notification_data !== undefined ) {
        if ( state.user_data !== undefined && state.user_data !== null ) {
          if ( !state.user_data.notification ) {
            state.user_data.notification = [];
          }
          state.user_data.notification.push(data.dd_notification_data);
        }
      }*/

    },
    ClearDDMessages(state) {
      state.dd_messages = null;
    }
  },
  actions: {},
  getters: {}
}
