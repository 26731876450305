export default {
  state: {
    alerts_stack: [],
  },
  mutations: {
    SetAlertsData(state, data) {
      console.log('SetAlertsData:', {...data})
      if (data.alert !== undefined) {
        state.alerts_stack.push({...data.alert, ...{id: (Date.now() + Math.random())}});
      }
      if (data.show_id !== undefined) {
        state.alerts_stack.find((e) => e.id === data.show_id).state = 'normal'
      }
      if (data.hide_id !== undefined) {
        state.alerts_stack.find((e) => e.id === data.hide_id).state = 'high'
      }
      if (data.remove_id !== undefined) {
        state.alerts_stack.splice(state.alerts_stack.findIndex((e) => e.id === data.remove_id), 1)
      }
    },
  },
  actions: {},
  getters: {}
}
