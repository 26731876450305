<template>
  <div class="chat-window">
    <img src="/img/logo.svg" alt="">
    <!--    <div class="chat-window-date" v-if="last_date.year">-->
    <!--      <time class="chat-window-date-box">12<span>th</span> Aug</time>-->
    <!--    </div>-->
    <div class="chat-window-box">
      <perfect-scrollbar
          :watchOptions="true"
          :options="scroll_options"
          @ps-scroll-up="onScroll"
          @ps-scroll-down="onScroll"
          class="DDHolder CHT_Window_scroll"
          ref="scroll">
        <div v-if="chat_data" class="chat-window-main">
          <template v-for="(value, index) in chat_data.messages" v-bind:key="index">
            <div class="chat-window-date" v-if="index>0 && data_ms(value.created_at,true) > data_ms(chat_data.messages[index-1].created_at,true)">
              <time class="chat-window-date-box" v-if="value.date && value.date.day && value.date.month">
                {{ new Date(value.created_at).getDate() }}<span>th</span> {{ month[parseInt(new Date(value.created_at).getMonth())] }}
              </time>
            </div>
            <div class="chat-message" :class="value.self?'chat-message-self':''">
              <div class="chat-message-text"
                   :class="value.alert ?'chat-message-icon':''"
                   v-if="value.content.text">
                <div class="chat-message-text-content" v-if="value.deleted">Message was deleted by user</div>
                <div class="chat-message-text-content" v-else>{{ value.content.text }}</div>
                <!--                <button type="button" class="chat-message-translate"></button>-->
                <button type="button" class="chat-message-icon-warning" v-if="value.alert"></button>
              </div>
              <template v-if="value.content.photos">
                <template v-for="(value2, index2) in value.content.photos" v-bind:key="index2">
                  <div class="chat-media">
                    <div class="chat-media-box">
                      <template v-if="value.self">
                        <picture class="chat-media-clickable-photo" @click="ViewPhoto(value2)">
                          <img :src="static_server + '/' + value2.photo" alt="">
                        </picture>
                        <button type="button" class="chat-media-remove"></button>
                      </template>
                      <div class="chat-media-buy" v-else-if="!value2.file">
                        <picture>
                          <img :src="static_server + '/' + value2.photo" alt="">
                        </picture>
                        <div class="button" @click="BuyPhoto(value.id,value2.id)">Buy the photo</div>
                      </div>
                      <template v-else>
                        <picture class="chat-media-clickable-photo" @click="ViewPhoto(value2)">
                          <img :src="static_server + '/' + value2.photo" alt="">
                        </picture>
                        <!--                        <button type="button" class="chat-media-remove"></button>-->
                      </template>
                    </div>
                    <div class="chat-media-text"><strong>{{ value2.price }} coins</strong>{{ value2.description }}</div>
                  </div>
                </template>
              </template>
              <template v-if="value.content.videos">
                <template v-for="(value2, index2) in value.content.videos" v-bind:key="index2">
                  <div class="chat-media">
                    <div class="chat-media-box">
                      <template v-if="value.self">
                        <picture class="chat-media-clickable-photo" @click="ViewVideo(value2)">
                          <img :src="static_server + '/' + value2.photo" alt="">
                        </picture>
                        <button type="button" class="chat-media-remove"></button>
                      </template>
                      <div class="chat-media-buy" v-else-if="!value2.video_url">
                        <picture>
                          <img :src="static_server + '/' + value2.photo" alt="">
                        </picture>
                        <div class="button" @click="BuyVideo(value.id,value2.id)">Buy the video</div>
                      </div>
                      <template v-else>
                        <picture class="chat-media-clickable-photo" @click="ViewVideo(value2)">
                          <img :src="static_server + '/' + value2.photo" alt="">
                        </picture>
                        <!--                        <button type="button" class="chat-media-remove"></button>-->
                      </template>
                    </div>
                    <div class="chat-media-text"><strong>{{ value2.price }} coins</strong>{{ value2.description }}</div>
                  </div>
                </template>
              </template>
              <template v-if="value.content.gift">
                <div class="chat-gift">
                  <div class="chat-media-box">
                    <picture class="chat-media-clickable-photo">
                      <img :src="static_server + '/' + value.content.gift.image" alt="">
                    </picture>
                  </div>
                </div>
              </template>
              <time class="chat-message-time">
                {{ ('0' + new Date(value._created_at).getHours()).slice(-2) }}:{{ ('0' + new Date(value._created_at).getMinutes()).slice(-2) }}
              </time>
            </div>
          </template>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "CHT_Window",
  computed: {
    ...mapState({
      chat_data: state => state.Chat.chat_data,
      chat: state => state.Chat.chat,
      chat_page_size: state => state.Chat.chat_page_size,
      chat_scroll_act: state => state.Chat.chat_scroll_act,
      static_server: state => state.Main.static_server,
      profiles_visible: state => state.User.profiles_visible,
      selected_user: state => state.User.selected_user,
    }),
  },
  props: {
    //messages: Array,
  },
  data: () => {
    return {
      temp_gifts: [
        {present_image: '/pic/present-2.png', time: '22:56', created_at: '13246978888'},
      ],
      // test_count: 0,
      //1 - text , 2 - photo
      messages_position: [],
      unread_to_send: [],
      unread_to_send_timeout: undefined,
      scroll_options: {
        suppressScrollX: true,
      },

      scroll_page_call: false,
      scrolled_to: false,
      scroll_dir_prev: false,
      scroll_elem: null,
      scroll_top_prev: 0,
      scroll_elem_offset: 0,
      scroll_upper_limit: false,
      scroll_bottom_limit: false,

      month: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      last_date: {
        day: 0,
        month: 0,
        year: 0,
      }
    }
  },
  mounted() {
    console.log('CHT_Window: mounted');
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetChatData']),
    ...mapActions(['SocketEmit', 'PutCall', 'UpdateChat']),
    data_ms(date, iso) {
      // this.test_count++;
      // console.log('CHT_Window date:', Date.UTC(date.year, date.month, date.day), this.test_count);
      if (!iso) {
        return Date.UTC(date.year, date.month, date.day);
      } else {
        return Date(date);
      }
    },
    ScrollTo() {
      let finded = false;
      let finded_id = 0;


      if (this.chat_data && this.chat_data.messages) {
        for (let i = 0; i < this.chat_data.messages.length; i++) {
          let message = this.chat_data.messages[i];
          if (!finded && message.unread && !message.self) {
            finded = true;
            finded_id = i;
          }
        }

        //console.log('CHT_Window: finded:', finded, 'finded_id:', finded_id, 'offset_y:', this.messages_position[finded_id].offset_y);

        const container = document.querySelector('.CHT_Window_scroll');
        let chat_window = document.querySelector('.chat-window-box');

        console.log('CHT_Window: ScrollTo: ', container)

        if (container) {
          if (!finded) {
            console.log('CHT_Window: ScrollTo: ', finded, container.scrollTop, container.scrollHeight)
            container.scrollTop = container.scrollHeight;
          } else {
            container.scrollTop = this.messages_position[finded_id].offset_y - chat_window.clientHeight + 60;
          }
        }
      }
    },
    FillPositionArray() {
      this.messages_position = [];

      let messages_holder = document.querySelector('.chat-window-main');

      if (messages_holder) {
        let messages = messages_holder.querySelectorAll('.chat-message');
        // console.log('CHT_Window: FillPositionArray: messages:',messages)

        for (let i = 0; i < messages.length; i++) {
          let message = messages[i];
          let obj = {
            offset_y: message.offsetTop,
            id: this.chat_data.messages[i].id,
            unread: this.chat_data.messages[i].unread
          };

          this.messages_position.push(obj);

          if (document.querySelector('.chat-window').offsetHeight > document.querySelector('.chat-window-box').offsetHeight) {
            if (this.chat_data.messages[i].unread) {
              if (!this.chat_data.messages[i].self) {
                this.SendReadStatus(this.chat_data.messages[i].id);
              }
            }
          }
        }
      }
    },
    onScroll(event) {
      // console.log('CHT_Window: scroll event: ', event);
      //let scroll_top_delta = 100;
      let chat_window = document.querySelector('.chat-window-box');

      let scroll_top = event.target.scrollTop + chat_window.clientHeight;

      let messages_position_length = this.messages_position.length;
      if (this.chat_data && this.chat_data.messages) {
        for (let i = 0; i < messages_position_length; i++) {
          let message = this.messages_position[i];

          //=====================     send read status   ==============================*/
          if (message.offset_y < scroll_top &&
              //message.offset_y > scroll_top - scroll_top_delta &&
              (this.profiles_visible || !this.selected_user) &&
              !this.chat_data.messages[i].self &&
              this.chat_data.messages[i].unread) {
            console.log('CHT_Window: message_id: ', this.chat_data.messages[i].id);
            this.chat_data.messages[i].unread = false;
            this.SendReadStatus(this.chat_data.messages[i].id);
          }

          if (!this.scroll_page_call) {
            //=====================     check upper limit position   ==============================*/
            if (event.type === "ps-scroll-up") {
              this.scroll_bottom_limit = false;
            }
            if (event.type === "ps-scroll-down") {
              this.scroll_upper_limit = false;
            }
            if (
                !this.scroll_upper_limit &&
                !this.scroll_page_call &&
                message.offset_y > scroll_top &&
                i === this.chat_page_size / 2 &&
                event.type === "ps-scroll-up"
            ) {
              console.log('CHT_Window: prev');
              this.scroll_page_call = true;
              this.scroll_elem = {...this.chat_data.messages[0]};
              this.scroll_elem_offset = this.messages_position[0].offset_y;

              this.UpdateChat({
                user: this.chat,
                options: {
                  size: this.chat_page_size * -1,
                  from: this.scroll_elem.id
                },
                callback: (data) => {
                  console.log('CHT_Window: UpdateChat: upper limit: callback: ', data);
                  if (data && data.messages && data.messages.length === 0) {
                    this.scroll_upper_limit = true;
                  }
                }
              });
            }

            //=====================     check bottom limit position   ==============================*/
            if (
                !this.scroll_bottom_limit &&
                !this.scroll_page_call &&
                message.offset_y < scroll_top &&
                i === messages_position_length - this.chat_page_size / 2 &&
                event.type === "ps-scroll-down"
            ) {
              console.log('CHT_Window:next');
              this.scroll_page_call = true;
              this.scroll_elem = {...this.chat_data.messages[messages_position_length - 1]};
              this.scroll_elem_offset = this.messages_position[messages_position_length - 1].offset_y;

              this.UpdateChat({
                user: this.chat,
                options: {
                  size: this.chat_page_size,
                  from: this.scroll_elem.id
                },
                callback: (data) => {
                  console.log('CHT_Window: UpdateChat: bottom limit: callback: ', data);
                  if (data && data.messages && data.messages.length === 0) {
                    this.scroll_bottom_limit = true;
                  }
                }
              });
            }
          }


        }
        // if ( message.offset_y < scroll_top ) {
        //   if ( this.data_ms(this.last_date) < this.data_ms(message.date) ) {
        //     this.last_date = { ...message.date.day };
        //   }
        // }
      }

      // console.log('CHT_Window:', event, event.target.scrollTop, event.target.scrollHeight, event.target.clientHeight);

      this.SetChatData({
        chat_scroll_perc: Math.round((event.target.scrollTop + event.target.clientHeight) * 100 / event.target.scrollHeight),
        suppress_log: true
      });

      /*
            if (!this.scroll_page_call && this.chat_data && this.chat_data.pagination && this.chat_data.pagination.totalPage > 2) {
              if (event.target.scrollTop < this.chat_page_size && this.chat_data.pagination.page > 2) {
                console.log('CHT_Window:prev');
                this.scroll_page_call = true;
                this.scroll_elem = {...this.chat_data.messages[0]};
                this.scroll_elem_offset = this.messages_position[0].offset_y;
                this.scroll_dir_prev = true;
                this.scroll_top_prev = event.target.scrollTop;
                this.UpdateChat({user: this.chat, options: {size: this.chat_page_size, page: this.chat_data.pagination.page - 1}});
              }
              if (event.target.scrollTop + event.target.clientHeight > event.target.scrollHeight - this.chat_page_size && this.chat_data.pagination.page < this.chat_data.pagination.totalPage - 2) {
                console.log('CHT_Window:next');
                this.scroll_page_call = true;
                this.scroll_elem = {...this.chat_data.messages[this.chat_data.messages.length - 1]};
                this.scroll_elem_offset = this.messages_position[this.chat_data.messages.length - 1].offset_y;
                this.scroll_dir_prev = false;
                this.scroll_top_prev = event.target.scrollTop;
                this.UpdateChat({user: this.chat, options: {size: this.chat_page_size, page: this.chat_data.pagination.page + 1}});
              }
            }
      */
    },

    SendReadStatus(id) {
      this.unread_to_send.push(id);

      if (this.unread_to_send_timeout) {
        clearTimeout(this.unread_to_send_timeout);
      }
      this.unread_to_send_timeout = setTimeout(() => {
        let send = {
          type: 'messages_readed',
          data: this.unread_to_send,
          callback: () => {
          }
        }
        this.SocketEmit(send);
        this.unread_to_send = [];
      }, 500);
    },

    BuyPhoto(message_id, photo_id) {
      console.log('CHT_Window:', message_id, photo_id);

      this.PutCall({
        endpoint: `/chats/${this.chat.id}/messages/${message_id}`,
        options: {
          photo_id: photo_id,
          status: 'buy'
        },
        callback: (response) => {
          if (response) {
            let temp_chat_data = {...this.chat_data};
            let temp_chat_data_messages = Array.from(temp_chat_data.messages);
            let index = temp_chat_data_messages.map((e) => {
              return e.id;
            }).indexOf(response.id);
            if (index >= 0) {
              temp_chat_data_messages[index].content.photos = Array.from(response.content.photos);
              temp_chat_data.messages = Array.from(temp_chat_data_messages);

              this.SetChatData({chat_data: temp_chat_data});
            }
          }
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('CHT_Window:', error_status);
        },
      })
    },
    ViewPhoto(photo) {
      let photos = [photo];
      this.SetPopupData({
        photo_view_data: photos,
        photo_view_index: 0,
        private_flag: false,
        popup_name: 'view_photo',
        popup_opened: true,
      })
    },

    BuyVideo(message_id, video_id) {
      console.log('CHT_Window:', message_id, video_id);

      this.PutCall({
        endpoint: `/chats/${this.chat.id}/messages/${message_id}`,
        options: {
          video_id: video_id,
          status: 'buy'
        },
        callback: (response) => {
          if (response) {
            let temp_chat_data = {...this.chat_data};
            let temp_chat_data_messages = Array.from(temp_chat_data.messages);
            let index = temp_chat_data_messages.map((e) => {
              return e.id;
            }).indexOf(response.id);
            if (index >= 0) {
              temp_chat_data_messages[index].content.videos = Array.from(response.content.videos);
              temp_chat_data.messages = Array.from(temp_chat_data_messages);
              this.SetChatData({chat_data: temp_chat_data});
            }
          }
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('CHT_Window:', error_status);
        },
      })
    },
    ViewVideo(video) {
      let videos = [video];
      this.SetPopupData({
        video_view_data: videos,
        video_view_index: 0,
        private_flag: false,
        popup_name: 'view_video',
        popup_opened: true,
      })
    }
  },
  watch: {
    chat: {
      handler(newVal, oldVal) {
        // console.log('CHT_Window: chat_data', newVal, oldVal);
        if (newVal && newVal.user_name) {
          document.title = `${newVal.user_name} :: Chat :: Dating.Wedding`;
        }
        if (newVal && oldVal) {
          if (newVal.user_id !== oldVal.user_id) {
            // let scrollable = this.$refs.scroll.$el;
            // scrollable.scrollTop = 0;
            this.scroll_elem = null;
            this.scrolled_to = false;
          }
        }
      },
      deep: true
    },
    chat_scroll_act: {
      handler(newVal, oldVal) {
        console.log('CHT_Window: chat_scroll_act', newVal, oldVal);
        if (newVal) {
          this.$nextTick(() => {
            let scrollable = this.$refs.scroll.$el;
            scrollable.scrollTop = scrollable.scrollHeight;
            this.SetChatData({chat_scroll_act: false});
          });
        }
      },
      deep: false
    },
    chat_data: {
      handler() {
        if (this.chat_data) {
          // if ( this.chat_data.messages && this.chat_data.messages[this.chat_data.messages.length - 1].self ) {
          //   this.scrolled_to = false;
          // }
          this.$nextTick(() => {
            this.FillPositionArray();
            console.log('CHT_Window: chat_data: this.scrolled_to', this.scrolled_to);
            if (!this.scrolled_to) {
              this.scrolled_to = true;
              // console.log('CHT_Window: chat_data: this.$refs.scroll', this.$refs.scroll.ps);
              this.$refs.scroll.ps.update();
              this.$nextTick(() => {
                this.ScrollTo();
              });
            } else {
              if (this.scroll_elem) {
                //
                this.$nextTick(() => {
                  let scrollable = this.$refs.scroll.$el;
                  let temp_elem = this.messages_position.find(element => element.id === this.scroll_elem.id);
                  if (temp_elem) {
                    scrollable.scrollTop = scrollable.scrollTop + (temp_elem.offset_y - this.scroll_elem_offset);
                  } else {
                    scrollable.scrollTop = 0;
                  }
                  this.scroll_page_call = false;
                  // console.log(scrollable.scrollTop);
                });
              }
            }
          });
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
