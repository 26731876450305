<template>
  <div class="container">
    <div class="panel-top">
      <div class="panel-top-side">
        <div class="panel-top-box">
          <h1>Мovement of coins</h1>
        </div>
      </div>
      <div class="panel-top-side tablet-only">
        <div class="panel-top-box">
          <button type="button" class="button button-transparent button-filter popup-open" data-popup="#filter">Filter</button>
        </div>
      </div>
    </div>
    <div class="panel-top popup" id="filter">
      <div class="popup-box">
        <button type="button" class="popup-close" data-close></button>
        <div class="popup-header">Filter</div>
        <form class="form">
          <div class="popup-content">
            <div class="panel-top-side">
              <div class="panel-top-box">
                <div class="panel-top-field">
                  <select class="over-bg" data-placeholder="Type" v-model="filter" @change="GetUserBilling">
                    <option value="all" selected>All</option>
                    <option value="income">Income</option>
                    <option value="expenses">Expenses</option>
                  </select>
                </div>
              </div>
              <div class="panel-top-box">
                <div class="panel-top-field">
                  <!--                  <input type="text" class="textfield over-bg" placeholder="Period">-->
                  <VueDatePicker
                      v-model="date"
                      model-type="timestamp"
                      range
                      placeholder="Period"
                      :enable-time-picker="false"
                      @update:model-value="GetUserBilling"
                  />
                </div>
              </div>
              <div class="panel-top-box" v-if="totalSpent">Total spent: <strong>{{ totalSpent }} coins</strong></div>
              <!--              <div class="panel-top-box">-->
              <!--                <a href="#">reset</a>-->
              <!--              </div>-->
            </div>
            <div class="panel-top-side">
              <div class="panel-top-box">
                <div class="panel-top-wallet" v-if="user_data">{{ user_data.coins }} Coins</div>
              </div>
              <div class="panel-top-box">
                <div class="panel-top-field">
                  <div type="button" class="button button-transparent button-download" @click="GetUserXLS">Download XLS</div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <button type="submit" class="button">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-wrapper">
      <table class="table" v-if="table">
        <thead>
        <tr>
          <th>Transaction number</th>
          <th class="table-align-right">Coins</th>
          <th>Description</th>
          <th class="table-align-right">
            <div class="table-sortable">Date</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(value, index) in table" v-bind:key="index">
          <tr>
            <td data-title="Transaction number">{{ value.id }}</td>
            <td data-title="Coins" class="talble-nowrap table-align-right" :class="parseFloat(value.spent)>0?'table-positive':'table-negative'">{{ value.spent }}</td>
            <td data-title="Description">{{ value.description }} <template v-if="value.seller_id">: <a :href="`https://dating.wedding/user-view?id=${value.seller_id}`">View Profile</a></template></td>
            <td data-title="Date" class="table-align-right">
              <time>
                <span>{{
                    new Date(value._created_at).getDate()
                      }}<sup>{{ nth(new Date(value._created_at).getDate()) }}</sup> {{ month[new Date(value._created_at).getMonth()] }} {{
                    new Date(value._created_at).getFullYear()
                      }}</span>
                <span>{{
                    new Date(value._created_at).getHours()
                      }}:{{
                    new Date(value._created_at).getMinutes() <= 9 ? '0' + new Date(value._created_at).getMinutes() : new Date(value._created_at).getMinutes()
                      }}</span>
              </time>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <div class="panel-bottom" v-if="pagination && pagination.totalPage > 1">
      <div class="panel-bottom-box">
        <ul class="pager">
          <li>
            <div class="pager-next" :class="page>1?'':'disabled'" @click="PrevPage()"/>
          </li>
          <template v-if="page === 4">
            <li @click="SetPage(1)">
              <span>{{ 1 }}</span>
            </li>
          </template>
          <template v-if="page === 5">
            <li @click="SetPage(1)">
              <div>{{ 1 }}</div>
            </li>
            <li @click="SetPage(2)">
              <div>{{ 2 }}</div>
            </li>
          </template>
          <template v-if="page > 5">
            <li @click="SetPage(1)">
              <div>{{ 1 }}</div>
            </li>
            <li>...</li>
          </template>
          <template v-for="i in pagination.totalPage" :key="i">
            <template v-if="i >= page - 2 && i <= page + 2">
              <li v-if="i === page" @click="SetPage(i)">
                <span>{{ i }}</span>
              </li>
              <li v-else @click="SetPage(i)">
                <div>{{ i }}</div>
              </li>
            </template>
          </template>
          <template v-if="page === pagination.totalPage - 3">
            <li @click="SetPage(pagination.totalPage)">
              <div>{{ pagination.totalPage }}</div>
            </li>
          </template>
          <template v-if="page === pagination.totalPage - 4">
            <li @click="SetPage(pagination.totalPage - 1)">
              <div>{{ pagination.totalPage - 1 }}</div>
            </li>
            <li @click="SetPage(pagination.totalPage)">
              <div>{{ pagination.totalPage }}</div>
            </li>
          </template>
          <template v-if="page < pagination.totalPage - 4">
            <li>...</li>
            <li @click="SetPage(pagination.totalPage)">
              <div>{{ pagination.totalPage }}</div>
            </li>
          </template>
          <li>
            <div class="pager-prev" :class="page<pagination.totalPage?'':'disabled'" @click="NextPage()"/>
          </li>
        </ul>
      </div>
      <!--        <div class="panel-bottom-box">-->
      <!--          <a href="#" class="button">Load more</a>-->
      <!--        </div>-->
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "Billing",
  components: {
    VueDatePicker
  },
  data: () => {
    return {
      pagination: undefined,
      page: 1,
      page_size: 20,
      filter: 'all',

      totalSpent: undefined,
      table: undefined,
      // month: [
      //   'Jan',
      //   'Feb',
      //   'Mar',
      //   'Apr',
      //   'May',
      //   'Jun',
      //   'Jul',
      //   'Aug',
      //   'Sep',
      //   'Oct',
      //   'Nov',
      //   'Dec'
      // ],
      date: null,
    }
  },
  computed: {
    ...mapState({
      user_data: state => state.User.user_data,
      month: state => state.Main.month,
    }),
  },
  mounted() {
    this.GetUserBilling();
  },
  methods: {
    ...mapMutations(['SetPopupData',]),
    ...mapActions(['GetCall', 'PostCall']),
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    nth(n) {
      return n > 3 && n < 21 ? "th" : n % 10 == 2 ? "nd" : n % 10 == 2 ? "nd" : n % 10 == 3 ? "rd" : "th"
    },
    NextPage() {
      if (this.page < this.pagination.totalPage) {
        this.page++;
        this.$nextTick(() => {
          this.scrollToTop();
        })
        this.GetUserBilling();
      }
    },
    PrevPage() {
      if (this.page > 1) {
        this.page--;
        this.$nextTick(() => {
          this.scrollToTop();
        })
        this.GetUserBilling();
      }
    },
    SetPage(n) {
      this.page = n;
      this.$nextTick(() => {
        this.scrollToTop();
      })
      this.GetUserBilling();
    },
    Format(timestamp) {
      const date = new Date(timestamp);
      const day = date.getDate();
      const month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1);
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
    GetUserBilling() {
      let sendOptions = {size: this.page_size, page: this.page};
      if (this.filter !== 'all') {
        sendOptions.filter = this.filter;
      }
      if (this.date) {
        sendOptions.datefrom = this.Format(this.date[0]);
        sendOptions.dateto = this.Format(this.date[1]);
      }

      this.GetCall({
        endpoint: '/users/billing/transactions',
        options: sendOptions,
        callback: (data) => {
          if (data && data.pagination && data.pagination.totalPage > 1) {
            this.pagination = {...data.pagination};
          } else {
            this.pagination = undefined;
          }
          this.table = {...data.rows};
          this.totalSpent = data.totalSpent;
        },
      })
    },
    GetUserXLS() {
      let sendOptions = {};

      if (this.date) {
        sendOptions.datefrom = this.Format(this.date[0]);
        sendOptions.dateto = this.Format(this.date[1]);
      }

      this.PostCall({
        endpoint: '/users/billing/transactions',
        options: sendOptions,
        callback: (data) => {
          console.log(data);
          this.download(data.path);
        },
      })
    },
    download(url) {
      const a = document.createElement('a')
      a.href = url
      a.download = url.split('/').pop()
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
  },
}
</script>

<style lang="less">
.dp__input {
  height      : 44px;
  border      : none;

  font-weight : 500;
  font-size   : 15px;
  font-family : 'Montserrat', 'Arial', 'Helvetica', 'Nimbus Sans L', sans-serif;
}
.dp__arrow_top {
  display : none;
}
.dp__menu {
  border      : none;
  font-family : 'Montserrat', 'Arial', 'Helvetica', 'Nimbus Sans L', sans-serif;
  font-size   : 14px;
  &:focus {
    border : none;
  }
}
.dp__calendar_wrap {
  font-family : 'Montserrat', 'Arial', 'Helvetica', 'Nimbus Sans L', sans-serif;
}
.dp__calendar_header_item {
  font-weight : 400;
  font-size   : 12px;
  color       : #777777;
}
.dp__selection_preview {
  display : none;
}
.dp__cancel {
  display : none;
}
.dp__action_buttons {
  width           : 100%;
  display         : flex;
  justify-content : center;
}
.dp__select {
  width           : 143px;
  height          : 44px;
  background      : #8549C2;
  border-radius   : 8px;

  display         : flex;
  justify-content : center;
  align-items     : center;

  color           : #FFFFFF;
  text-align      : center;
  &:hover {
    background : linear-gradient(91.72deg, #8449C1 0%, #BB36D0 100%);
  }
}
.dp__today {
  border        : 1px solid #BA37D0;
  border-radius : 18px;
}
.dp__range_start {
  background    : #BA37D0;
  border-radius : 18px 0 0 18px;
}
.dp__range_end {
  background    : #BA37D0;
  border-radius : 0 18px 18px 0;
}
.dp__date_hover_start {
  border-radius : 18px 0 0 18px;
}
.dp__date_hover_end {
  border-radius : 0 18px 18px 0;
}
.dp__range_between {
  border-radius : 0;
}
</style>
