<template>
  <li>
    <label class="interest">
      <input type="checkbox" v-if="loc_selected" checked @change="Delete">
      <input type="checkbox" v-else @change="Save">
      <span class="interest-content">
        <i>
          <img :src="static_server+'/'+ data.icon1">
        </i>
        <span>{{ data.title }}</span>
      </span>
    </label>
  </li>
</template>

<script>
// import InlineSvg from 'vue-inline-svg';
import {mapActions, mapState} from "vuex";

export default {
  name: "UP_Interests_Elem",
  components: {
    // InlineSvg
  },
  props: {
    data: Object,
    selected: Boolean
  },
  data: function () {
    return {
      block: false,
      loc_selected: false,
    }
  },
  mounted() {
    this.loc_selected = this.selected;
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
    }),
  },
  methods: {
    ...mapActions(['PostCall', 'DeleteCall']),
    Save() {
      if (!this.block) {
        this.block = true;
        this.PostCall({
          endpoint: '/users/add_interest',
          options: {id: this.data.id},
          callback: (data) => {
            console.log(data);
            this.$nextTick(() => {
              this.block = false;
              this.loc_selected = true;
            });

          },
          on_error: (error) => {
            let error_status = error.response.status;
            console.log(error_status);
          },
        })
      }
    },
    Delete() {
      if (!this.block) {
        this.block = true;
        this.DeleteCall({
          endpoint: '/users/delete_interests/' + this.data.id,
          options: '',
          callback: (data) => {
            console.log(data);
            this.$nextTick(() => {
              this.block = false;
              this.loc_selected = false;
            });
          },
          on_error: (error) => {
            let error_status = error.response.status;
            console.log(error_status);
          },
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
