<template>
  <dialog class="popup" id="delete-confirm">
    <div class="popup-box" style="max-width: 464px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Favorite user remove confirmation</div>
      <div class="popup-content center">
        <div class="popup-message">Are you sure?</div>
        <button type="button" class="button" @click="Delete">Yes, remove the user</button>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PDeleteFav",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      popup_additional_data: state => state.Popups.popup_additional_data,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData',]),
    ...mapActions(['DeleteCall',]),

    Delete() {
      this.DeleteCall({
        endpoint: '/favorites/' + this.popup_additional_data.fav_id,
        callback: (data) => {
          console.log(data);
          if (this.popup_additional_data.callback) {
            if (data && data.id) {
              this.popup_additional_data.callback.apply(null, [data.id]);
            } else {
              this.popup_additional_data.callback.apply(null);
            }
          }
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },


    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>
