<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "PBlocked",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData',]),

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },

}
</script>

<template>
  <dialog class="popup" id="delete-confirm">
    <div class="popup-box" style="max-width: 464px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Problem with sending</div>
      <div class="popup-content center">
        <div class="popup-message">Your message could not be sent. You may have been blocked.</div>
        <button type="button" class="button" @click="hide">Ok</button>
      </div>
    </div>
  </dialog>
</template>

<style scoped>

</style>
