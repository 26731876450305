import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store';
import Main from "@/components/00Main/Main";
import UserProfile from "@/components/01UserProfile/UserProfile";
import UserView from "@/components/02UserView/UserView";
import SearchResult from "@/components/03SearchResult/SearchResult";
import Invites from "@/components/04Invites/Invites";
import Chat from "@/components/05Chat/Chat";
import Letters from "@/components/06Letters/Letters";
import Favorites from "@/components/08Favorites/Favorites";
import Blacklist from "@/components/09Blacklist/Blacklist";
import Billing from "@/components/10Billing/Billing";
import FAQ from "@/components/11Static/FAQ";
import Contacts from "@/components/11Static/Contacts";

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresLogin: true,
      title: "Profile",
    },
  },
  {
    path: '/user-view',
    name: 'UserView',
    component: UserView,
    meta: {
      requiresLogin: true,
      title: "User View",
    },
  },
  {
    path: '/search-result',
    name: 'SearchResult',
    component: SearchResult,
    meta: {
      requiresLogin: true,
      title: "Search Result",
    },
  },
  {
    path: '/invites',
    name: 'invites',
    component: Invites,
    meta: {
      requiresLogin: true,
      title: "Invites",
    },
    props: {id: undefined}
  },
  {
    path: '/invites/:id',
    name: 'invites-id',
    component: Invites,
    meta: {
      requiresLogin: true,
      title: "Invites",
    },
    props: route => ({id: route.params.id})
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
    meta: {
      requiresLogin: true,
      title: "Chat",
    },
    props: {id: undefined}
  },
  {
    path: '/chat/:id',
    name: 'chat-id',
    component: Chat,
    meta: {
      requiresLogin: true,
      title: "Chat",
    },
    props: route => ({id: route.params.id})
  },
  {
    path: '/letters',
    name: 'letters',
    component: Letters,
    meta: {
      requiresLogin: true,
      title: "Letters",
    },
    props: {id: undefined}
  },
  {
    path: '/letters/:id',
    name: 'letters-id',
    component: Letters,
    meta: {
      requiresLogin: true,
      title: "Letters",
    },
    props: route => ({id: route.params.id})
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites,
    meta: {
      requiresLogin: true,
      title: "Favorites",
    },
  },
  {
    path: '/blacklist',
    name: 'Blacklist',
    component: Blacklist,
    meta: {
      requiresLogin: true,
      title: "Blacklist",
    },
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
    meta: {
      requiresLogin: true,
      title: "Billing",
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: {
      requiresLogin: false,
      title: "FAQ",
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      requiresLogin: false,
      title: "Contacts",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} :: Dating.Wedding` : "Dating.Wedding";
  console.log('Router:beforeEach:to-from', to, from);

  if (!store.state.Main.api_server) {
    console.log('Router:beforeEach:api_server called');
    await store.dispatch('GetApiServer');
    console.log('Router:beforeEach:api_server getted');
  }

  if (!store.state.User.is_auth && !store.state.User.auth_pending) {

    store.commit('SetRedirectData', {
      redirect_path: to.path,
      redirect_query: to.query,
    });

    if (to.matched.some(record => record.meta.requiresLogin)) {
      store.state.Main.loaded = false;
      store.state.Main.hide_loader = false;
      console.log('Router:beforeEach:GetScopeUpdate auth:false called');
      await store.dispatch('GetScopeUpdate');
      console.log('Router:beforeEach:GetScopeUpdate auth:false getted');
    }
  }

  if (to.matched.some(record => record.meta.requiresLogin)) {
    // this route requires auth, check if logged in, if not, redirect to login page.
    console.log('Router:beforeEach:is_auth', store.state.User.is_auth);
    if (store.state.User.is_auth && !store.state.User.draw_scope_loaded) {
      store.state.Main.loaded = false;
      store.state.Main.hide_loader = false;

      console.log('Router:beforeEach:GetScopeUpdate auth:true called');
      await store.dispatch('GetScopeUpdate');
      console.log('Router:beforeEach:GetScopeUpdate auth:true getted');
    }

    if (!store.state.User.is_auth && !store.state.User.auth_pending && from.path !== '/') {
      console.log('go to /');
      next({path: '/'})
    } else {
      next();
    }
  } else {
    store.state.Main.loaded = true;
    next(); // make sure to always call next()!
  }

})

export default router
