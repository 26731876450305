<template>
  <div class="chat-search">
    <div class="chat-search-box" v-if="communication">
      <input type="search" class="textfield" placeholder="Search" v-model="search">
      <div class="button"></div>
    </div>
    <div class="chat-search-box" v-else style="opacity: 0.6">
      <input type="search" class="textfield" placeholder="Search" disabled>
      <div class="button"></div>
    </div>
    <button type="button" class="chat-filter tip" @mouseenter="OpenChatFilter"></button>
  </div>
  <div class="chat-list">
    <perfect-scrollbar :watchOptions="true" :options="scroll_options" class="DDHolder">
      <template v-for="(value, index) in communications_list" v-bind:key="index">
        <L_LetterElement :local_communication="value"/>
      </template>
      <template v-if="!communications_list || (communications_list && communications_list.length===0)">
        <template v-for="(value, index) in 1" v-bind:key="index">
          <div class="chat-user" style="cursor: default">
            <div class="chat-user-avatar" style="aspect-ratio: 1; cursor: default"></div>
            <div class="chat-user-info">
              <div class="chat-user-name-date">
                <div class="chat-user-name" style="background: #E8E8E8; min-height: 16px"></div>
              </div>
              <div class="chat-user-links-about">
                <ul class="chat-user-links">
                  <li class="chat-user-links-photo"></li>
                  <li class="chat-user-links-video"></li>
                </ul>
                <div class="chat-user-about" style="background: #E8E8E8; min-width: 80%;"></div>
              </div>
            </div>
          </div>
        </template>
      </template>

    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import L_LetterElement from "./L_LeftPart/L_LetterElement";

export default {
  name: "L_LeftPart",
  components: { L_LetterElement },
  computed: {
    ...mapState({
      communication: state => state.Letters.communication,
      communications_list: state => state.Letters.communications_list,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
      search: '',
      search_timeout: undefined,
    }
  },
  beforeMount() {
    this.SetLetterData({ communications_list: [] });
  },
  mounted() {
    this.SetLetterData({ q: '', filter_id: '' });
    this.GetLettersList();
  },
  methods: {
    ...mapMutations(['SetDDData', 'SetLetterData']),
    ...mapActions(['GetLettersList']),
    OpenChatFilter() {
      this.SetDDData({
        dd_name: 'chat_filter',
        position_target: { hover: 'chat-filter', x: 'chat-filter', y: 'chat-search' },
        dd_opened: true,
      })
    },
    Search() {
      this.SetLetterData({ q: this.search });
      this.GetLettersList();
    }
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        if ( newVal !== oldVal ) {
          if ( this.search_timeout ) {
            clearTimeout(this.search_timeout);
          }
          this.search_timeout = setTimeout(() => {
            this.Search();
          }, 300);
        }
      },
      deep: true
    },

  }
}
</script>

<style scoped>

</style>
