<template>
  <section class="chat chat-letter-write">
    <div class="chat-letter-to">
      <span>To:</span>
      <div class="chat-letter-to-user">
        <div class="chat-user">
          <div class="chat-user-avatar">
            <picture v-if="write_letter_user.main_photo">
              <img :src="static_server + '/' + write_letter_user.main_photo" alt="">
            </picture>
            <picture v-else-if="write_letter_user.user_photo_url">
              <img :src="static_server + '/' + write_letter_user.user_photo_url" alt="">
            </picture>
          </div>
          <div class="chat-user-info">
            <div class="chat-user-name">{{ write_letter_user.user_name }}</div>
          </div>
        </div>
      </div>
      <div @click="CloseReply" class="button button-transparent button-back">
        <span>Close</span>
      </div>
    </div>
    <div class="chat-letter-subject">
      <span>Subject:</span>
      <div class="chat-letter-textarea">
        <textarea class="textfield autosize" :class="subject_error?'error':''" rows="1" v-model="subject" @keydown="myEventHandler"></textarea>
        <!--        <button type="button" class="chat-letter-textarea-translate"></button>-->
      </div>
    </div>
    <div class="chat-letter-text">
      <div class="chat-letter-textarea">
        <textarea class="textfield" :class="text_error?'error':''" v-model="text" @keydown="myEventHandler"></textarea>
        <!--        <button type="button" class="chat-letter-textarea-translate"></button>-->
      </div>
    </div>
    <div class="chat-letter-bottom">
      <div class="chat-letter-price">
        <template v-if="!user_data.is_seller && letter_price">
          Price: <span>{{ letter_price.letter_price }} coins</span>
        </template>
      </div>
      <ul class="chat-letter-buttons">
        <!--        <li>-->
        <!--          <button type="button" class="chat-letter-translate"></button>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <button type="button" class="chat-letter-video"></button>-->
        <!--        </li>-->
        <li v-if="user_data.is_seller && private_photos && private_photos.length>0">
          <div class="chat-letter-photo" @click="OpenChatAdd"></div>
        </li>
        <!--
                <li>
                  <button type="button" class="chat-letter-smile"></button>
                </li>
        -->
        <li>
          <div class="chat-letter-send-message" @click="LetterSend"></div>
        </li>
      </ul>
    </div>
    <template v-if="photo_selected">
      <div class="chat-letter-box chat-letter-box-write" v-if="photo_selected.length > 0">
        <div class="chat-letter-row">
          <template v-for="(value,index) in photo_selected" :key="index">
            <div class="chat-letter-row-col">
              <div class="chat-media">
                <div class="chat-media-box">
                  <div class="chat-media-buy">
                    <picture>
                      <!--                        <source srcset="pic/avatar-female-big.webp 1x, pic/avatar-female-big@2x.webp 2x" type="image/webp">-->
                      <!--                        <source srcset="pic/avatar-female-big.jpg 1x, pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">-->
                      <img :src="static_server + '/' + value.preview" alt="">
                    </picture>
                    <div class="chat-media-photo"></div>
                  </div>
                </div>
                <div class="chat-media-text"><strong>{{ value.price }} coins</strong>{{ value.name }}</div>
              </div>
            </div>
          </template>
          <!--              <template v-for="(value,index) in letter.video" :key="index">-->
          <!--                <div class="chat-letter-row-col">-->
          <!--                  <div class="chat-media">-->
          <!--                    <div class="chat-media-box">-->
          <!--                      <div class="chat-media-buy">-->
          <!--                        <picture>-->
          <!--                          &lt;!&ndash;                          <source srcset="pic/avatar-female-big.webp 1x, pic/avatar-female-big@2x.webp 2x" type="image/webp">&ndash;&gt;-->
          <!--                          &lt;!&ndash;                          <source srcset="pic/avatar-female-big.jpg 1x, pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">&ndash;&gt;-->
          <!--                          <img :src="value.preview_link" alt="">-->
          <!--                        </picture>-->
          <!--                        <div class="chat-media-video"></div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="chat-media-text"><strong>{{ value.price }} coins</strong>{{ value.name }}</div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </template>-->
        </div>
      </div>
    </template>

  </section>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "L_WriteLetter",
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      write_letter_user: state => state.Letters.write_letter_user,
      letter_price: state => state.Letters.letter_price,
      user_data: state => state.User.user_data,
      private_photos: state => state.User.user_data.private_photos,
      photo_selected: state => state.Communications.photo_selected,
      photo_selected_target: state => state.Communications.photo_selected_target,
    }),
  },
  data: () => {
    return {
      subject: '',
      text: '',
      subject_error: false,
      text_error: false,
    }
  },
  mounted() {

  },
  methods: {
    ...mapMutations(['SetCommunicationData', 'SetLetterData', 'SetPopupData',]),
    ...mapActions(['SocketEmit', 'GetCall', 'PostCall']),
    myEventHandler(event) {
      if ((event.ctrlKey || event.metaKey) && (event.keyCode === 13 || event.keyCode === 10)) {
        console.log(event);
        this.LetterSend();
      }
    },
    OpenChatAdd() {
      this.SetCommunicationData({photo_selected_target: 'letter'});
      this.SetPopupData({
        private_flag: false,
        popup_name: 'chat_add',
        popup_opened: true
      })
    },
    /*GetLetters() {

      this.GetCall({
        endpoint: `/users/shortview/${this.user_id}`,
        options: {},
        callback: (data) => {
          console.log(data);
          this.user_name = data.user_name;
          this.user_avatar = data.main_photo;
        },
      })
    },*/
    LetterSend() {
      let trimmed_subject = '';
      let trimmed_text = '';
      if (this.subject && this.text) {
        trimmed_subject = this.subject.trim();
        trimmed_text = this.text.trim();
      }


      if (trimmed_subject && trimmed_subject.length >= 10 && trimmed_text && trimmed_text.length >= 10) {
        let photos = [];

        if (this.photo_selected && this.photo_selected_target === 'letter') {
          this.photo_selected.forEach((photo) => {
            photos.push({
              id: photo.id,
              price: photo.price
            })
          });
        }

        this.PostCall({
          endpoint: '/letters',
          options: {
            user_id: this.write_letter_user.id,
            subject: trimmed_subject,
            content: {
              text: trimmed_text,
              photos: photos
            }
          },
          callback: (response) => {
            console.log('L_WriteLetter:LetterSend:', response);
            if (response && response.result !== 'error') {
              this.SetLetterData({write_letter: false});
              this.SetCommunicationData({
                photo_selected: [],
                photo_selected_target: null
              });
            } else {
              if (response && response.error === 'blocked') {
                this.SetPopupData({
                  private_flag: false,
                  popup_name: 'blocked',
                  popup_opened: true,
                })
              }
            }
          },
          on_error: (error) => {
            let error_status = error.response.status;
            console.log('L_WriteLetter:LetterSend:Error:', error_status);
          },
        })

      } else {
        if (trimmed_subject && trimmed_subject.length < 10) {
          this.subject_error = true;
          this.SetPopupData({
            popup_additional_data: {
              text: 'Please enter a longer subject line',
            },
            private_flag: false,
            popup_name: 'error',
            popup_opened: true,
          })
        }
        if (trimmed_text && trimmed_text.length < 10) {
          this.text_error = true;
          this.SetPopupData({
            popup_additional_data: {
              text: 'Please enter a longer message',
            },
            private_flag: false,
            popup_name: 'error',
            popup_opened: true,
          })
        }
      }
    },
    CloseReply() {
      this.SetLetterData({write_letter: false});
    }
  }
}
</script>

<style scoped>

</style>
