<template>
  <dialog class="popup media-gallery popup-video" id="media-photo">
    <button type="button" class="popup-close" @click="hide"></button>
    <div class="media-gallery-box">
      <div class="media-gallery-object" :class="private_flag  ? 'media-gallery-locked':''">
        <div class="media-gallery-video">
          <iframe
              width="100%"
              height="100%"
              :src="`${video_service}${link}`"
              title="Video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
          </iframe>
        </div>
        <div class="media-gallery-lock" v-if="private_flag">
          <a href="#" class="button">
            <span>View private photo – <strong>5 coins</strong></span>
          </a>
        </div>
        <template v-if="video_view_data && video_view_data.length > 1">
          <button type="button" class="media-gallery-prev" @click="ShowPrev"></button>
          <button type="button" class="media-gallery-next" @click="ShowNext"></button>
        </template>
      </div>
      <div class="media-gallery-about">
        <div class="media-gallery-caption">{{ video_view_data[index].description }}</div>
        <div class="media-gallery-count">{{ index + 1 }} of {{ video_view_data.length }}</div>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PViewVideos",
  data: function () {
    return {
      is_open: false,
      description: '',
      file: null,
      index: 0,
    }
  },

  /*<iframe width="560" height="315" src="https://www.youtube.com/embed/5TpYRqkak0I?si=QPxIXsL43oEht6Fa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>*/
/*<iframe src="https://player.vimeo.com/video/835587844?h=a279accff7" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
<p><a href="https://vimeo.com/835587844">Sigur R&oacute;s - Skel</a> from <a href="https://vimeo.com/georgestone">George Jasper Stone</a> on <a href="https://vimeo.com">Vimeo</a>.</p>*/

  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      video_view_data: state => state.Popups.video_view_data,
      video_view_index: state => state.Popups.video_view_index,
      private_flag: state => state.Popups.private_flag,
      link() {
        let temp_link = '';
        let temp_arr = this.video_view_data[this.index].video_url.split('/');
        if(temp_arr.length>1) {
          temp_link = temp_arr[temp_arr.length - 1];
        }
        return temp_link;
      },
      video_service(){
        let ret = 'https://www.youtube.com/embed/';
        if(
            this.video_view_data[this.index].video_url.indexOf('vimeo')>0 ||
            this.video_view_data[this.index].video_url.indexOf('Vimeo')>0
        ){
          ret = 'https://player.vimeo.com/video/';
        }

        return ret;
      }
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
      this.index = this.video_view_index;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData',]),
    ...mapActions(['PostCall',]),

    ShowPrev() {
      console.log(this.video_view_data.length - 1);
      if (this.index === 0) {
        this.index = this.video_view_data.length - 1;
      } else if (this.index > 0) {
        this.index--;
      }
    },
    ShowNext() {
      if (this.index === this.video_view_data.length - 1) {
        this.index = 0;
      } else if (this.index < this.video_view_data.length) {
        this.index++;
      }
    },
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>