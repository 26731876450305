<template>
  <form class="chat-form" v-if="chat">
    <textarea class="textfield" placeholder="My message..." v-model="chat_message" @keydown="myEventHandler"></textarea>
    <ul class="chat-form-buttons" v-if="user_data">
      <!--
            <li>
              <button type="button" class="chat-form-translate"></button>
            </li>
      -->
      <li v-if="!user_data.is_seller">
        <button type="button" class="chat-form-present" @click="ShowPresent()"></button>
      </li>
      <li v-if="user_data.is_seller && private_videos && private_videos.length>0">
        <button type="button" class="chat-form-video" @click="OpenChatAddVideo"></button>
      </li>
      <li v-if="user_data.is_seller && private_photos && private_photos.length>0">
        <button type="button" class="chat-form-photo" @click="OpenChatAddPhoto"></button>
      </li>
      <!--      <li>-->
      <!--        <button type="button" class="chat-form-smile"></button>-->
      <!--      </li>-->
      <li>
        <div class="chat-form-send-message" @click="ChatSend"></div>
      </li>
    </ul>
    <div class="chat-form-present-box" :class="presents_opened?'is-opened':''">
      <div class="chat-form-present-line">
        <ul>
          <template v-for="(value, index) in gifts_list" v-bind:key="index">
            <li>
              <div @click="GiftSend(value.id)">
                <img :src="static_server+'/'+value.image" alt="">
                <span>{{ value.coins }} coins</span>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <button type="button" class="chat-form-present-box-close" @click="HidePresent()"></button>
    </div>
  </form>
  <form class="chat-form" v-else>
    <textarea class="textfield" placeholder="My message..." disabled></textarea>
    <ul class="chat-form-buttons">
      <!--
            <li>
              <button type="button" class="chat-form-translate" style="cursor: default; opacity: .5"></button>
            </li>
      -->
      <li>
        <button type="button" class="chat-form-smile" style="cursor: default; opacity: .5"></button>
      </li>
      <li>
        <div class="chat-form-send-message" style="cursor: default; opacity: .5"></div>
      </li>
    </ul>
  </form>

</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "CHT_SendField",
  props: {},
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      user_data: state => state.User.user_data,
      chat: state => state.Chat.chat,

      private_photos: state => state.User.user_data.private_photos,
      photo_selected: state => state.Communications.photo_selected,
      photo_selected_target: state => state.Communications.photo_selected_target,

      private_videos: state => state.User.user_data.private_videos,
      video_selected: state => state.Communications.video_selected,
      video_selected_target: state => state.Communications.video_selected_target,
    }),
  },
  data: () => {
    return {
      chat_message: '',
      chat_message_cache: '',
      chat_open_user_id: null,
      presents_opened: false,
      gifts_list: [],
    }
  },
  emits: [
    'onWrite'
  ],
  methods: {
    ...mapMutations(['SetPopupData', 'SetChatData', 'SetCommunicationData', 'ResetChatData']),
    ...mapActions(['SocketEmit', 'PostCall', 'GetCall']),
    // created() {
    //   window.addEventListener("keydown", this.myEventHandler);
    // },
    // unmounted() {
    //   window.removeEventListener("keydown", this.myEventHandler);
    // },

    myEventHandler(event) {
      if ((event.ctrlKey || event.metaKey) && (event.keyCode === 13 || event.keyCode === 10)) {
        console.log(event);
        this.ChatSend();
      }
    },
    ChatSend() {
      let trimmed = '';
      if (this.chat_message) {
        trimmed = this.chat_message.trim();
      }

      if (trimmed.length !== 0 && trimmed !== ' ') {
        let send = {
          type: 'chat_messages',
          data: {
            type: 1, //1-text, 2-photos, 3-gifts, 4-videos
            chat_id: this.chat.id,
            message: trimmed,
          },
          callback: (response) => {
            if (response.result) {
              this.chat_message_cache = '';
            } else {
              this.chat_message = this.chat_message_cache;

              if (response && response.error === 'blacklist') {
                this.SetPopupData({
                  private_flag: false,
                  popup_name: 'blocked',
                  popup_opened: true,
                })
              }
            }
            this.SetChatData({chat_scroll_act: true});
          }
        }
        this.SocketEmit(send);
        this.chat_message_cache = trimmed;
        this.chat_message = '';
      }
    },

    OpenChatAddPhoto() {
      this.SetCommunicationData({photo_selected_target: 'chat'});

      this.SetPopupData({
        private_flag: false,
        popup_name: 'chat_photo_add',
        popup_opened: true
      })
    },
    PhotoSend() {
      let photos = [];

      this.photo_selected.forEach((photo) => {
        photos.push({
          id: photo.id,
          price: photo.price,
        })
      });

      let send = {
        type: 'chat_messages',
        data: {
          type: 2, //1-text, 2-photos, 3-gifts, 4-videos
          chat_id: this.chat.id,
          message: photos,
        },
        callback: () => {
          this.SetCommunicationData({
            photo_selected: [],
            photo_selected_target: null
          });
          // this.hide();
        }
      }
      this.SocketEmit(send);
      this.chat_message = '';
    },

    OpenChatAddVideo() {
      this.SetCommunicationData({video_selected_target: 'chat'});

      this.SetPopupData({
        private_flag: false,
        popup_name: 'chat_video_add',
        popup_opened: true
      })
    },
    VideoSend() {
      let videos = [];

      this.video_selected.forEach((video) => {
        videos.push({
          id: video.id,
          price: video.price,
        })
      });

      let send = {
        type: 'chat_messages',
        data: {
          type: 4, //1-text, 2-photos, 3-gifts, 4-videos
          chat_id: this.chat.id,
          message: videos,
        },
        callback: () => {
          this.SetCommunicationData({
            video_selected: [],
            video_selected_target: null
          });
          // this.hide();
        }
      }
      this.SocketEmit(send);
      this.chat_message = '';
    },

    GiftSend(id) {
      let gift = {
        id: id,
      };

      let send = {
        type: 'chat_messages',
        data: {
          type: 3, //1-text, 2-photos, 3-gifts, 4-videos
          chat_id: this.chat.id,
          message: gift,
        },
        callback: () => {
          this.presents_opened = !this.presents_opened;
        }
      }
      this.SocketEmit(send);
    },
    ShowPresent() {
      this.GetCall({
        endpoint: '/chats/gifts',
        // options: {},
        callback: (data) => {
          this.gifts_list = Array.from(data);
          this.presents_opened = !this.presents_opened;
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },
    HidePresent() {
      this.presents_opened = !this.presents_opened;
    },
  },
  watch: {
    photo_selected: {
      handler(newVal) {
        if (newVal) {
          if (newVal.length !== 0) {
            if (this.photo_selected_target === 'chat') {
              this.PhotoSend();
            }
          }
        }
      },
      deep: true
    },
    video_selected: {
      handler(newVal) {
        if (newVal) {
          if (newVal.length !== 0) {
            if (this.video_selected_target === 'chat') {
              this.VideoSend();
            }
          }
        }
      },
      deep: true
    },
  }

}
</script>

<style scoped>

</style>
