<script>
import { mapMutations, mapState } from "vuex";
import VideoListElement from "../../Misc/VideoListElement";

export default {
  name: "UP_Videos",
  components: { VideoListElement },
  data:()=>{
    return {
      is_opened:false,
    }
  },
  computed: {
    ...mapState({
      videos: state => state.User.user_data.videos,
      toggle_video: state => state.User.toggle_video,
    }),
  },

  methods:{
    ...mapMutations(['SetPopupData','SetUserData',]),
    Video_File_Add() {
      this.SetPopupData({
        private_flag: false,
        popup_name: 'add_video',
        popup_opened: true
      })
    },
    ToggleOpened(){
      this.is_opened = !this.is_opened;
    }
  },
  watch: {
    toggle_video: {
      handler(newVal) {
        if(newVal){
          this.is_opened = true;
          this.SetUserData({toggle_video:null});
        }
      },
      deep: false
    },
  },
}
</script>

<template>
  <section class="box open-box"
           :class="is_opened ? 'is-opened':''">
    <div class="box-header">
      <div class="box-header-item">
        <h3>Add your video</h3>
      </div>
      <div class="box-header-item">
        <button type="button" class="button-link button-link-add prevent-click" @click="Video_File_Add"><i></i>Add video</button>
        <div class="close-btn" @click="ToggleOpened"></div>
      </div>
    </div>
    <div class="box-content">
      <ul class="media-list media-video">
        <template v-for="(value, index) in videos" v-bind:key="index">
          <video-list-element :video="value" :index="index"/>
        </template>
      </ul>
    </div>
  </section>
</template>

<style scoped>

</style>
