<template>
  <dialog class="popup" id="buy-coins">
    <div class="popup-box" style="max-width: 1020px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header popup-header-buy">Make your choice</div>
      <div class="popup-content" v-if="coin_data">
        <ul class="buy-coins">
          <template v-for="(value, index) in coin_data.items" v-bind:key="index">
            <li>
              <div class="buy-coins-box" @click="SetSum(value.value)">
                <div class="buy-coins-header">{{ value.title }}</div>
                <img src="/img/buy-coins-1.svg" class="buy-coins-icon" alt="">
                <div class="buy-coins-price">
                  <div class="buy-coins-element">
                    <!--                      <img src="/img/dollar-logo.svg">-->
                    <div class="buy-coins-title">${{ value.price }}</div>
                  </div>
                </div>
                <!--              <div class="button button-block" @click="Buy(100)">Buy</div>-->
              </div>
            </li>
          </template>
        </ul>
        <div class="popup-line"></div>
        <div class="popup-subtitle">Or enter your value, coins:</div>
        <div class="plus-minus">
          <div class="pm-button pm-minus" @click="Minus"></div>
          <input class="pm-count" type="number" v-model.number="coins" min="11" pattern="\d+"/>
          <div class="pm-button pm-plus" @click="Plus"></div>
        </div>
        <div class="buy-coins-price" v-if="coins>0 && coin_data.coinsToUSDT">
          <div class="buy-coins-element">
            <!--            <div class="buy-coins-title">${{ Math.round(parseFloat(coin_data.coinsToUSDT) * coins * 100) / 100 }}</div>-->
            <div class="button" v-if="coins>0" @click="Buy">Pay ${{ Math.round(parseFloat(coin_data.coinsToUSDT) * coins * 100) / 100 }}</div>
          </div>
        </div>
        <div class="popup-info-text buy-coins-info center">values are calculated automatically based on current rates</div>
      </div>

    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PBuyCoins",
  data: function () {
    return {
      is_open: false,
      coin_data: undefined,
      coins: 0,
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      user_data: state => state.User.user_data,
    }),
  },
  mounted() {
    this.GetPopupData();

    setTimeout(() => {
      this.is_open = true;
      if (this.user_data) {
        if (this.user_data.mood_id) {
          this.mood_id = this.user_data.mood_id;
        }
      }
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData']),
    ...mapActions(['GetCall', 'PutCall',]),

    Plus() {
      if (this.coins < 9999) {
        this.coins++;
      }
    },
    Minus() {
      if (this.coins > 0) {
        this.coins--;
      }
    },

    GetPopupData() {
      this.GetCall({
        endpoint: '/users/billing/shop',
        callback: (data) => {
          // console.log(data);
          this.coin_data = {...data};
        },
        on_error: (error) => {
          let error_status = error.status;
          console.log(error_status);
        },
      })
    },


    SetSum(coins) {
      this.coins = coins;
    },

    Buy() {

      let form = {
        coins: this.coins,
        // currency:currency
      }

      this.PutCall({
        endpoint: '/users/billing/refill',
        options: form,
        callback: (data) => {
          window.open(data.invoice_url, '_blank', 'noreferrer');
          this.SetUserData({
            payment: {invoice_url: data.invoice_url},
            user_data: {...this.user_data,...{invoice_url: data.invoice_url}}
          });
          // this.SetPopupData({
          //   private_flag: false,
          //   popup_name: 'payment_info',
          //   popup_opened: true
          // })
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.status;
          console.log(error_status);
        },
      })
    },


    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>
