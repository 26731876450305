import router from "@/router";

export default {
  state: {
    invite_id: '',
    invites_list: [],
    invite: null,
    invite_data: null,

    q: '',//поисковый запрос
    filter_id: '',//фильтр
    invite_send_obj: {},

    invite_scroll_perc: 0,
    invite_scroll_act: false,
  },
  mutations: {
    SetInviteData(state, data) {
      console.log('Invites: SetInviteData', data);

      if (data.invite_id) {
        state.invite_id = data.invite_id;
      }
      if (data.invites_list) {
        state.invites_list = Array.from(data.invites_list);
      }
      if (data.invites_list_update) {
        //state.chats_list = Array.from(data.chats_list);
        const il = data.invites_list_update;
        const invite_index = state.invites_list.findIndex((e) => e.id === il.data.id);
        state.invites_list[invite_index]={...state.invites_list[invite_index],...il.data};
        // if(invite_index>=0) state.invites_list.splice(invite_index, 1);
        // state.invites_list = [il.data].concat(state.invites_list);
        // state.chat = {...cl.data}
      }
      if (data.invites_list_delete) {
        const il = data.invites_list_delete;
        const invite_index = state.invites_list.findIndex((e) => e.id === il.data.id);
        if(invite_index>=0) state.invites_list.splice(invite_index, 1);
      }
      if (data.invite) {
        state.invite = {...data.invite};
      }
      if (data.invite_data) {
        state.invite_data = {...data.invite_data};
      }
      if (state.invite_data && data.blocked !== undefined) {
        state.invite_data.blocked = data.blocked;
      }
      if (data.invite_message_delete) {
        const im = data.invite_message_delete;
        const message_index = state.invite_data.messages.findIndex((e) => e.id === im);
        if(message_index>=0) state.invite_data.messages.splice(message_index, 1);
      }

      if (data.user_unread_count) {
        if (state.invites_list) {
          let index = state.invites_list.findIndex((e) => e.id === data.user_unread_count.id);
          if (index >= 0) {
            state.invites_list[index].user_unread_count = data.user_unread_count.count_unread;
          }
        }
      }
      if (data.q || data.q === '') {
        console.log('Invites:', data.q);
        state.q = data.q;
      }
      if (data.filter_id || data.filter_id === '') {
        state.filter_id = data.filter_id;
      }
      if (state.invite_data && data.blocked !== undefined) {
        state.invite_data.blocked = data.blocked;
      }
      if (data.invite_send_obj) {
        state.invite_send_obj = {...data.invite_send_obj};
      }
      if (data.open_mobile !== undefined) {
        state.open_mobile = data.open_mobile;
      }
    },
    ResetInviteData(state) {
      state.invite_id = '';
      state.invites_list = null;
      state.invite = null;
      state.invite_data = null;
      state.open_mobile = false;
    },
    UpdateInviteData(state, data) {
      if (!state.invite_data) {
        state.invite_data = {};
      }
      if (!state.invite_data.messages) {
        state.invite_data.messages = [];
      }
      state.invite_data.messages.push({...data, ...{unread: true}});
    },
  },
  actions: {
    ///chat/list?filterid=SelectedFilterID&q=SearchText
    GetInvitesList({dispatch, state}, options) {
      let endpoint = '/chats';

      let call_options = options ? {...options} : {};
      if (state.q && state.q !== '') {
        call_options.q = state.q;
      }
      if (state.filter_id && state.filter_id !== '') {
        call_options.filter_id = state.filter_id;
      }

      dispatch('GetCall', {
        endpoint: endpoint,
        options: call_options,
        callback: (data) => {
          if(!options.new_list) {
            state.invites_list = state.invites_list.concat(data.rows);
          }else{
            state.invites_list = Array.from(data.rows);
          }
          state.invites_pagination = data.pagination;
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('Invites:', error_status);
        },
      })
    },
    SendInvite({dispatch}, data_obj) {
      dispatch('PostCall', {
        endpoint: '/chats/invite',
        options: data_obj,
        callback: (response) => {
          data_obj.callback(response);
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('SendInvite:', error_status);
        },
      })
    },
    UpdateInvite({dispatch, commit, state}, options) {
      dispatch('GetCall', {
        endpoint: `/chats/${state.invite.id}/messages`,
        options: options.options,
        callback: (data) => {
          commit('SetInviteData', {invite_data: data, /*chat_timer_data: data.chatting*/});
          if (options.scroll_to_top) {
            state.invite_scroll_act = true
          }
          router.push({path: `/invites/${options.user.user_id}`});
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },

  },
  getters: {}
}
