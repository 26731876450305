<template>
  <main>
    <CommunicationTabs/>
    <div class="container">
<!--      <section class="chat chat-invite">-->
      <section class="chat" :class="open_mobile?'is-shifted':''">
        <I_LeftPart/>
        <I_Top/>
        <I_Window/>
        <I_SendField/>
      </section>
    </div>
  </main>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import I_LeftPart from "./Parts/I_LeftPart";
import I_Top from "./Parts/I_Top";
import I_Window from "./Parts/I_Window";
import I_SendField from "./Parts/I_SendField";
import CommunicationTabs from "../Misc/CommunicationTabs";
import router from "@/router";

export default {
  name: "Invites",
  components: { CommunicationTabs, I_SendField, I_Window, I_Top, I_LeftPart },
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      user_data: state => state.User.user_data,
      invites_list: state => state.Invites.invites_list,
      open_mobile: state => state.Invites.open_mobile,
    }),
  },
  mounted() {
    this.SetCommunicationData({ communication_type: 0 });
    this.SetIdFromUrl(this.id);
  },
  methods: {
    ...mapMutations(['SetCommunicationData', 'SetInviteData']),
    ...mapActions(['UpdateInvite']),
    SetIdFromUrl(newVal){
      let elem = this.invites_list.find(item => item.user_id.toString() === newVal.toString());
      console.log('Invites:watch:id:elem', elem)
      if (elem) {
        this.SetInviteData({invite: elem, invite_id: newVal});
        this.UpdateInvite({user: elem, options: {page: 0}});
      } else {
        this.SetInviteData({invite_id: newVal});
        router.push({path: `/invites`});
      }
    }
  },
  watch: {
    id: {
      handler(newVal) {
        this.SetIdFromUrl(newVal);
      },
      deep: false
    },
  },
}
</script>

<style scoped>

</style>
