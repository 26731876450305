<template>
  <dialog class="popup" id="boost-account" :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 464px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">You don't have enough coins</div>
      <div class="popup-content">
        <div class="popup-button-centered">
          <button type="button" class="button">Boost account</button>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "PNoCoins",
  data: function () {
    return {
      is_open: false,
      description: '',
      mood_id: 0,
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      user_data: state => state.User.user_data,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupData',]),

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({ popup_opened: false });
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if ( newVal === false ) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>