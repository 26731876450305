<template>
  <!--  <div class="tip-bg" @click="CloseTip"></div>-->
  <div class="tip-holder"
       ref="tip_holder"
       :style="'left:'+calcPosition.x+'px; top:'+calcPosition.y+'px;'">
    <DDBurgerNav v-if="dd_name === 'menu'" ref="Dropdown"/>
    <DDMessages v-if="dd_name === 'messages'" ref="Dropdown"/>
    <DDNotifications v-if="dd_name === 'notifications'" ref="Dropdown"/>
    <DDProfiles v-if="dd_name === 'profiles'" ref="Dropdown"/>
    <DDChatFilter v-if="dd_name === 'chat_filter'" ref="Dropdown"/>
    <DDFavorites v-if="dd_name === 'favorites'" ref="Dropdown"/>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import DDBurgerNav from "./Parts/DDBurgerNav";
import DDNotifications from "./Parts/DDNotifications";
import DDProfiles from "./Parts/DDProfiles";
import DDMessages from "./Parts/DDMessages";
import DDChatFilter from "./Parts/DDChatFilter";
import DDFavorites from "./Parts/DDFavorites";

export default {
  name: "Dropdowns",
  components: { DDFavorites, DDChatFilter, DDMessages, DDProfiles, DDNotifications, DDBurgerNav },
  computed: {
    ...mapState({
      dd_name: state => state.Dropdowns.dd_name,
      position_target: state => state.Dropdowns.position_target,
    }),
  },
  data: () => {
    return {
      calcPosition: {
        x: 0,
        y: 0
      },
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    window.addEventListener('mousemove', this.CalcMousePosition);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
    window.removeEventListener('mousemove', this.CalcMousePosition);
  },
  mounted() {
    this.CalcDDPosition();
  },
  methods: {
    ...mapMutations(['SetDDData']),
    CloseTip() {
      this.SetDDData({ dd_opened: false });
    },
    CalcMousePosition(event) {
      let target = document.getElementsByClassName(this.position_target.hover)[0];

      if ( event.clientY < target.getBoundingClientRect().top ) {
        this.CloseTip();
      } else {
        if ( event.clientY >= target.getBoundingClientRect().top && event.clientY <= target.getBoundingClientRect().bottom ) {
          if ( event.clientX < target.getBoundingClientRect().left || event.clientX > target.getBoundingClientRect().right ) {
            this.CloseTip();
          }
        } else {
          if ( event.clientY > target.getBoundingClientRect().bottom && event.clientY < this.$refs.Dropdown.$el.getBoundingClientRect().top ) {
            if ( event.clientX < this.$refs.Dropdown.$el.getBoundingClientRect().left || event.clientX > this.$refs.Dropdown.$el.getBoundingClientRect().right ) {
              this.CloseTip();
            }
          } else {
            if ( event.clientY >= this.$refs.Dropdown.$el.getBoundingClientRect().top && event.clientY <= this.$refs.Dropdown.$el.getBoundingClientRect().bottom ) {
              if ( event.clientX < this.$refs.Dropdown.$el.getBoundingClientRect().left || event.clientX > this.$refs.Dropdown.$el.getBoundingClientRect().right ) {
                this.CloseTip();
              }
            } else {
              this.CloseTip();
            }
          }
        }
      }
    },
    CalcDDPosition() {


      let target_x = document.getElementsByClassName(this.position_target.x)[0];
      let target_y = document.getElementsByClassName(this.position_target.y)[0];
      let x_pos = target_x.getBoundingClientRect().x;
      let y_pos = target_y.getBoundingClientRect().y;

      if ( this.position_target.deltaX ) {
        x_pos += this.position_target.deltaX;
      }
      if ( this.position_target.deltaY ) {
        y_pos += this.position_target.deltaY;
      }

      let position = {
        x: x_pos,
        y: y_pos,
        width: target_x.clientWidth,
        height: target_y.clientHeight
      };

      console.log('CalcDDPosition', position, this.$refs.tip_holder.clientWidth);

      if ( position.x > window.innerWidth / 2 ) {
        this.calcPosition.x = position.x - this.$refs.tip_holder.clientWidth + position.width
      } else {
        this.calcPosition.x = position.x - position.width
      }
      this.calcPosition.y = position.y + position.height

    },
    myEventHandler() {
      this.CalcDDPosition();
    }

  },
  watch: {
    position_target: {
      handler() {
        setTimeout(() => {
          this.CalcDDPosition();
        }, 50);
      },
      deep: true
    },
  },
}
</script>

<style lang="less" scoped>
.tip-bg {
  position   : fixed;
  z-index    : 9998;
  background : rgba(0, 0, 0, .01);
  width      : 100%;
  height     : 100%;
  top        : 0;
  left       : 0;
}
.tip-holder {
  position : fixed;
  z-index  : 9999;
  //transition : left .3s ease-in-out, top .3s ease-in-out;
  .tip-content {
    display            : block;
    background         : #fff;
    padding            : 20px;
    border-radius      : 4px;
    -webkit-box-shadow : 0 5px 20px rgba(0, 0, 0, .15);
    box-shadow         : 0 5px 20px rgba(0, 0, 0, .15);
    color              : #303030;
  }
}
</style>