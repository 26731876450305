<template>
  <div class="tip-content" id="chat-filter">
    <ul class="chat-menu">
      <li>
        <div class="chat-menu-all" :class="filter_id === '' ? 'active':''" @click="SetFilter()">All</div>
      </li>
      <!--      <li>-->
      <!--        <a href="#" class="chat-menu-sent">Sent</a>-->
      <!--      </li>-->
      <li>
        <div class="chat-menu-unread" :class="filter_id === 'unread' ? 'active':''" @click="SetFilter('unread')">
          <i>1</i>
          <span>Unread</span>
        </div>
      </li>
      <li>
        <div class="chat-menu-no-answer" :class="filter_id === 'noanswer' ? 'active':''" @click="SetFilter('noanswer')">No answer</div>
      </li>
      <li>
        <div class="chat-menu-blocked" :class="filter_id === 'blocked' ? 'active':''" @click="SetFilter('blocked')">Blocked</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "DDChatFilter",
  computed: {
    ...mapState({
      i_filter_id: state => state.Invites.filter_id,
      c_filter_id: state => state.Chat.filter_id,
      l_filter_id: state => state.Letters.filter_id,
      communication_type: state => state.Communications.communication_type,
    }),
  },
  data: () => {
    return {
      //noanswer , unread, blocked
      filter_id: '',
      active_filter: ''
    }
  },
  mounted() {
    switch (this.communication_type) {
      case 0:
        this.filter_id = this.i_filter_id;
        break;
      case 1:
        this.filter_id = this.c_filter_id;
        break;
      case 2:
        this.filter_id = this.l_filter_id;
        break;
    }
  },
  methods: {
    ...mapMutations(['SetInviteData', 'SetChatData', 'SetLetterData', 'SetDDData']),
    ...mapActions(['GetInvitesList', 'GetChatsList', 'GetLettersList',]),
    SetFilter(filter_id = '') {
      this.active_filter = filter_id;
      switch (this.communication_type) {
        case 0:
          this.SetInviteData({ filter_id: this.active_filter });
          this.GetInvitesList({new_list:true});
          break;
        case 1:
          this.SetChatData({ filter_id: this.active_filter });
          this.GetChatsList({new_list:true});
          break;
        case 2:
          this.SetLetterData({ filter_id: this.active_filter });
          this.GetLettersList({new_list:true});
          break;
      }
      this.SetDDData({ dd_opened: false });
    }
  }
}
</script>

<style scoped>

</style>
