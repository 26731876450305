<template>
  <dialog class="popup" id="popup-message" style="max-width: 580px">
    <div class="popup-box">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header popup-header-buy">Previous payment has not been completed</div>
      <div class="popup-bottom" v-if="payment">
        <div class="popup-bottom-buttons">
          <a :href="payment.invoice_url" type="button" class="button" target="_blank">Continue payment</a>
          <button type="button" class="button" @click="NewPayment">New payment</button>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
/*{
  "pay_address": "TVmGHsvmXV9TBSRZKNEje6eWN6Hyd4CW38",
  "price_amount": 20
}*/
export default {
  name: "PShowPaymentInfo",
  data: function () {
    return {}
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      payment: state => state.User.payment,
    }),
  },
  mounted() {

  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData']),
    ...mapActions(['GetCall', 'PutCall',]),

    Buy(coins) {

      let form = {
        coins: coins,
        // currency:currency
      }

      this.PutCall({
        endpoint: '/users/billing/refill',
        options: form,
        callback: (data) => {
          //console.log(data);
          this.SetUserData({coins: parseInt(data.coins)});
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },
    NewPayment(){
      this.SetPopupData({
        private_flag: false,
        popup_name: 'buy_coins',
        popup_opened: true,
      })
    },

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>