<template>
  <dialog class="popup" id="mood" :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 464px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Today my mood is</div>
      <form class="form">
        <div class="popup-content">
          <ul class="mood-select">
            <li>
              <label>
                <input type="radio" name="mood" id="mood-1" value=0 v-model="mood_id">
                <span>
								<img src="/img/mood-1.svg" alt="">
								<i>Serious</i>
							</span>
              </label>
            </li>
            <li>
              <label>
                <input type="radio" name="mood" id="mood-2" value=1 v-model="mood_id">
                <span>
								<img src="/img/mood-2.svg" alt="">
								<i>Pen pal</i>
							</span>
              </label>
            </li>
            <li>
              <label>
                <input type="radio" name="mood" id="mood-3" value=2 v-model="mood_id">
                <span>
								<img src="/img/mood-3.svg" alt="">
								<i>Romantic</i>
							</span>
              </label>
            </li>
            <li>
              <label>
                <input type="radio" name="mood" id="mood-4" value=3 v-model="mood_id">
                <span>
								<img src="/img/mood-4.svg" alt="">
								<i>Flirtry</i>
							</span>
              </label>
            </li>
            <li>
              <label>
                <input type="radio" name="mood" id="mood-5" value=4 v-model="mood_id">
                <span>
								<img src="/img/mood-5.svg" alt="">
								<i>Naughty</i>
							</span>
              </label>
            </li>
          </ul>
          <fieldset>
            <label>Comment</label>
            <textarea class="textfield" v-model="description"></textarea>
          </fieldset>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottom-buttons">
            <div class="button" @click="Save">Save</div>
          </div>
        </div>
      </form>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PMood",
  data: function () {
    return {
      is_open: false,
      description: '',
      mood_id: 0,
      mood_img:'',
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      user_data: state => state.User.user_data,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
      if (this.user_data) {
        if (this.user_data.mood_id !== null && this.user_data.mood_id !== undefined) {
          this.mood_id = this.user_data.mood_id;
          if(this.user_data.mood_description) {
            this.description = this.user_data.mood_description;
          }
        }
      }
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData']),
    ...mapActions(['PostCall', 'PutCall',]),

    ClearDescription() {
      this.description = ''
    },

    Save() {

      this.mood_id = parseInt(this.mood_id);

      let form = {
        description: this.description,
        mood_id: this.mood_id
      }

      this.PutCall({
        endpoint: '/users/updatemood',
        options: form,
        callback: (data) => {
          console.log(data);
          this.SetUserData({mood_id: this.mood_id, mood_description:this.description});
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },


    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>
