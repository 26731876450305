<template>
  <section class="box">
    <div class="box-header">
      <div class="box-header-item">
        <h3>About me</h3>
      </div>
    </div>
    <div class="box-content">
      <form class="form">
        <div class="form-cols">
          <template v-for="(value, index) in about_me_dd_data" v-bind:key="index">
            <template v-if="about.map(e => { return e.item_id; }).indexOf(value.id) > -1">
              <template v-for="(value2, index2) in about" v-bind:key="index2">
                <UP_AboutMe_Elem v-if="value.id === value2.item_id" :data="value" :selected="value2"/>
              </template>
            </template>
            <template v-else>
              <UP_AboutMe_Elem :data="value" :selected="undefined"/>
            </template>
          </template>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import UP_AboutMe_Elem from "@/components/01UserProfile/Parts/UP_AboutMe/UP_AboutMe_Elem";

export default {
  name: "UP_AboutMe",
  components:{
    UP_AboutMe_Elem,
  },
  computed: {
    ...mapState({
      about: state => state.User.user_data.about,
      about_me_dd_data: state => state.User.about_me_dd_data,
    }),
  },
}
</script>

<style scoped>

</style>