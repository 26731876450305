<template>
  <Slider
      class="rules-range"
      v-model="range"
      :min="range_min"
      :max="range_max"
      :tooltips="true"
      :merge="5"
      @change="sync"
  />
</template>

<script>
import Slider from '@vueform/slider'
export default {
  name: "GRange",
  components: {
    Slider,
  },
  props: {
    modelValue: {
      type: Array,
      // default: {}
    },
    range_min: {
      type: Number,
      default: 0
    },
    range_max: {
      type: Number,
      default: 100
    },
  },
  data: () => {
    return {
      range: [0, 100],
      externalChange: false,
      val: {
        range_low: 0,
        range_high: 100,
      }
    }
  },
  emits: [
    'update:modelValue'
  ],
  mounted() {
    this.externalChange = true;
    setTimeout(() => {
      if (!this.modelValue) {
        this.range = Array.from(this.modelValue)
        // this.val.range_low = this.modelValue.range_low;
        // this.val.range_high = this.modelValue.range_high;
      }
    }, 50);
    setTimeout(()=>{
      this.externalChange = false;
    },100);
  },
  methods: {
    sync() {
      // console.log('sync ',this.range);
      setTimeout(()=>{
        // console.log('sync2 ',this.range);
        if (!this.externalChange) {
          this.$emit('update:modelValue', this.range);
        }
      },50);
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.externalChange = true;
        setTimeout(() => {
          if (newVal) {
            console.log('watch ',this.range, newVal);
            this.range = Array.from(newVal)
            // console.log('watch2 ',this.range);
            // this.val.range_low = this.modelValue.range_low;
            // this.val.range_high = this.modelValue.range_high;
          }
        }, 50);
        setTimeout(()=>{
          this.externalChange = false;
        },100);
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
.rules-range {
  position                      : relative;
  left                          : 0;
  top                           : 8px;

  /*margin                        : 25px 0 15px;*/

  --slider-bg                   : #E7ECF0;
  --slider-connect-bg           : #BA37D0;
  --slider-connect-bg-disabled  : #9CA3AF;
  --slider-height               : 6px;
  --slider-vertical-height      : 300px;
  --slider-radius               : 9999px;

  --slider-handle-bg            : #BA37D0;
  --slider-handle-border        : 0;
  --slider-handle-width         : 20px;
  --slider-handle-height        : 20px;
  --slider-handle-radius        : 9999px;
  --slider-handle-shadow        : 0.5px 0.5px 2px 1px rgba(144, 118, 221, 0.18);
  --slider-handle-shadow-active : 0.5px 0.5px 2px 1px rgba(144, 118, 221, 0.18);
  --slider-handle-ring-width    : 3px;
  --slider-handle-ring-color    : #9076DD2D;

  --slider-tooltip-bg           : #FFFFFF;
  --slider-tooltip-color        : #444444;
  --slider-tooltip-radius       : 5px;
  --slider-tooltip-min-width    : 20px;
  --slider-tooltip-font-size    : 0.875rem;
  --slider-tooltip-line-height  : 1.25rem;
  --slider-tooltip-font-weight  : 600;
  --slider-tooltip-py           : 2px;
  --slider-tooltip-px           : 6px;
  --slider-tooltip-arrow-size   : 5px;
  --slider-tooltip-distance     : 3px;
}
</style>
<style>
  @import "@vueform/slider/themes/default.css";
</style>
