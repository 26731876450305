<template>
  <li>
    <figure>
      <div class="media">
        <picture class="media-preview" @click="ViewVideo">
          <!--          <source srcset="/pic/video.webp 1x, /pic/video@2x.webp 2x" type="image/webp">-->
          <!--          <source srcset="/pic/video.jpg 1x, /pic/video@2x.jpg 2x" type="image/jpeg">-->
          <img :src="static_server+'/'+video.preview" alt="">
        </picture>
        <button type="button" class="media-remove" @click="Delete"></button>
      </div>
      <figcaption class="media-caption">
        <div class="scrollbar-inner" v-if="video.description && video.description.toString().replace(' ','')!==''">{{ video.description }}</div>
        <div class="scrollbar-inner" v-else>No description</div>
      </figcaption>
    </figure>
  </li>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

/*{
  "user_id"
  "video_url"
  "description"
  "preview"
  "id"
}*/
export default {
  name: "VideoListElement",
  props: {
    index: Number,
    video: Object,
    private: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      private_videos: state => state.User.user_data.private_videos,
      videos: state => state.User.user_data.videos,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData',]),
    ...mapActions(['DeleteCall']),

    ViewVideo() {
      this.SetPopupData({
        video_view_data: this.private ? this.private_videos : this.videos,
        video_view_index: this.index,
        private_flag: false,
        popup_name: 'view_video',
        popup_opened: true,
      })
    },
    Delete() {
      let endpoint = '/users/videos/' + this.video.id;
      if (this.private) {
        endpoint = '/users/private_videos/' + this.video.id;
      }

      this.DeleteCall({
        endpoint: endpoint,
        options: '',
        callback: () => {
          if (!this.private) {
            this.SetUserData({remove_videos: this.video});
          } else {
            this.SetUserData({remove_private_videos: this.video});
          }
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },
  }
}
</script>

<style scoped>

</style>
