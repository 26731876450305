<template>
  <div class="container">
    <h1>Contacts</h1>
    <template v-for="(value, index) in contacts" v-bind:key="index">
      <p v-if="!value.type || value.type==='text'">
        <b>{{ value.title }}</b>: <i>{{ value.text }}</i>
      </p>
      <p v-else-if="value.type==='link'">
        <b>{{ value.title }}</b>: <a :href="value.link">{{ value.link }}</a>
      </p>
      <p v-else-if="value.type==='email'">
        <b>{{ value.title }}</b>: <a :href="'mailto:'+value.link">{{ value.link }}</a>
      </p>
    </template>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Contacts",
  data() {
    return {
      contacts: [],
    };
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
    }),
  },
  mounted() {
    this.LoadJson();
  },
  methods: {
    async LoadJson() {
      let fetchOpt = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        referrerPolicy: "strict-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }

      try {
        const response = await fetch(this.static_server + '/contacts.json', fetchOpt);
        if (response.ok) {
          let response_parse = await response.json();
          console.log('Networking:response', response_parse);
          this.contacts = Array.from(response_parse);
        } else {
          console.log('Networking:response:catch', response);
        }
      } catch (error) {
        console.log('Networking:response', error);
      }
    },
  }

}
</script>

<style>
</style>
