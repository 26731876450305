export default {
	state: {
		search_result: [],
	},
	mutations: {
		SetSearchResult(state,data){
			state.search_result = {...data};
		},
	},
	actions: {
		GetSearchResult({dispatch, commit}, options){
			dispatch('GetCall',{
				endpoint: '/search/users',
				options: options,
				// options: {},
				callback: (data) => {
					commit('SetSearchResult',data);
				},
				on_error: (error) => {
					let error_status = error.response.status;
					console.log(error_status);
				},
			})
		}
	},
	getters: {}
}
