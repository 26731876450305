<template>
  <div class="tip-content" id="profiles">
    <div class="profiles" data-simplebar>
      <ul>
        <li>
          <div class="profiles-avatar">
            <picture>
              <img :src="static_server + '/' + main_profile.main_photo" alt="">
            </picture>
          </div>
          <div class="profiles-name"
               :class="!selected_user ? 'is-active':''"
               @click="SelectUser(null)">
            <span>{{ main_profile.user_name }}</span>
          </div>
          <div class="profiles-buttons">
            <!--            <div class="profiles-logout"></div>-->
          </div>
        </li>
        <template v-if="profiles && profiles.length > 0">
          <template v-for="(value, index) in profiles" v-bind:key="index">
            <li>
              <div class="profiles-avatar">
                <picture>
                  <img :src="static_server + '/' + value.main_photo" alt="">
                </picture>
              </div>
              <div class="profiles-name"
                   :class="selected_user === value.id ? 'is-active':''"
                   @click="SelectUser(value.id)">
                <span>{{ value.user_name }}</span>
              </div>
              <div class="profiles-buttons">
                <div class="profiles-stealth" :class="(selected_user === value.id && profiles_visible===false) ? '':'is-active'" @click="SetVisible(value.id)"></div>
                <!--              <div class="profiles-logout"></div>-->
              </div>
            </li>
          </template>
        </template>
      </ul>
    </div>
    <div class="profiles-bottom">
      <!--      <a href="#" class="profiles-add">Add another profile</a>-->
      <div class="profiles-logout">Use main profile</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "DDProfiles",
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      user_data: state => state.User.user_data,
      selected_user: state => state.User.selected_user,
      profiles: state => state.User.profiles,
      profiles_visible: state => state.User.profiles_visible,
      main_profile: state => state.User.main_profile,
    }),
  },
  methods: {
    ...mapMutations(['SetUserData', 'ClearDDMessages']),
    ...mapActions(['Logout', 'GetScopeUpdate']),
    SelectUser(id) {
      this.SetUserData({selected_user: id, profiles_visible: false});

      let query = Object.assign({}, this.$route.query);

      if (id) {
        query.mid = id;
        this.$router.replace({query: query});
      } else {
        delete query.mid;
        this.$router.replace({query: query});
      }

      setTimeout(() => {
        // this.ClearDDMessages();
        this.GetScopeUpdate({change_user: true});
        this.$router.go(this.$router.currentRoute);
      }, 100);
    },
    SetVisible(id) {
      this.SetUserData({profiles_visible: !this.profiles_visible});
      console.log(id);
    }
  }
}
</script>

<style scoped>

</style>
