<template>
  <footer>
    <div class="container">
      <nav class="footer-nav">
        <ul>
<!--          <li>-->
<!--            <a href="#">News</a>-->
<!--          </li>-->
          <li>
            <router-link to="/faq">F.A.Q</router-link>
          </li>
          <li>
            <a href="/terms_and_conditions.pdf">Terms and conditions</a>
          </li>
<!--          <li>-->
<!--            <a href="#">Love story</a>-->
<!--          </li>-->
          <li>
            <router-link to="/contacts">Contacts</router-link>
          </li>
        </ul>
      </nav>
      <div class="footer-copyright">&copy; {{ new Date().getFullYear() }} All rights reserved</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
