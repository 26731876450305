// import axios from "axios";
import {io} from "socket.io-client";
import router from "../../router";

export default {
  state: {
    socket: null,
    socket_reconnect_interval: undefined,
    get_api_server_timeout: undefined,
  },
  mutations: {},
  actions: {
    async GetCall({rootState, dispatch, getters}, data_obj) {
      let options_str;
      if (data_obj.options === undefined) {
        options_str = '';
      } else {
        options_str = Object.keys(data_obj.options).reduce((prev, key, i) => (
          `${prev}${i !== 0 ? '&' : ''}${key}=${data_obj.options[key]}`
        ), '');
      }
      if (options_str.length > 0) {
        options_str = '?' + options_str;
      }

      let callSever = rootState.Main.api_server;
      if (data_obj.server) {
        callSever = data_obj.server;
      }
      let path = callSever + data_obj.endpoint + options_str;

      console.log('Networking:Get:path:', path);

      let headers = getters.HeaderGenerate();

      let fetchOpt = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        headers: headers,
        referrerPolicy: "strict-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }

      try {
        const response = await fetch(path, fetchOpt);
        if (response.ok) {
          dispatch('CallbackWorker', {response: response, data_obj: data_obj});
        } else {
          dispatch('ErrorWorker', {response: response, data_obj: data_obj});
        }
      } catch (error) {
        dispatch('ErrorWorker', {response: error, data_obj: data_obj});
      }

      /*
        axios
          .get(path, {withCredentials: true, headers: headers})
          // .get(path)
          .then((response) => {
            dispatch('CallbackWorker', {response: response, data_obj: data_obj});
          })
          .catch((error) => {
            console.log('Networking: error', error);
            dispatch('ErrorWorker', {error: error, data_obj: data_obj});
          });
      */
    },
    async PostCall({rootState, dispatch, getters}, data_obj) {
      console.log(data_obj);
      if (data_obj.options === undefined) {
        data_obj.options = {}
      }

      let h_type = data_obj.options instanceof FormData ? 'formData' : 'json';

      let callSever = rootState.Main.api_server;
      if (data_obj.server) {
        callSever = data_obj.server;
      }
      let path = callSever + data_obj.endpoint;
      console.log('Networking: Post', path);

      let headers = getters.HeaderGenerate({h_type: h_type});
      //let objstring = JSON.stringify(data_obj.options).replace('}','').replace('{','').replace(':','=').replace('"','').replace(',','\n');
      let fetchOpt = {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        headers: headers,
        referrerPolicy: "strict-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: h_type === 'formData' ? data_obj.options : JSON.stringify(data_obj.options),
      }

      try {
        const response = await fetch(path, fetchOpt);
        // const result = await response.json();

        if (response.ok) {
          dispatch('CallbackWorker', {response: response, data_obj: data_obj});
        } else {
          dispatch('ErrorWorker', {response: response, data_obj: data_obj});
        }
      } catch (error) {
        dispatch('ErrorWorker', {response: error, data_obj: data_obj});
      }

      /*
            axios
              .post(path, data_obj.options, {withCredentials: true, headers: headers})
              .then((response) => {
                dispatch('CallbackWorker', {response: response, data_obj: data_obj});
              })
              .catch((error) => {
                console.log('Networking: error', error);
                dispatch('ErrorWorker', {error: error, data_obj: data_obj});
              });
      */
    },
    async PutCall({rootState, dispatch, getters}, data_obj) {
      if (data_obj.options === undefined) {
        data_obj.options = {}
      }
      let h_type = data_obj.options instanceof FormData ? 'formData' : 'json';

      let callSever = rootState.Main.api_server;
      if (data_obj.server) {
        callSever = data_obj.server;
      }
      let path = callSever + data_obj.endpoint;

      console.log('Networking: Put', path);

      let headers = getters.HeaderGenerate({h_type: h_type});
      let fetchOpt = {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        headers: headers,
        referrerPolicy: "strict-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: h_type === 'formData' ? data_obj.options : JSON.stringify(data_obj.options),
      }
      if (data_obj.no_cors) {
        fetchOpt = {...fetchOpt, ...{mode: 'no-cors'}}
      }

      try {
        const response = await fetch(path, fetchOpt);
        // const result = await response.json();

        if (response.ok) {
          dispatch('CallbackWorker', {response: response, data_obj: data_obj});
        } else {
          dispatch('ErrorWorker', {response: response, data_obj: data_obj});
        }
      } catch (error) {
        dispatch('ErrorWorker', {response: error, data_obj: data_obj});
      }

      /*
            axios
              .put(path, data_obj.options, {withCredentials: true, headers: headers})
              .then((response) => {
                dispatch('CallbackWorker', {response: response, data_obj: data_obj});
              })
              .catch((error) => {
                console.log('Networking: error', error);
                dispatch('ErrorWorker', {error: error, data_obj: data_obj});
              });
      */
    },
    async DeleteCall({rootState, dispatch, getters}, data_obj) {
      let options_str;
      if (data_obj.options === undefined) {
        options_str = '';
      } else {
        options_str = Object.keys(data_obj.options).reduce((prev, key, i) => (
          `${prev}${i !== 0 ? '&' : ''}${key}=${data_obj.options[key]}`
        ), '');
      }
      if (options_str.length > 0) {
        options_str = '?' + options_str;
      }

      let callSever = rootState.Main.api_server;
      if (data_obj.server) {
        callSever = data_obj.server;
      }
      let path = callSever + data_obj.endpoint + options_str;

      console.log('Networking: Delete', path);


      let headers = getters.HeaderGenerate();
      let fetchOpt = {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        headers: headers,
        referrerPolicy: "strict-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }

      try {
        const response = await fetch(path, fetchOpt);
        if (response.ok) {
          dispatch('CallbackWorker', {response: response, data_obj: data_obj});
        } else {
          dispatch('ErrorWorker', {response: response, data_obj: data_obj});
        }
      } catch (error) {
        dispatch('ErrorWorker', {response: error, data_obj: data_obj});
      }
      /*
            axios
              .delete(path, {withCredentials: true, headers: headers})
              .then((response) => {
                dispatch('CallbackWorker', {response: response, data_obj: data_obj});
              })
              .catch((error) => {
                console.log('Networking: error', error);
                dispatch('ErrorWorker', {error: error, data_obj: data_obj});
              });
      */
    },
    async CallbackWorker({dispatch}, data) {
      console.log('Networking:', data.response);

      try {
        let response = await data.response.json();
        console.log('Networking:response', response);

        switch (typeof data.data_obj.callback) {
          case "string":
            dispatch(data.data_obj.callback, response, {root: true});
            break;
          case "function":
            data.data_obj.callback(response);
            break;
        }
      } catch (e) {
        console.log('Networking:JSON parse error:', e);
      }
    },
    async ErrorWorker({dispatch, commit}, data) {
      console.log('Networking: error:', data);
      console.log('Networking: error code: ', data.response.status);

      // let response = await data.response.json();

      if (data.response.status === 401 || data.response.status === 0) {
        dispatch('DeleteCredentials', {root: true});
        commit('SetMainData', {
          loaded: true,
        });
      } /*else {
        switch (typeof data.data_obj.on_error) {
          case "string":
            dispatch(data.data_obj.on_error, response, {root: true})
            break;
          case "function":
            data.data_obj.on_error(response);
            break;
          default:
            break;
        }
      }*/
    },

    SocketConnect({rootState, state, dispatch, commit},) {
      console.log('Networking: try socket connect call');

      // let l_storage = window.localStorage;
      // let access_token = l_storage.getItem('token');
      //
      // if (!token) {
      //   token = access_token;
      // }
      let auth = {};
      if (rootState.User.selected_user) {
        auth.userid = rootState.User.selected_user;
      }

      const options = {
        // path: '/' ,
        secure: true,
        withCredentials: true,
        autoConnect: false,
        reconnectionDelayMax: 5000,
        reconnection: true,
        auth: auth,
        transports: ["websocket"],
        path: '/j/',
        /* query: {
           "my-key": "my-value"
         }*/
      }; //Options object to pass into SocketIO

      if (state.socket_reconnect_interval) {
        clearTimeout(state.socket_reconnect_interval);
      }

      if (!state.socket || (state.socket && !state.socket.connected)) {
        console.log('Networking: socket channel connect call');

        state.socket = io(rootState.Main.socket_server, options);

        state.socket.connect();

        state.socket.on("connect", () => {
          console.log('Networking: socket to notification channel connected');
        });

        state.socket.on("invite", (data) => {
          console.log('Networking: SocketIncoming: Invite Event: ', data);

          if (rootState.Invites.invite.id === data.chat_id) {
            commit('UpdateInviteData', data, {root: true});
          } else {
            let temp_chat_list = Array.from(rootState.Invites.invites_list);
            let index = temp_chat_list.map(e => e.id).indexOf(data.chat_id);
            if (index >= 0) {
              temp_chat_list.splice(index, 1);
              temp_chat_list.unshift(temp_chat_list[index]);
              commit('SetInviteData', {invites_list: temp_chat_list}, {root: true});
            }
          }

        });

        state.socket.on("chats", (data) => {
          console.log('Networking: SocketIncoming: Chats Event: ', data);
          const update = {data: data.data, index: data.index};
          if ((rootState.Chat.chat || rootState.Invites.invite) && data.method === 'update') {
            if (data.data.is_invite) {
              commit('SetInviteData', {invites_list_update: update}, {root: true});
            } else {
              commit('SetChatData', {chats_list_update: update, from: 'Socket.ON.Chats'}, {root: true});
            }
          }
          if ((rootState.Chat.chat || rootState.Invites.invite) && data.method === 'delete') {
            if (data.data.is_invite) {
              commit('SetInviteData', {invites_list_delete: update}, {root: true});
            } else {
              commit('SetChatData', {invites_list_delete: update}, {root: true});
            }
          }
        });

        state.socket.on("chat_messages", (data) => {
          console.log('Networking: SocketIncoming: Chat Messages Event: ', data, router.currentRoute);

          if (router.currentRoute._value.path.includes('/chat')) {
            // callback({received: true});
            if (rootState.Chat.chat) {
              // console.log('Networking: Chat Event: ',rootState.Communications.chat_user.id,data.chat_id,router.currentRoute._value.path);
              console.log(rootState.Chat.chat.id, data.chat_id)
              if (rootState.Chat.chat.id === data.chat_id) {
                commit('UpdateChatData', data, {root: true});
              } else {
                let temp_chat_list = Array.from(rootState.Chat.chats_list);
                let index = temp_chat_list.map(e => e.id).indexOf(data.chat_id);
                if (index >= 0) {
                  temp_chat_list[index].last_date = data.time;
                  temp_chat_list.unshift(temp_chat_list[index]);
                  temp_chat_list.splice(index + 1, 1);
                  commit('SetChatData', {chats_list: temp_chat_list}, {root: true});
                }
                ShowAlert(data);
              }
            }
          } else {
            if (router.currentRoute._value.path.includes('/invites')) {
              if (rootState.Invites.invite) {
                if (rootState.Invites.invite.id === data.chat_id) {
                  if(data.is_invite) {
                    commit('UpdateInviteData', data, {root: true});
                  }else{
                    router.push({path: `/chat/${rootState.Invites.invite.user_id}`});
                  }
                } else {
                  let temp_invites_list = Array.from(rootState.Invites.invites_list);
                  let index = temp_invites_list.map(e => e.id).indexOf(data.chat_id);
                  if (index >= 0) {
                    temp_invites_list[index].last_date = data.time;
                    temp_invites_list.unshift(temp_invites_list[index]);
                    temp_invites_list.splice(index + 1, 1);
                    commit('SetInviteData', {invites_list: temp_invites_list}, {root: true});
                  }
                }
              }
            } else {
              ShowAlert(data);
            }
          }

          function ShowAlert(data) {
            if (!data.self && !data.is_invite) {
              let alert = {
                type: 'message', //system, message
                img: data.content.user.photo,
                header: data.content.user.user_name,
                text: data.content.text,
                uid: data.content.user.id,
                link_name: 'Reply',
              }
              commit('SetAlertsData', {alert: alert}, {root: true});
            }
          }
        });
        state.socket.on("chat_message_delete", (data) => {
          console.log('Networking: SocketIncoming: Chat Message Delete Event: ', data, router.currentRoute);

          if (router.currentRoute._value.path.includes('/chat')) {
            if (rootState.Chat.chat) {
              console.log(rootState.Chat.chat.id, data.chat_id)
              if (rootState.Chat.chat.id === data.chat_id) {
                commit('SetChatData', {chat_message_delete: data.message_id}, {root: true});
              }
            }
          } else {
            if (router.currentRoute._value.path.includes('/invites')) {
              if (rootState.Invites.invite) {
                if (rootState.Invites.invite.id === data.chat_id) {
                  commit('SetInviteData', {invite_message_delete: data.message_id}, {root: true});
                }
              }
            }
          }
        });

        state.socket.on("chat_timer", (data) => {
          console.log('Networking: SocketIncoming: chat_timer event: ', data);
          if (data.method === 'start') {
            commit('SetChatData', {chat_timer_data: data, set_timer_duration: 0}, {root: true});
          }
          if (data.method === 'stop') {
            commit('SetChatData', {set_timer_duration: null}, {root: true});
          }
        });

        state.socket.on("letters", (data) => {
          console.log('Networking: SocketIncoming: Letters Event: ', data, router.currentRoute);

          if (router.currentRoute._value.path.includes('/letters')) {
            /*if(data.method==='create'){

            }*/
            if (data.method === 'update') {
              commit('UpdateLettersList', data, {root: true});
            }
            if (data.method === 'delete') {
              const list = {data: data.data, index: data.index};
              commit('SetLetterData', {communications_list_delete: list}, {root: true});
            }
          }

        });
        state.socket.on("letter", (data) => {
          console.log('Networking: SocketIncoming: Letter Event: ', data, router.currentRoute);

          // console.log(router.currentRoute._value.path.includes('/letters'),data.method === 'create',data.method === 'update');
          // console.log(rootState.Letters.communication,rootState.Letters.communication.id,data.communication_id,rootState.Letters.communication.id === data.communication_id);
          if (router.currentRoute._value.path.includes('/letters')) {
            let communication = rootState.Letters.communication;
            if (data.method === 'create' || data.method === 'update') {
              if (communication && communication.id === data.data.communication_id) {
                // console.log('Networking: SocketIncoming: Letter Event: current communication');
                commit('UpdateLetterData', data, {root: true});
              }
            }
            if (data.method === 'delete' && communication && communication.id === data.data.communication_id) {
              const list = {data: data.data, index: data.index};
              commit('SetLetterData', {letters_list_delete: list}, {root: true});
            }

            /*
                          commit('UpdateLetterData', data, {root: true});
                          if (rootState.Letters.communication && rootState.Letters.communication.id === data.communication_id) {
                            //commit('UpdateLetterData', data, { root: true });
                            console.log('Networking: SocketIncoming: Letter Event: current communication');
                          } else {
                            let temp_chat_list = Array.from(rootState.Letters.letters_list);
                            let index = temp_chat_list.map(e => e.id).indexOf(data.communication_id);
                            if (index >= 0) {
                              let temp_element = temp_chat_list[index];
                              temp_chat_list.splice(index, 1);
                              temp_chat_list.unshift(temp_element);
                              commit('SetLetterData', {letters_list: temp_chat_list}, {root: true});
                            }
                          }
            */
          } else {

            // if (!data.self) {
            //   let communication = rootState.Letters.communications_list.find(e=> e.id === data.data.communication_id);
            //
            //   let alert = {
            //     type: 'letter', //system, message, letter
            //     img: communication.user_photo_url,
            //     header: data.data.subject,
            //     text: data.data.content.text,
            //     uid: communication.user_id,
            //     link_name: 'Reply',
            //   }
            //   commit('SetAlertsData', {alert: alert}, {root: true});
            // }
          }
        });

        state.socket.on("unread", (data) => {
          console.log('Networking: SocketIncoming: Unread: ', data);
          commit('SetDDData', {dd_messages: data}, {root: true});
          /*
                      if (data.type === 'letter') {
                        data.items.forEach((value) => {
                          let to_upd = rootState.Letters.letters_list.find((item) => (item.user_id.toString() === value.user_id_from.toString() && value.type === 'letter'));
                          if (to_upd) {
                            if (value.count_unreaded) {
                              to_upd.user_unread_count = value.count_unreaded;
                            } else {
                              to_upd.user_unread_count = 0;
                            }
                          }
                        });
                      }
                      if (data.type === 'chat') {
                        let to_upd = rootState.Chat.chats_list.find(item => item.id.toString() === data.communication.communication_id.toString());
                        if (to_upd) {
                          if (data.communication && data.communication.count_unreaded) {
                            to_upd.user_unread_count = data.communication.count_unreaded;
                          } else {
                            to_upd.user_unread_count = 0;
                          }
                        } else {
                          to_upd.user_unread_count = 0;
                        }
                      }
          */
          /*if ( router.currentRoute._value.path.includes('/letters') || router.currentRoute._value.path.includes('/chat')) {
            commit('SetChatData', { user_unread_count: data }, { root: true });
          }*/
        });

        state.socket.on("favorites", (data) => {
          console.log('Networking: SocketIncoming: favorites event: ', data);
          commit('SetDDData', {dd_favorite: data}, {root: true});
        });

        state.socket.on("refill", () => {
          console.log('Networking: SocketIncoming: refill: ');
          commit('SetChatData', {set_timer_duration: null}, {root: true});
          commit('SetPopupData', {private_flag: false, popup_name: 'buy_coins', popup_opened: true,});
        });
        state.socket.on("refill_complete", (data) => {
          console.log('Networking: SocketIncoming: refill_complete: ');
          let totalCoins = parseInt(data.coins);
          commit('SetUserData', {
            coins: totalCoins,
            payment: null,
            user_data: {...rootState.User.user_data, ...{invoice_url: null}},
          }, {root: true});

          let alert = {
            type: 'refill', //system, message
            header: `You purchased ${data.amount} coins 👍`,
            text: `You have ${totalCoins} coins now`,
            //   link_name: 'Reply',
          }
          commit('SetAlertsData', {alert: alert}, {root: true});
          commit('SetPopupData', {private_flag: false, popup_name: '', popup_opened: false,});
        });

        state.socket.on("logout", () => {
          console.log('Networking: SocketIncoming: logout: ');
          dispatch('Logout', {}, {root: true});
        });

        state.socket.on("disconnect", (reason) => {
          console.log('Networking: socket channel disconnect', reason);
          // handleSocketFail();
        });

        state.socket.on("connect_failed", (reason) => {
          console.log('Networking: socket channel connect fall', reason);
          // handleSocketFail();
        });

      }

      /*function handleSocketFail() {
        state.socket.off();
        state.socket.disconnect();
        state.socket = undefined;
        state.socket_reconnect_interval = setTimeout(() => {
          if (token) {
            dispatch('SocketConnect', token);
          } else {
            dispatch('SocketConnect');
          }
        }, 2000);
        setTimeout(() => {
          dispatch('GetApiServer', {});
        }, 1000);
      }*/
    },

    SocketEmit({rootState, state, dispatch}, obj) {
      // SocketEmit({ rootState, state }, obj) {
      console.log('Networking: Socket Emits: ', obj);

      if (state.socket && state.socket.connected) {
        state.socket.emit(obj.type, obj.data, (response) => {

          console.log('Networking: Socket Response: ', response);

          if (router.currentRoute._value.path.includes('/chat')) {
            if (rootState.Chat.chat) {
              if (rootState.Chat.chat.id === response.chat_id) {
                //commit('UpdateChatData', response, { root: true });
                dispatch('UpdateChat', {user: rootState.Chat.chat, options: {page: 0}, scroll_to_top: true});
              }
            }
          }

          console.log('Networking:', typeof obj.callback);

          switch (typeof obj.callback) {
            case "string":
              dispatch(obj.callback, response, {root: true});
              break;
            case "function":
              obj.callback(response);
              break;
          }

        });
      } else {
        setTimeout(() => {
          dispatch('SocketEmit', obj);
        }, 1000);
      }

    },

    SetApiTimeout({state, dispatch}, data) {
      if (state.get_api_server_timeout) {
        clearTimeout(state.get_api_server_timeout);
      }
      state.get_api_server_timeout = setTimeout(() => {
        dispatch('GetApiServer', data);
      }, 5000);
    },
    async GetApiServer({dispatch, commit, rootState}, data) {
      console.log('route server call:');
      let headers = {'CurrentUser': 'default'};

      let fetchOpt = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        headers: headers,
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
      let fetchOpt2 = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit
        // headers: headers,
        referrerPolicy: "unsafe-url", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }

      try {
        const response = await fetch(rootState.Main.dns_server, fetchOpt2);
        const result = await response.json();

        console.log('reserve route server response:', result, result.Answer[0].data);

        commit('SetMainData', {reserve_api_server: `https://${result.Answer[0].data.replaceAll('"', '')}.dating.wedding:8083`}, {root: true});

      } catch (error) {
        console.log('reserve route server response: error:', error);
      }

      try {
        const response = await fetch(rootState.Main.route_server, fetchOpt);
        const result = await response.json();

        console.log('route server response:', result);
        if (result && result.result) {
          commit('SetMainData', {api_server: result.result}, {root: true});
          if (data && data.callback) {
            dispatch('CallbackWorker', {response: result, data_obj: data});
          }
          // if (!rootState.User.draw_scope_loaded) {
          //   setTimeout(() => {
          //     dispatch('GetScopeUpdate', {root: true});
          //   }, 100)
          // }
        } else {
          dispatch('SetApiTimeout', data);
        }
      } catch (error) {
        console.log('route server response: error:', error);
        commit('SetMainData', {api_server: rootState.Main.reserve_api_server}, {root: true});
      }

      /*
            await axios
              .get(rootState.Main.route_server, {withCredentials: true, headers: headers})
              .then((response) => {
                console.log('route server response:', response);
                if (response && response.data && response.data.result) {
                  commit('SetMainData', {api_server: response.data.result}, {root: true});
                  if (data && data.callback) {
                    dispatch('CallbackWorker', {response: response, data_obj: data});
                  }
                  if (!rootState.User.draw_scope_loaded) {
                    setTimeout(() => {
                      dispatch('GetScopeUpdate', {root: true});
                    }, 100)
                  }
                } else {
                  dispatch('SetApiTimeout', data);
                }
              })
              .catch((error) => {
                console.log('route server response: error:', error);
                //dispatch('SetApiTimeout', data);
              });
      */
    },
  },
  getters: {
    HeaderGenerate: (state, getters, rootState) => (options) => {
      // let return_obj = {'Authorization': `Bearer ${token}`};
      let return_obj;
      // let return_obj = {'x-time': new Date().getTime()};
      if (options && options.h_type !== 'formData') {
        return_obj = {...return_obj, ...{"Content-Type": "application/json",}};
      }

      if (rootState.User.selected_user) {
        return_obj = {...return_obj, ...{'CurrentUser': rootState.User.selected_user}};
      } else {
        return_obj = {...return_obj, ...{'CurrentUser': 'default'}};
      }
      return return_obj
    },
  }
}
