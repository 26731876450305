import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import PerfectScrollbar from 'vue3-perfect-scrollbar'
// import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';

import vue3GoogleLogin from 'vue3-google-login'

import {CircleStencil, Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';


createApp(App)
  .use(store)
  .use(router)
  .use(PerfectScrollbarPlugin)
  .component('CircleStencil', CircleStencil)
  .component('Cropper', Cropper)
  .use(vue3GoogleLogin, {clientId: '817602551501-rcc04thvhil4d8pktk1it1818pdtoehr.apps.googleusercontent.com'})
  .mount('#app')
