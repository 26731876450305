<template>
  <dialog class="popup" id="fav-add">
    <div class="popup-box" style="max-width: 464px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Add user to blocked</div>
      <form class="form">
        <div class="popup-content">
          <fieldset>
            <label>You can leave a comment</label>
            <textarea class="textfield autosize" rows="1" v-model="comment"></textarea>
          </fieldset>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <div class="button" @click="Save">Save</div>
          </div>
        </div>
      </form>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PAddBlock",
  data: function () {
    return {
      is_open: false,
      comment: '',
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      popup_additional_data: state => state.Popups.popup_additional_data,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetChatData', 'SetReloadFlag']),
    ...mapActions(['PostCall',]),

    ClearDescription() {
      this.comment = ''
    },

    Save() {

      let form = {
        comment: this.comment,
        name: this.popup_additional_data.user_name,
        user_id: this.popup_additional_data.user_id,
      }

      this.PostCall({
        endpoint: '/users/blacklist',
        options: form,
        callback: (data) => {
          if (this.popup_additional_data.callback) {
            if (data && data.id) {
              this.popup_additional_data.callback.apply(null, [data.id]);
            } else {
              this.popup_additional_data.callback.apply(null);
            }
          }
          if (this.popup_additional_data.blocked) {
            this.SetReloadFlag(true);
          }
          this.hide();
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      });
    },


    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>
