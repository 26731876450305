<template>
  <dialog class="popup sliding-door" id="chat-add">
    <div class="popup-box">
      <div class="sliding-door-top">
        <div class="chat-add-header"><span>Add your private photos</span> – you can choose 5 photos more</div>
        <form class="chat-add-search">
          <input type="text" class="textfield" value="Weekend" placeholder="Search...">
          <button type="submit"></button>
        </form>
        <button type="button" class="popup-close" @click="hide"></button>
      </div>
      <perfect-scrollbar :watchOptions="true"
                         :options="scroll_options"
                         class="DDHolder">
        <div class="sliding-door-main">

          <div class="chat-add-result" :class="show_selected?'':'chat-add-result-small'">
            <div class="chat-add-grid">
              <template v-for="(value, index) in photos" v-bind:key="index">
                <div class="chat-add-item" v-if="value.selected">
                  <div class="chat-add-item-image">
                    <picture>
                      <!--                    <source srcset="pic/chat-add.webp 1x, pic/chat-add@2x.webp 2x" type="image/webp">-->
                      <!--                    <source srcset="pic/chat-add.jpg 1x, pic/chat-add@2x.jpg 2x" type="image/jpeg">-->
                      <img :src="static_server + '/'  + value.preview" alt="">
                    </picture>
                    <label>
                      <input type="checkbox" v-model="value.selected">
                      <span></span>
                    </label>
                  </div>
                  <div class="chat-add-item-price">
                    <span>Price</span>
                    <div class="count">
                      <button type="button" class="count-minus" @click="MinusPrice(value)"></button>
                      <input type="text" class="textfield only-numbers" v-model="value.price" maxlength="3">
                      <button type="button" class="count-plus" @click="PlusPrice(value)"></button>
                    </div>
                    <span>Coins</span>
                  </div>
                  <div class="chat-add-item-price-lite"><span>Price:</span> {{ value.price }}</div>
                  <div class="chat-add-item-title">{{ value.description }}</div>
                </div>
              </template>
            </div>
            <button type="button" v-if="!show_selected" class="button button-gray button-block chat-add-result-show" @click="ToggleShowSelected">Show
                                                                                                                                                 selected
            </button>
            <button type="button" v-if="show_selected" class="button button-block chat-add-result-hide" @click="ToggleShowSelected">Hide selected
            </button>
          </div>
          <div class="chat-add-list">
            <div class="chat-add-grid">

              <template v-for="(value, index) in photos" v-bind:key="index">
                <div class="chat-add-item">
                  <div class="chat-add-item-image">
                    <picture>
                      <!--                    <source srcset="pic/chat-add.webp 1x, pic/chat-add@2x.webp 2x" type="image/webp">-->
                      <!--                    <source srcset="pic/chat-add.jpg 1x, pic/chat-add@2x.jpg 2x" type="image/jpeg">-->
                      <img :src="static_server + '/' + value.preview" alt="">
                    </picture>
                    <label>
                      <input type="checkbox" v-model="value.selected">
                      <span></span>
                    </label>
                  </div>
                  <div class="chat-add-item-price">
                    <span>Price</span>
                    <div class="count">
                      <button type="button" class="count-minus" @click="MinusPrice(value)"></button>
                      <input type="text" class="textfield only-numbers" v-model="value.price" maxlength="3">
                      <button type="button" class="count-plus" @click="PlusPrice(value)"></button>
                    </div>
                    <span>Coins</span>
                  </div>
                  <div class="chat-add-item-price-lite"><span>Price:</span> {{ value.price }}</div>
                  <div class="chat-add-item-title">{{ value.description }}</div>
                </div>
              </template>

            </div>
          </div>

        </div>
      </perfect-scrollbar>
      <div class="sliding-door-bottom center">
        <button type="button" class="button" @click="PhotoSelected">Add {{ selected_num }} photos</button>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PChatPhotoAdd",
  data: function () {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
      show_selected: false,
      is_open: false,
      photos: [],
      /*photos: [
        { id: 0, preview: "/pic/chat-add.jpg", price: 300, description: 'Weekend in Kiev', },
      ]*/
      selected_num: 0,
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      static_server: state => state.Main.static_server,
      private_photos: state => state.User.user_data.private_photos,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;

      this.private_photos.forEach((photo) => {
        this.photos.push(photo)
      })

    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetCommunicationData']),
    ...mapActions(['PostCall', 'SocketEmit']),


    PhotoSelected() {
      let photo_selected = [];

      this.photos.forEach((photo) => {
        let parsed_price = parseInt(photo.price);
        let send_price = parsed_price < 0 ? -parsed_price : parsed_price === 0 ? 1 : parsed_price;

        if (photo.selected) {
          photo_selected.push({
            price: send_price,
            id: photo.id,
            preview: photo.preview,
            name: photo.name,
          })
        }
      })

      this.SetCommunicationData({photo_selected: photo_selected});

      this.hide();
    },

    SelectedNum() {
      let ret = 0;
      this.photos.forEach((e) => {
        if (e.selected) {
          ret++;
        }
      })
      return ret;
    },
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
    PlusPrice(e) {
      if (e.price < 999) {
        e.price++;
      }
    },
    MinusPrice(e) {
      if (e.price > 0) {
        e.price--;
      }
    },
    ToggleShowSelected() {
      this.show_selected = !this.show_selected;
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
    photos: {
      handler() {
        this.selected_num = this.SelectedNum();
      },
      deep: true
    },
  },
}
</script>

<style scoped>

</style>
