import { createStore } from 'vuex';
import Networking from './modules/Networking';
import Main from './modules/Main';
import Popups from "./modules/Popups";
import Alerts from "./modules/Alerts";
import User from "./modules/User";
import Dropdowns from "./modules/Dropdowns";
import Search from "./modules/Search";
import Communications from "./modules/Communications";
import Invites from "./modules/Invites";
import Chat from "./modules/Chat";
import Letters from "./modules/Letters";
import FakeData from "./modules/FakeData";


export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Networking,
    Main,
    Popups,
    Alerts,
    User,
    Dropdowns,
    Search,
    Communications,
    Invites,
    Chat,
    Letters,
    FakeData,
  }
})
