<template>
  <div class="chat-search">
    <div class="chat-search-box" v-if="invite">
      <input type="search" class="textfield" placeholder="Search" v-model="search">
      <div class="button"></div>
    </div>
    <div class="chat-search-box" v-else style="opacity: 0.6">
      <input type="search" class="textfield" placeholder="Search" disabled>
      <div class="button"></div>
    </div>

  </div>
  <div class="chat-list">
    <perfect-scrollbar :watchOptions="true" :options="scroll_options" class="DDHolder" @ps-scroll-y="onScroll">
      <template v-for="(value, index) in invites_list" v-bind:key="index">
        <InviteElement :communication="value"/>
      </template>
      <template v-if="!invites_list || (invites_list && invites_list.length===0)">
        <template v-for="(value, index) in 1" v-bind:key="index">
          <div class="chat-user" style="cursor: default">
            <div class="chat-user-avatar" style="aspect-ratio: 1; cursor: default"></div>
            <div class="chat-user-info">
              <div class="chat-user-name-date">
                <div class="chat-user-name" style="background: #E8E8E8; min-height: 16px"></div>
              </div>
              <div class="chat-user-links-about">
                <ul class="chat-user-links">
                  <li class="chat-user-links-photo"></li>
                  <li class="chat-user-links-video"></li>
                </ul>
                <div class="chat-user-about" style="background: #E8E8E8; min-width: 80%;"></div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import InviteElement from "./I_LeftPart/I_InviteElement.vue";
import router from "@/router";

export default {
  name: "I_LeftPart",
  components: {InviteElement},
  computed: {
    ...mapState({
      invite: state => state.Invites.invite,
      invites_list: state => state.Invites.invites_list,
      invites_pagination: state => state.Invites.invites_pagination,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
      search: '',
      search_timeout: undefined,

      pagination_size: 20,
      invites_list_local: [],
      list_elem_position: [],

      scroll_page_call: false,
    }
  },
  beforeMount() {
    this.SetInviteData({invites_list: [], q: '', filter_id: ''});
  },
  mounted() {
    this.GetInvitesList({size: 20, page: 1, is_invite: true});
  },
  methods: {
    ...mapMutations(['SetDDData', 'SetInviteData']),
    ...mapActions(['GetInvitesList']),
    OpenChatFilter() {
      this.SetDDData({
        dd_name: 'chat_filter',
        position_target: {hover: 'chat-filter', x: 'chat-filter', y: 'chat-search'},
        dd_opened: true,
      })
    },
    Search() {
      console.log('I_LeftPart:Search');
      this.SetInviteData({q: this.search});
      this.GetInvitesList({size: 20, page: 1, is_invite: true,new_list:true});
    },

    FillPositionArray() {
      this.list_elem_position = [];

      let list_holder = document.querySelector('.chat-list');

      if (list_holder) {
        let list = list_holder.querySelectorAll('.chat-user');
        // console.log('CHT_Window: FillPositionArray: messages:',messages)

        for (let i = 0; i < list.length; i++) {
          let list_element = list[i];
          let obj = {
            offset_y: list_element.offsetTop,
          };

          this.list_elem_position.push(obj);
        }
      }
    },
    onScroll(event) {
      //let scroll_top_delta = 100;
      if (!this.scroll_page_call && this.list_elem_position && this.chats_pagination && this.chats_pagination.totalPage > 1) {
        if (event.target.scrollTop + event.target.clientHeight > event.target.scrollHeight - 750 && this.chats_pagination.page < this.chats_pagination.totalPage) {
          console.log('I_LeftPart:next');
          this.scroll_page_call = true;
          this.GetInvitesList({size: this.pagination_size, page: this.chats_pagination.page + 1, is_invite: false});
        }
      }
    },
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.search_timeout) {
            clearTimeout(this.search_timeout);
          }
          this.search_timeout = setTimeout(() => {
            this.Search();
          }, 300);
        }
      },
      deep: true
    },
    invites_list: {
      handler(newVal, oldVal) {
        if (newVal && oldVal && oldVal.length === 0) {
          if (newVal.length > 0) {
            let id = router.currentRoute._value.params.id;
            if (id) {
              let id_index = newVal.map(e => e.user_id.toString()).indexOf(id.toString());
              if (id_index > 0) {
                this.SetInviteData({invite: newVal[id_index]});
              } else {
                this.SetInviteData({invite: newVal[0]});
              }
            } else {
              this.SetInviteData({invite: newVal[0]});
            }
          }
        }
        if (this.invites_list) {
          this.invites_list_local = Array.from(this.invites_list);
          this.$nextTick(() => {
            this.FillPositionArray();
            this.scroll_page_call = false;
          });
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
