import router from "../../router";

export default {
  state: {
    communication_id: '',
    communications_list: null, //отображение в левой части чатов
    communication: null, // текущий юзер с которым переписка
    letters_data: null, // данные для верхней части и расхлопа
    letters_list: null,
    letter_price: null,

    letter: null, //текущее письмо при просмотре писем

    q: '',//поисковый запрос
    filter_id: '',//фильтр

    write_letter: false, // открыто написание письма
    write_letter_user: null, // юзер которуму пишется письмо пр иоткрытом написании письма
  },
  mutations: {
    SetLetterData(state, data) {
      console.log('Letter: SetLetterData', data);

      if (data.letter_price) {
        state.letter_price = {...data.letter_price};
      }
      if (data.communication_id) {
        state.communication_id = data.communication_id;
      }
      if (data.communications_list) {
        state.communications_list = Array.from(data.communications_list);
      }
      if (data.letters_list_delete) {
        const ll = data.letters_list_delete;
        const letter_index = state.letters_list.findIndex((e) => e.id === ll.data.letter_id);
        if (letter_index >= 0) state.letters_list.splice(letter_index, 1);
      }
      if (data.letters_list) {
        if (data.letters_list === null || data.letters_list === undefined) {
          state.letters_list = null;
        } else {
          state.letters_list = Array.from(data.letters_list);
        }
      }
      if (data.communication) {
        state.communication = {...data.communication};
      }
      if (data.letters_data) {
        state.letters_data = {...data.letters_data};
      }
      if (data.letter) {
        state.letter = {...data.letter};
      }
      if (data.q || data.q === '') {
        console.log('Chat:', data.q);
        state.q = data.q;
      }
      if (data.filter_id || data.filter_id === '') {
        state.filter_id = data.filter_id;
      }
      if (state.letters_data && data.favorites !== undefined) {
        state.letters_data.favorites = data.favorites;
      }
      if (state.letters_data && data.blocked !== undefined) {
        state.letters_data.blocked = data.blocked;
      }
      if (data.write_letter !== undefined) {
        state.write_letter = data.write_letter;
      }
      if (data.write_letter_user) {
        state.write_letter_user = {...data.write_letter_user};
      }
    },
    ResetLetterData(state) {
      state.communication_id = '';
      state.communications_list = null;
      state.communication = null;
      state.letters_data = null;
      state.letter = null;
    },
    UpdateLettersList(state, data) {
      if (state.communications_list && state.communications_list.length > 0) {
        const index = state.communications_list.findIndex(e => e.id === data.data.id);
        let temp_list = Array.from(state.communications_list);

        if (index >= 0) {
          state.communications_list[index] = {...state.communications_list[index], ...data.data};

          let temp_element = state.communications_list[index];
          temp_list.splice(index, 1);
          temp_list.unshift(temp_element);
          state.communications_list = Array.from(temp_list);
        }
      }
    },
    UpdateLetterData(state, data) {
      console.log('Letter: UpdateLetterData', data);
      if (!state.letters_list) {
        state.letters_list = [];
      }

      let index = state.letters_list.findIndex(e => e.id === data.data.id);

      if (index === -1) {
        state.letters_list.unshift(data.data);
      } else {
        state.letters_list[index] = {...state.letters_list[index], ...data.data};
      }
      state.letter = {...state.letter, ...data.data};
    },
  },
  actions: {
    ///chat/list?filterid=SelectedFilterID&q=SearchText
    GetLettersList({dispatch, state}) {
      let endpoint = '/letters';

      let call_options = {};
      if (state.q && state.q !== '') {
        call_options.q = state.q;
      }
      if (state.filter_id && state.filter_id !== '') {
        call_options.filter_id = state.filter_id;
      }

      dispatch('GetCall', {
        endpoint: endpoint,
        options: call_options,
        callback: (data) => {
          state.communications_list = Array.from(data.rows);

          if (data.rows.length > 0) {
            let id = router.currentRoute._value.params.id;
            if (id) {
              let id_index = data.rows.map(e => e.user_id.toString()).indexOf(id.toString());

              if (id_index > 0) {
                state.communication = data.rows[id_index];
              } else {
                state.communication = data.rows[0];
              }
            } else {
              state.communication = data.rows[0];
            }
          }
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('Letters:', error_status);
        },
      })
    },
  },
  getters: {}
}
