<template>
  <div class="container">
    <h1>FAQ</h1>
    <template v-for="(value, index) in faq" v-bind:key="index">
      <p>
        <b>{{ value.title }}</b>
        <i>{{ value.text }}</i>
      </p>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "FAQ",
  data() {
    return {
      faq: [],
    };
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
    }),
  },
  mounted() {
    this.LoadJson();
  },
  methods: {
    async LoadJson() {
      let fetchOpt = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        referrerPolicy: "strict-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }

      try {
        const response = await fetch(this.static_server + '/faq.json', fetchOpt);
        if (response.ok) {
          let response_parse = await response.json();
          console.log('Networking:response', response_parse);
          this.faq = Array.from(response_parse);
        } else {
          console.log('Networking:response:catch', response);
        }
      } catch (error) {
        console.log('Networking:response', error);
      }
    },
  }
}
</script>

<style>
</style>
