<template>
  <div class="chat-user"
       :class="communication.user_id === local_communication.user_id ? 'is-active':''"
       @click="UserClicked">
    <template v-if="letters_list && user_data">
      <template v-if="user_data.is_seller">
        <div class="chat-user-chain" :class="letters_list[0].direction_to_seller ? 'chat-user-chain-incoming':'chat-user-chain-outcoming'"></div>
      </template>
      <template v-else>
        <div class="chat-user-chain" :class="letters_list[0].direction_to_seller ? 'chat-user-chain-outcoming':'chat-user-chain-incoming'"></div>
      </template>
    </template>
    <div class="chat-user-avatar" :class="local_communication.online ? 'is-online':''" @click="GoToUserView">
      <picture v-if="local_communication && local_communication.user_photo_url">
        <!--              <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
        <!--              <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
        <img :src="static_server+'/'+local_communication.user_photo_url" alt="">
      </picture>
    </div>
    <div class="chat-user-info">
      <div class="chat-user-name-date">
        <div class="chat-user-name">{{ local_communication.user_name }}</div>
        <time class="chat-user-date">
          {{ new Date(local_communication.updated_at).getDate() }}<sup>{{nth(new Date(local_communication.updated_at).getDate())}}</sup> {{ month[new Date(local_communication.updated_at).getMonth()] }}
        </time>
      </div>
      <div class="chat-user-links-about">
        <ul class="chat-user-links"
            v-if="(local_communication.message.photos && local_communication.message.photos.length>0) ||
                (local_communication.message.videos && local_communication.message.videos.length>0)">
          <li class="chat-user-links-photo" v-if="local_communication.message.photos && local_communication.message.photos.length>0"></li>
          <li class="chat-user-links-video" v-if="local_communication.message.videos && local_communication.message.videos.length>0"></li>
        </ul>
        <div class="chat-user-about">{{ local_communication.subject }}</div>
        <div class="chat-user-chain-dummy">{{ local_communication.count_unread }}</div>
        <div class="chat-user-chain-toggle"
             title="Number of unread emails"
             :class="local_communication.opened?'is-opened':''"
             @click.stop="LettersDDClicked">{{ local_communication.count_unread }}</div>
      </div>
    </div>
  </div>
  <perfect-scrollbar class="letters-holder"
                     :class="local_communication.opened ? 'is-opened':''"
                     :watchOptions="true" :options="scroll_options">
    <div>
      <template v-for="(value2, index2) in letters_list" v-bind:key="index2">
        <template v-if="index2 > 0">
          <div class="chat-user chat-user-line"
               :class="[
                   letter.id === value2.id ? 'is-selected':'',
                   (value2.unread && user_data && user_data.is_seller && value2.direction_to_seller) ? 'unreaded':'',
                   (value2.unread && user_data && !user_data.is_seller && !value2.direction_to_seller) ? 'unreaded':'',
                   ]"
               @click="LetterClicked(value2.id)">

            <template v-if="user_data">
              <template v-if="user_data.is_seller">
                <div class="chat-user-chain" :class="value2.direction_to_seller ? 'chat-user-chain-incoming':'chat-user-chain-outcoming'"></div>
              </template>
              <template v-else>
                <div class="chat-user-chain" :class="value2.direction_to_seller ? 'chat-user-chain-outcoming':'chat-user-chain-incoming'"></div>
              </template>
            </template>

            <div class="chat-user-info">
              <div class="chat-user-name-date">
                <time class="chat-user-date">{{ ('0' + new Date(value2._created_at).getHours()).slice(-2) }}:{{ ('0' + new Date(value2._created_at).getMinutes()).slice(-2) }}</time>
              </div>
              <div class="chat-user-links-about" v-if="value2.subject">
                <ul class="chat-user-links"
                    v-if="(value2.content.photos && value2.content.photos.length>0) || (value2.content.videos && value2.content.videos.length>0)">
                  <li class="chat-user-links-photo" v-if="value2.content.photos && value2.content.photos.length>0"></li>
                  <li class="chat-user-links-video" v-if="value2.content.videos && value2.content.videos.length>0"></li>
                </ul>
                <div class="chat-user-about">{{ value2.subject }}</div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </perfect-scrollbar>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "L_LetterElement",
  props: {
    local_communication: Object,
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      month: state => state.Main.month,

      user_data: state => state.User.user_data,
      letter: state => state.Letters.letter,
      letters_list: state => state.Letters.letters_list,
      communication_id: state => state.Letters.communication_id,
      communications_list: state => state.Letters.communications_list,
      communication: state => state.Letters.communication,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
      letters: null,
      local_call: false,
    }
  },
  mounted() {
    if (this.local_communication.user_id === this.communication.user_id) {
      setTimeout(() => {
        this.GetLetters();
      }, 150);
    }
  },
  methods: {
    ...mapMutations(['SetLetterData', 'SetDDData']),
    ...mapActions(['GetCall', 'SocketEmit']),
    nth(n){return n>3&&n<21?"th":n%10==2?"nd":n%10==2?"nd":n%10==3?"rd":"th"},
    UserClicked() {//get_letters_flag

      if (this.local_communication.user_id !== this.communication_id) {
        this.local_call = true;
        this.SetLetterData({
          communication_id: this.local_communication.user_id,
          communication: this.local_communication,
        });
        this.$nextTick(() => {
          this.local_call = false;
        });

        this.GetLetters();
      } else {
        this.GetLetters();
      }
    },
    GoToUserView(e){
      e.stopPropagation();
      this.$router.push({path: `/user-view`,query: {id:this.local_communication.user_id}});
    },

    GetLetters() {
      // let query = router.currentRoute._value.query;
      // this.$router.replace({ query: { ...query, ...{ id: this.local_communication.user_id } } });

      this.$router.push({path: `/letters/${this.local_communication.user_id}`})

      this.GetCall({
        endpoint: `/letters/${this.local_communication.id}/`,
        callback: (data) => {

          this.SetLetterData({
            letters_list: data.messages,
            letters_data: data,
          });

          if (data.messages.length > 0) {
            this.SetLetterData({
              letter: data.messages[0],
            });

            const to_seller = data.messages[0].direction_to_seller;

            if (
                this.user_data && data.messages[0].unread &&
                ((to_seller && this.user_data.is_seller) || (!to_seller && !this.user_data.is_seller))
            ) {
              // this.SendReadStatus(data.messages[0].id);
            }

            let tempUserList = Array.from(this.communications_list);
            let user_index = tempUserList.map(e => {
              return e.user_id
            }).indexOf(this.local_communication.user_id);
            tempUserList[user_index].opened = true;
            this.SetLetterData({
              communications_list: tempUserList,
            });

          }

        },
      })
    },
    /*GetLetter(letter_id) {
      this.GetCall({
        endpoint: `/letters/${this.local_communication.id}/letter/${letter_id}/`,
        options: {},
        callback: (data) => {
          this.SetChatData({
            letter: data,
          });
        },
      })
    },*/

    LetterClicked(letter_id) {
      let letter_index = this.letters_list.map(e => e.id).indexOf(letter_id);

      this.letters_list.forEach(e => {
        e.active = false
      });
      this.letters_list[letter_index].active = true;


      this.SetLetterData({
        letter: this.letters_list[letter_index],
      });

      let temp_user = {...this.local_communication};
      let temp_list = Array.from(this.communications_list);
      // let temp_list_user = temp_list.find(e => e.user_id);

      const to_seller = this.letters_list[letter_index].direction_to_seller;

      if (
          this.user_data &&
          this.letters_list[letter_index].unread &&
          ((to_seller && this.user_data.is_seller) || (!to_seller && !this.user_data.is_seller))
      ) {
        // this.SendReadStatus(this.letters_list[letter_index].id);
        this.letters_list[letter_index].unread = false;
      }
      //this.GetLetter(letter_id);
      this.local_call = true;

      this.SetLetterData({
        communication_id: this.local_communication.user_id,
        communication: temp_user,
        communications_list: temp_list,
      });
      this.$nextTick(() => {
        this.local_call = false;
      });
    },

    LettersDDClicked() {
      // let opened = !!this.local_communication.opened;

      let tempUserList = Array.from(this.communications_list);
      // let user_index = tempUserList.map(e => {
      //   return e.user_id
      // }).indexOf(this.local_communication.user_id);
      tempUserList.forEach(e => {
        e.opened = false
      });
      // tempUserList[user_index].opened = !opened;

      this.SetLetterData({
        communications_list: tempUserList,
      });

      this.SetLetterData({
        letters_list: null,
      });

      this.GetLetters();
    },

    // SendReadStatus(id) {
    //   let send = {
    //     type: 'letter_readed',
    //     data: [id],
    //     callback: () => {
    //     }
    //   }
    //   // console.log(send);
    //   this.SocketEmit(send);
    // },

  },
  watch: {
    /*communication: {
      handler() {
        if ( this.communication && this.communication.user_id === this.local_communication.user_id && !this.local_call ) {
          this.GetLetters();
        }
      },
      deep: true
    },*/
  }

}
</script>

<style scoped>

</style>
