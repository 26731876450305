<template>
  <section class="box">
    <div class="box-header">
      <div class="box-header-item">
        <h3>My interests</h3>
      </div>
    </div>
    <div class="box-content">
      <ul class="interests">
        <template v-for="(value, index) in interests" v-bind:key="index">
          <template v-if="interest.map(e => { return e.id; }).indexOf(value.id) > -1">
            <template v-for="(value2, index2) in interest" v-bind:key="index2">
              <UP_Interests_Elem v-if="value.id === value2.id" :data="value" :selected="true"/>
            </template>
          </template>
          <UP_Interests_Elem v-else :data="value" :selected="false"/>
        </template>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UP_Interests_Elem from "@/components/01UserProfile/Parts/UP_Interests/UP_Interests_Elem";

export default {
  name: "UP_Interests",
  components:{
    UP_Interests_Elem
  },
  data: function () {
    return {
      // interests:[],
      // interest0:[
      //   item_option_id:''
      // ]

    }
  },
  computed: {
    ...mapState({
      interest: state => state.User.user_data.interests,
      interests: state => state.User.interests,
    }),
  },
  mounted() {
    if(this.user_data) {
      if (this.user_data.interest !== null) {
        this.server_update = true;
        // this.text = this.user_data.interest;
        setTimeout(()=>{this.server_update = false},100);
      }
    }
  },
  methods:{
    ...mapActions(['PutCall']),

    Save(e){
      e.preventDefault();

      let form = {
        key: 'interest',
        value: this.interest
      }

      this.PutCall({
        endpoint: '/api/profile/update_profile',
        options: form,
        callback: (data) => {
          console.log(data);
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    }
  },
  watch:{
    user_data: {
      handler(val) {
        if(val) {
          if (val.about_user !== null) {
            this.server_update = true;
            this.text = val.about_user;
            setTimeout(()=>{this.server_update = false},100);
          }
        }
      },
      deep: true
    },
  },

}
</script>

<style scoped>

</style>