<template>
  <dialog class="popup media-gallery" id="media-photo">
    <button type="button" class="popup-close" @click="hide"></button>
    <div class="media-gallery-box">
      <div class="media-gallery-object" :class="private_flag  ? 'media-gallery-locked':''">
        <picture>
          <!--          <source srcset="pic/media-gallery-photo.webp 1x, pic/media-gallery-photo@2x.webp 2x" type="image/webp">-->
          <!--          <source srcset="pic/media-gallery-photo.jpg 1x, pic/media-gallery-photo@2x.jpg 2x" type="image/jpeg">-->
          <img :src="static_server + '/'+photo_view_data[index].file" alt="">
        </picture>
        <div class="media-gallery-lock" v-if="private_flag">
          <a href="#" class="button">
            <span>View private photo – <strong>5 coins</strong></span>
          </a>
        </div>
        <template v-if="photo_view_data && photo_view_data.length > 1">
          <button type="button" class="media-gallery-prev" @click="ShowPrev"></button>
          <button type="button" class="media-gallery-next" @click="ShowNext"></button>
        </template>
      </div>
      <div class="media-gallery-about">
        <div class="media-gallery-caption">{{ photo_view_data[index].description }}</div>
        <div class="media-gallery-count">{{ index + 1 }} of {{ photo_view_data.length }}</div>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PViewPhotos",
  data: function () {
    return {
      is_open: false,
      description: '',
      file: null,
      index: 0,
    }
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      is_auth: state => state.User.is_auth,
      photo_view_data: state => state.Popups.photo_view_data,
      photo_view_index: state => state.Popups.photo_view_index,
      private_flag: state => state.Popups.private_flag,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
      this.index = this.photo_view_index;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData',]),
    ...mapActions(['PostCall',]),

    ShowPrev() {
      console.log(this.photo_view_data.length - 1);
      if (this.index === 0) {
        this.index = this.photo_view_data.length - 1;
      } else if (this.index > 0) {
        this.index--;
      }
    },
    ShowNext() {
      if (this.index === this.photo_view_data.length - 1) {
        this.index = 0;
      } else if (this.index < this.photo_view_data.length) {
        this.index++;
      }
    },
    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },

}
</script>

<style scoped>

</style>