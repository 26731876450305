<template>
  <main>
    <div class="container"
         v-if="
         user_data !== null &&
         about_me_dd_data !== null &&
         // my_lifestyle_dd_data !== null &&
         languages !== null &&
         interests !== null">
      <user-info/>
      <few-words/>
      <gifts v-if="user_data.is_seller"/>
      <photos/>
      <private-photos v-if="user_data.is_seller"/>
      <videos/>
      <private-videos v-if="user_data.is_seller"/>
      <interests/>
      <about-me/>
      <!--      <my-lifestyle/>-->
      <looking-for/>
    </div>
  </main>
  <button type="button" class="up" @click="ScrollTop()" v-if="!is_top"></button>
</template>

<script>
import UserInfo from "@/components/01UserProfile/Parts/UP_UserInfo";
import FewWords from "@/components/01UserProfile/Parts/UP_FewWords";
import Gifts from "@/components/01UserProfile/Parts/UP_Gifts";
import Photos from "@/components/01UserProfile/Parts/UP_Photos";

import PrivatePhotos from "@/components/01UserProfile/Parts/UP_PrivatePhotos";
import Videos from "@/components/01UserProfile/Parts/UP_Videos";
import PrivateVideos from "@/components/01UserProfile/Parts/UP_PrivateVideos";
import Interests from "@/components/01UserProfile/Parts/UP_Interests";
import AboutMe from "@/components/01UserProfile/Parts/UP_AboutMe";
// import MyLifestyle from "@/components/01UserProfile/Parts/UP_MyLifestyle";
import LookingFor from "@/components/01UserProfile/Parts/UP_LookingFor";

import {mapState, mapActions} from "vuex";

export default {
  name: "UserProfile",
  components: {
    UserInfo,
    FewWords, Gifts, Photos, PrivatePhotos, Videos, PrivateVideos, Interests, AboutMe,
    // MyLifestyle,
    LookingFor
  },
  data: function () {
    return {
      //user_info:{},
      //languages: [],
      is_top: true,
    }
  },
  computed: {
    ...mapState({
      debug: state => state.Main.debug,
      user_data: state => state.User.user_data,
      about_me_dd_data: state => state.User.about_me_dd_data,
      my_lifestyle_dd_data: state => state.User.my_lifestyle_dd_data,
      languages: state => state.User.languages,
      interests: state => state.User.interests,
    }),
  },
  mounted() {
    this.GetScopeUpdate();
    document.addEventListener('scroll', this.OnScroll)
  },
  beforeUnmount() {
    document.removeEventListener('scroll',this.OnScroll);
  },
  methods: {
    ...mapActions(['GetScopeUpdate']),
    ScrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    OnScroll(){
      // console.log(window.scrollY);
      if (window.scrollY > 0) {
        this.is_top = false;
      }else{
        this.is_top = true;
      }
    }
  }
}
</script>

<style scoped>

</style>
