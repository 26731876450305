<template>
  <div class="chat-start" v-if="communication">
    <div class="chat-start-box">
      <button type="button" class="button button-chat" @click="StartChat">Start chat</button>
    </div>
    <div class="chat-start-box">
      <button type="button" class="button button-mail" @click="WriteReply">Reply</button>
    </div>
  </div>
  <div class="chat-start" v-else>
    <div class="chat-start-box">
      <button type="button" class="button button-chat" style="opacity: .5;cursor: default">Start chat</button>
    </div>
    <div class="chat-start-box">
      <button type="button" class="button button-mail" style="opacity: .5;cursor: default">Reply</button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "L_SendField",
  props: {},
  computed: {
    ...mapState({
      user_data: state => state.User.user_data,
      communication: state => state.Letters.communication,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetLetterData', 'ResetChatData']),
    ...mapActions(['SocketEmit', 'PostCall']),
    // created() {
    //   window.addEventListener("keydown", this.myEventHandler);
    // },
    // unmounted() {
    //   window.removeEventListener("keydown", this.myEventHandler);
    // },

    StartChat() {
      this.PostCall({
        endpoint: '/chats/',
        options: {
          user_id: this.communication.user_id
        },
        callback: () => {
          this.$router.push({path: `/chat/${this.communication.user_id}`});
        },
      })
    },
    WriteReply() {
      // this.$router.push({ path: `/letters` });
      this.SetLetterData({write_letter: true, write_letter_user: {...this.communication, ...{id: this.communication.user_id}}});
    },
  },
}
</script>

<style scoped>

</style>
