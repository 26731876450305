<template>
  <Header v-if="show_header"/>
  <router-view/>
  <Footer/>
  <Overlay/>
  <Popups v-if="popup_opened"/>
  <Dropdowns v-if="dd_opened"></Dropdowns>
  <!--  <Version/>-->
  <Alerts/>
  <Loader v-if="!hide_loader"/>
</template>

<script>
// import Version from "./components/Version";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Popups from "./components/Popups/Popups";
import Overlay from "./components/Popups/Overlay";
import {mapActions, mapMutations, mapState} from "vuex";
import Dropdowns from "./components/Dropdowns/Dropdowns";
import Alerts from "@/components/Alerts/Alerts.vue";
import Loader from "@/components/Misc/Loader.vue";

export default {
  name: 'App',
  components: {
    Loader,
    Alerts,
    Dropdowns,
    // Version,
    Header,
    Footer,
    Popups,
    Overlay,
  },
  data: () => {
    return {
      show_header: false,
    }
  },
  computed: {
    ...mapState({
      force_reload: state => state.User.force_reload,
      hide_loader: state => state.Main.hide_loader,
      route_server: state => state.Main.route_server,
      api_server: state => state.Main.api_server,
      draw_scope_loaded: state => state.User.draw_scope_loaded,
      is_auth: state => state.User.is_auth,
      selected_user: state => state.User.selected_user,
      popup_opened: state => state.Popups.popup_opened,
      dd_opened: state => state.Dropdowns.dd_opened,
    }),
  },
  mounted() {
    this.InitApp();
    // if (!this.api_server) {
    //   this.GetApiServer({callback: this.InitApp});
    // } else {
    //   this.InitApp();
    // }
  },
  methods: {
    ...mapMutations(['SetUserData', 'SetDDData', 'SetLetterData', 'ClearQueryArray', 'FillQueryArray', 'SetMainData']),
    ...mapActions(['GetScopeUpdate',"SetUrlQuery", "GetCall", 'GetApiServer']),
    /*
        SetApiTimeout() {
          if (this.get_api_server_timeout) {
            clearTimeout(this.get_api_server_timeout);
          }
          this.get_api_server_timeout = setTimeout(() => {
            this.GetApiServer()
          }, 10000);
        },
        GetApiServer() {
          console.log('route server call:');
          let headers = {'CurrentUser': 'default'};
          axios
              .get(this.route_server, {withCredentials: true, headers: headers})
              // .get(path)
              .then((response) => {
                console.log('route server response:', response);
                if (response && response.data && response.data.result) {
                  this.SetMainData({api_server: response.data.result});
                  this.$nextTick(() => {
                    this.InitApp();
                    if (!this.draw_scope_loaded) {
                      this.GetScopeUpdate();
                    }
                  });
                } else {
                  this.SetApiTimeout();
                }
              })
              .catch((error) => {
                console.log('route server response: error:', error);
                this.SetApiTimeout();
              });
        },
    */
    InitApp() {
      const _query_params = this.GetQueryParams(window.location.href);
      const _query_params_array = Object.keys(_query_params).map(e => {
        return {key: e, val: _query_params[e]}
      });

      if(_query_params.a) {
        let a_index = _query_params_array.find(e=>e.key==='a');
        _query_params_array.splice(a_index,1);
        this.SetMainData({referral_id:_query_params.a});
      }

      console.log('_query_params_array', _query_params_array)
      this.FillQueryArray(_query_params_array);
      // _query_params_array.forEach(e => {
      //   this.SetUrlQuery({key: e, val: _query_params[e]});
      // })

      const _selected_user = _query_params.mid;


      if (_selected_user) {
        this.SetUserData({selected_user: _selected_user});
      }

      // const l_storage = window.localStorage;
      //
      // const token = l_storage.getItem('token');
      //
      // if (token !== null && token !== undefined) {
      //   this.SetUserData({is_auth: true});
      //   /*if ( !this.draw_scope_loaded ) {
      //     this.GetScopeUpdate();
      //   }*/
      // } else {
      //   this.DeleteCredentials();
      // }
    },
    GetQueryParams(url) {
      const params = {};
      if (url.indexOf('?') > 0) {
        const paramArr = url.slice(url.indexOf('?') + 1).split('&');

        paramArr.map(param => {
          const [key, val] = param.split('=');
          params[key] = decodeURIComponent(val);
        })
      }
      console.log('App:GetQueryParams:params', params);
      return params;
    },
  },
  watch: {
    force_reload(newVal) {
      if (newVal) {
        document.location.reload();
      }
    },
    popup_opened(newVal) {
      let body = document.body;
      if (newVal) {
        body.classList.add("is-locked");
      } else {
        body.classList.remove("is-locked");
      }
    },
    '$route'(to, from) {
      console.log('Route changed from ' + from.path + ' to ' + to.path);

      this.SetDDData({dd_opened: false,})

      if (to.path === '/') {
        this.show_header = false;
        document.body.classList.add('intro-page');
        document.body.classList.remove('fullscreen-page');
      } else {
        this.show_header = true;
        document.body.classList.remove('intro-page');

        // if (!this.draw_scope_loaded && this.api_server && this.is_auth) {
        //   this.GetScopeUpdate();
        // }
        // if ( to.path !== '/user-profile' && to.path !== '/user-view' ) {
        // }
        if (to.path.includes('/invites') || to.path.includes('/chat') || to.path.includes('/letters')) {
          document.body.classList.add('fullscreen-page')
        } else {
          document.body.classList.remove('fullscreen-page');
        }
      }
      document.body.classList.remove("is-locked");

      let query = {};

      if (to.path !== '/') {
        // console.log('Route from:', from, ' to query:', to);

        // this.ClearQueryArray();

        if (this.selected_user) {
          if (from.query.mid && !to.query.mid) {
            query = {...to.query, mid: from.query.mid}
          } else {
            query = {...to.query}
          }
          this.$router.push({query: query});
        }
      } else {
        // this.ClearQueryArray();
      }
      if (from.path.includes('/letters')) {
        // console.log('Route from:', from, ' to query:', to);
        this.SetLetterData({write_letter: false})
      }

    }
  }
}

</script>

<style lang="less">

</style>
