<template>
  <main>
    <CommunicationTabs/>
    <div class="container">
      <template v-if="!write_letter">
        <section class="chat chat-letter">
          <L_LeftPart/>
          <L_Top/>
          <L_Window/>
          <L_SendField/>
        </section>
      </template>
      <L_WriteLetter v-else/>
    </div>
  </main>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import L_WriteLetter from "./Sub/L_WriteLetter";
import L_LeftPart from "./Parts/L_LeftPart";
import L_Top from "./Parts/L_Top";
import L_Window from "./Parts/L_Window";
import L_SendField from "./Parts/L_SendField";
import CommunicationTabs from "../Misc/CommunicationTabs";

export default {
  name: "Letters",
  components: {CommunicationTabs, L_WriteLetter, L_SendField, L_Window, L_Top, L_LeftPart},
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      communications_list: state => state.Letters.communications_list,
      write_letter: state => state.Letters.write_letter,
    }),
  },
  mounted() {
    this.SetCommunicationData({ communication_type: 2 });
  },
  methods: {
    ...mapMutations(['SetCommunicationData', 'SetLetterData']),
  },
  watch: {
    id: {
      handler(newVal) {
        let elem = this.communications_list.find(item => item.user_id.toString() === newVal.toString());
        if (elem) {
          this.SetLetterData({communication: elem, communication_id: newVal});
        } else {
          this.SetLetterData({communication_id: newVal});
        }
      },
      deep: false
    },
  },
}
</script>

<style scoped>

</style>
