export default {
	state: {
		filterMood: [
			{ id: 0, name: 'Serious', ico: '/img/mood-1.svg' },
			{ id: 1, name: 'Pen pal', ico: '/img/mood-2.svg' },
			{ id: 2, name: 'Romantic', ico: '/img/mood-3.svg' },
			{ id: 3, name: 'Flirtry', ico: '/img/mood-4.svg' },
			{ id: 4, name: 'Naughty', ico: '/img/mood-5.svg' },
		],
		filterCategories: [
			{ id: 0, groupName: 'Interests', list: [{ id: 0, name: 'int_0' }, { id: 1, name: 'int_1' }, { id: 2, name: 'int_2' },] },
			{ id: 1, groupName: 'Education', list: [{ id: 0, name: 'ed_0' }, { id: 1, name: 'ed_1' }, { id: 2, name: 'ed_2' },] },
			{ id: 2, groupName: 'Languages', list: [{ id: 0, name: 'lang_0' }, { id: 1, name: 'lang_1' }, { id: 2, name: 'lang_2' },] },
			{ id: 3, groupName: 'Smoking', list: [{ id: 0, name: 'Dont smoke' }, { id: 1, name: 'Regularty smoking' },] },
		],
		filterInterests: [
			{
				id: 0, name: 'Dancing',
				ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
			},
			{
				id: 1, name: 'Dancing',
				ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
			},
			{
				id: 2, name: 'Dancing',
				ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
			},
			{
				id: 3, name: 'Dancing',
				ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
			},
			{
				id: 4, name: 'Dancing',
				ico: '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/><path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/></svg>'
			},
		],
	},
	mutations: {},
	actions: {},
	getters: {}
}