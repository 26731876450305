<template>
  <div class="chat-subject" v-if="letter">
    <div class="chat-subject-text">{{ letter.subject }}</div>
    <time class="chat-subject-date">
      {{ new Date(letter._created_at).getDate() }} {{ month[new Date(letter._created_at).getMonth()] }} {{ new Date(letter._created_at).getFullYear() }}
      at {{ ('0' + new Date(letter._created_at).getHours()).slice(-2) }}:{{ ('0' + new Date(letter._created_at).getMinutes()).slice(-2) }}
    </time>
  </div>
  <div class="chat-window">
    <img src="/img/logo.svg" alt="">
    <!--    <div class="chat-window-date" v-if="last_date.year  communication_type===1 || communication_type===0">-->
    <!--      <time class="chat-window-date-box">12<span>th</span> Aug</time>-->
    <!--    </div>-->
    <div class="chat-window-box">
      <perfect-scrollbar
          :watchOptions="true"
          :options="scroll_options"
          class="DDHolder">
        <div v-if="letter" class="chat-window-main">
          <div class="chat-letter-box">
            <div class="chat-letter-main">
              <p v-if="letter.content">{{ letter.content.text }}</p>
            </div>
            <div class="chat-letter-actions">
<!--              <button type="button" class="chat-letter-translate"></button>-->
              <!--              <button type="button" class="chat-letter-warning"></button>-->
            </div>
          </div>
          <template v-if="letter.content">
            <template v-if="letter.content.photos">
              <div class="chat-letter-box" v-if="letter.content.photos.length > 0">
                <div class="chat-letter-row">
                  <template v-for="(value,index) in letter.content.photos" :key="index">
                    <div class="chat-letter-row-col">
                      <div class="chat-media">
                        <div class="chat-media-box">

                          <template v-if="letter.self">
                            <picture class="chat-media-clickable-photo" @click="ViewPhoto(value)">
                              <img :src="static_server + '/' + value.photo" alt="">
                            </picture>
                            <button type="button" class="chat-media-remove"></button>
                          </template>

                          <div class="chat-media-buy" v-else-if="!value.file">
                            <picture>
                              <img :src="static_server + '/' + value.photo" alt="">
                            </picture>
                            <div class="button" @click="BuyPhotoLetter(value.id)">Buy the photo</div>
                          </div>

                          <template v-else>
                            <picture class="chat-media-clickable-photo" @click="ViewPhoto(value)">
                              <img :src="static_server + '/' + value.photo" alt="">
                            </picture>
                            <!--                        <button type="button" class="chat-media-remove"></button>-->
                          </template>
                        </div>
                        <div class="chat-media-text"><strong>{{ value.price }} coins</strong>{{ value.name }}</div>
                      </div>
                    </div>
                  </template>
                  <!--              <template v-for="(value,index) in letter.video" :key="index">-->
                  <!--                <div class="chat-letter-row-col">-->
                  <!--                  <div class="chat-media">-->
                  <!--                    <div class="chat-media-box">-->
                  <!--                      <div class="chat-media-buy">-->
                  <!--                        <picture>-->
                  <!--                          &lt;!&ndash;                          <source srcset="pic/avatar-female-big.webp 1x, pic/avatar-female-big@2x.webp 2x" type="image/webp">&ndash;&gt;-->
                  <!--                          &lt;!&ndash;                          <source srcset="pic/avatar-female-big.jpg 1x, pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">&ndash;&gt;-->
                  <!--                          <img :src="value.preview_link" alt="">-->
                  <!--                        </picture>-->
                  <!--                        <div class="chat-media-video"></div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                    <div class="chat-media-text"><strong>{{ value.price }} coins</strong>{{ value.name }}</div>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                  <!--              </template>-->
                </div>
              </div>
            </template>
          </template>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "L_Window",
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      communication_type: state => state.Communications.communication_type,
      letters_data: state => state.Letters.letters_data,
      communication: state => state.Letters.communication,
      letter: state => state.Letters.letter,
    }),
  },
  props: {
    //messages: Array,
  },
  data: () => {
    return {
      // test_count: 0,
      letter2: {
        id: 10,
        reply_to_id: 9,
        date: '5 Mar 2021 at 10:42',
        subj: 'This is what the subject look like. This is what the subject look like.',
        text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed, It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposedIt is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed',
        photo: [
          { id: 0, preview_link: '', price: 100500, name: 'photo 1' },
          { id: 1, preview_link: '', price: 100500, name: 'photo 2' },
        ],
        video: [
          { id: 0, preview_link: '', price: 100500, name: 'video 1' },
          { id: 1, preview_link: '', price: 100500, name: 'video 2' },
        ],
      },
      //1 - text , 2 - photo
      scroll_options: {
        suppressScrollX: true,
      },
      scrolled_to: false,
      month: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      last_date: {
        day: 0,
        month: 0,
        year: 0,
      }
    }
  },
  mounted() {
    console.log('CHT_Window: mounted');
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetLetterData']),
    ...mapActions(['SocketEmit', 'PutCall']),
    data_ms(date) {
      // this.test_count++;
      // console.log('CHT_Window date:', Date.UTC(date.year, date.month, date.day), this.test_count);
      return Date.UTC(date.year, date.month, date.day);
    },

    BuyPhotoLetter(photo_id) {
      console.log('L_Window:BuyPhotoLetter:', photo_id);

      this.PutCall({
        endpoint: `/letters/${this.communication.id}/letter/${this.letter.id}`,
        options: {
          photo_id: photo_id,
          status: 'buy'
        },
        callback: (response) => {
          this.SetLetterData({
            letter: response,
          })
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('L_Window:BuyPhotoLetter:error:', error_status);
        },
      })
    },

    ViewPhoto(photo) {
      let photos = [photo];
      this.SetPopupData({
        photo_view_data: photos,
        photo_view_index: 0,
        private_flag: false,
        popup_name: 'view_photo',
        popup_opened: true,
      })
    },
    SendReadStatus(id) {
      let send = {
        type: 'letter_readed',
        data: [id],
        callback: () => {
        }
      }
      // console.log(send);
      this.SocketEmit(send);
    },

  },
  watch: {
    letter: {
      handler() {
        if ( this.letter && !this.letter.self && this.letter.id && this.letter.unread ) {
          this.SendReadStatus(this.letter.id);
        }
      },
      deep: true
    },
  }

}
</script>

<style scoped>

</style>
