<template>
  <div class="loader" :class="loaded_state?'hidden':''">
    Loading...
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "Loader",
  computed: {
    ...mapState({
      loaded: state => state.Main.loaded,
    }),
  },
  data: function () {
    return {
      loaded_state: false,
    }
  },
  methods: {
    ...mapMutations(['SetMainData']),
  },
  watch: {
    loaded: {
      handler(newVal) {
        if (newVal) {
          setTimeout(() => {
            this.loaded_state = true;
          }, 500);
          setTimeout(() => {
            this.SetMainData({hide_loader: true})
          }, 900);
        }
      },
      deep: false
    },
  },

}
</script>


<style scoped>

</style>
