<template>
  <div class="chat-window">
    <img src="/img/logo.svg" alt="">
    <!--    <div class="chat-window-date" v-if="last_date.year">-->
    <!--      <time class="chat-window-date-box">12<span>th</span> Aug</time>-->
    <!--    </div>-->
    <div class="chat-window-box">
      <perfect-scrollbar
          :watchOptions="true"
          :options="scroll_options"
          @ps-scroll-y="onScroll"
          class="DDHolder">
        <div v-if="invite_data" class="chat-window-main">
          <template v-for="(value, index) in invite_data.messages" v-bind:key="index">
            <div class="chat-message" :class="value.self?'chat-message-self':''">
              <div
                  class="chat-message-text"
                  :class="value.alert ?'chat-message-icon':''">
                <div class="chat-message-text-content">{{ value.content.text }}</div>
<!--                <button type="button" class="chat-message-translate"></button>-->
                <button type="button" class="chat-message-icon-warning" v-if="value.alert"></button>
              </div>

              <time class="chat-message-time">
                {{ ('0' + new Date(value._created_at).getHours()).slice(-2) }}:{{ ('0' + new Date(value._created_at).getMinutes()).slice(-2) }}
              </time>
            </div>
          </template>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "I_Window",
  computed: {
    ...mapState({
      invite_data: state => state.Invites.invite_data,
      invite: state => state.Invites.invite,
    }),
  },
  props: {
    //messages: Array,
  },
  data: () => {
    return {
      messages_position: [],
      unread_to_send: [],
      unread_to_send_timeout: undefined,
      scroll_options: {
        suppressScrollX: true,
      },
      scrolled_to: false,
      month: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      last_date: {
        day: 0,
        month: 0,
        year: 0,
      }
    }
  },
  mounted() {
    console.log('CHT_Window: mounted');
  },
  methods: {
    ...mapMutations(['SetPopupData']),
    ...mapActions(['SocketEmit', 'PutCall']),
    data_ms(date) {
      // this.test_count++;
      // console.log('CHT_Window date:', Date.UTC(date.year, date.month, date.day), this.test_count);
      return Date.UTC(date.year, date.month, date.day);
    },
    ScrollTo() {
      let finded = false;
      let finded_id = 0;
      if (this.invite_data && this.invite_data.messages) {
        for (let i = 0; i < this.invite_data.messages.length; i++) {
          let message = this.invite_data.messages[i];
          if (!finded && message.unread && !message.self) {
            finded = true;
            finded_id = i;
          }
        }

        console.log('I_Window:finded:', finded, 'finded_id:', finded_id, 'offset_y:', this.messages_position[finded_id].offset_y);

        const container = document.querySelector('.ps--active-y');
        let chat_window = document.querySelector('.chat-window-box');

        if (container) {
          if (!finded) {
            container.scrollTop = container.scrollHeight;
          } else {
            container.scrollTop = this.messages_position[finded_id].offset_y - chat_window.clientHeight + 60;
          }
        }
      }
    },
    FillPositionArray() {
      this.messages_position = [];

      let messages_holder = document.querySelector('.chat-window-main');

      if (messages_holder) {
        let messages = messages_holder.querySelectorAll('.chat-message');
        // console.log('CHT_Window: FillPositionArray: messages:',messages)

        for (let i = 0; i < messages.length; i++) {
          let message = messages[i];
          let obj = {
            offset_y: message.offsetTop,
            id: this.invite_data.messages[i].id,
            unread: this.invite_data.messages[i].unread
          };

          this.messages_position.push(obj);
        }
      }
    },
    onScroll(event) {
      //let scroll_top_delta = 100;
      let chat_window = document.querySelector('.chat-window-box');
      let scroll_top = event.target.scrollTop + chat_window.clientHeight;

      // console.log('CHT_Window:', event.target.scrollTop + chat_window.clientHeight);

      if (this.invite_data && this.invite_data.messages) {
        for (let i = 0; i < this.messages_position.length; i++) {
          let message = this.messages_position[i];
          if (message.offset_y < scroll_top &&
              //message.offset_y > scroll_top - scroll_top_delta &&
              !this.invite_data.messages[i].self &&
              this.invite_data.messages[i].unread) {
            console.log('I_Window:message_id:', this.invite_data.messages[i].id);
            this.invite_data.messages[i].unread = false;
            this.SendReadedStatus(this.invite_data.messages[i].id)
          }
        }
        // if ( message.offset_y < scroll_top ) {
        //   if ( this.data_ms(this.last_date) < this.data_ms(message.date) ) {
        //     this.last_date = { ...message.date.day };
        //   }
        // }
      }
    },

    SendReadedStatus(id) {
      this.unread_to_send.push(id);

      if (this.unread_to_send_timeout) {
        clearTimeout(this.unread_to_send_timeout);
      }
      this.unread_to_send_timeout = setTimeout(() => {
        let send = {
          type: 'readed',
          data: {
            chat_id: this.invite.id,
            messages: this.unread_to_send,
          },
          callback: () => {
          }
        }
        this.SocketEmit(send);
        this.unread_to_send = [];
      }, 500);
    },
  },
  watch: {
    invite: {
      handler(newVal, oldVal) {
        console.log('I_Window:invite_data:', newVal, oldVal);
        if (newVal && newVal.user_name) {
          document.title = `${newVal.user_name} :: Invites :: Dating.Wedding`;
        }
        if (newVal && oldVal) {
          if (newVal.user_id !== oldVal.user_id) {
            this.scrolled_to = false;
          }
        }
      },
      deep: true
    },
    invite_data: {
      handler() {
        if (this.invite_data) {
          if (this.invite_data.messages && this.invite_data.messages[this.invite_data.messages.length - 1].self) {
            this.scrolled_to = false;
          }
          this.$nextTick(() => {
            this.FillPositionArray();
            if (!this.scrolled_to) {
              this.scrolled_to = true;
              this.$nextTick(() => {
                this.ScrollTo();
              });
            }
          });
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
