<template>
  <dialog class="popup" id="add-videos" :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 480px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Add videos</div>
      <form class="form">
        <div class="popup-content">
          <!--          <div class="popup-icons">-->
          <!--            <img src="img/youtube.svg" alt="">-->
          <!--            <img src="img/facebook.svg" alt="">-->
          <!--            <img src="img/tiktok.svg" alt="">-->
          <!--          </div>-->
          <fieldset>
            <label>Link to another resource</label>
            <div class="textfield-clear">
              <input type="text" class="textfield" :class="errors.video_link?'error':''" v-model="video_link">
              <button type="button"></button>
            </div>
          </fieldset>
          <fieldset>
            <label>Select preview image</label>
            <div class="popup-image default-photo" v-if="file_src">
              <Cropper
                  :src="file_src"
                  @change="change"
                  :stencil-props="{aspectRatio: 7/4,}"
                  image-restriction="none"
                  :move-image="false"
                  :resize-image="false"
              />
              <!--              <img :src="file_src">-->
            </div>
            <div class="textfield-clear file-input-holder" :class="errors.file_src?'error':''">
              <input type="file" class="file-input" @change="FileAdded" accept=".png,.jpg">
              <span v-if="file === null">*.png,*.jpg</span>
              <span v-else class="file-selected">{{ file.name }}</span>
              <div v-if="file !== null" type="button" @click="ClearFile"></div>
            </div>
          </fieldset>
          <fieldset>
            <label>Enter video description</label>
            <div class="textfield-clear">
              <input type="text" class="textfield" :class="errors.description?'error':''" v-model="description">
              <div type="button" v-if="description.length > 0" @click="ClearDescription"></div>
            </div>
          </fieldset>
          <fieldset>
            <label>Enter video price</label>
            <div class="textfield-clear">
              <input type="text" class="textfield number-arrow-hide" :class="errors.price?'error':''" v-model="price">
              <div type="button" v-if="price.length > 0" @click="ClearPrice"></div>
            </div>
          </fieldset>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <div class="button" @click="Save">Save video</div>
          </div>
        </div>
      </form>
      <div class="popup-cover" v-if="block">
        <div class="spinner"></div>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PAddVideoPrivate",
  data: function () {
    return {
      is_open: false,
      video_link: '',
      description: '',
      file: null,
      file_src: null,
      price: '',
      image: null,
      block: false,

      errors: {
        video_link: false,
        description: false,
        file_src: false,
        price: false
      }
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData']),
    ...mapActions(['PostCall', 'GetScopeUpdate']),

    FileAdded(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        this.file = files[0];
        this.file_src = URL.createObjectURL(this.file);
        this.errors.file_src = false;
      }
    },
    change({coordinates, canvas}) {
      console.log(coordinates, canvas);
      canvas.toBlob(blob => this.image = blob, 'image/png');
    },

    ClearFile() {
      this.file = null
    },
    ClearDescription() {
      this.description = ''
    },
    ClearPrice() {
      this.price = ''
    },

    Save(e) {
      e.preventDefault();
      if(this.image) {
        // let _file = this.file;
        let _file = this.image;

        const formData = new FormData();

        if (this.CheckInputs()) {
          this.block = true;

          formData.append('video_url', this.video_link);
          formData.append('type', 'videos');
          formData.append('description', this.description);
          formData.append('price', this.price);
          formData.append('file', _file, 'video_photo.png');

          this.PostCall({
            endpoint: '/users/private_videos',
            options: formData,
            callback: () => {
              /*if ( data ) {
            this.SetUserData({ photos: data });
          }*/
              this.SetUserData({toggle_private_video:true});
              this.GetScopeUpdate();
              this.hide();
            },
            on_error: (error) => {
              let error_status = error.response.status;
              console.log(error_status);
            },
          })
        }
      }else{
        this.SetPopupData({
          popup_additional_data: {
            text: 'Please select image',
          },
          private_flag: false,
          popup_name: 'error',
          popup_opened: true,
        })
      }
    },

    CheckInputs() {
      let ret = true;

      if (this.video_link === null || this.video_link === '') {
        ret = false;
        this.errors.video_link = true;
      } else {
        this.errors.video_link = false;
      }
      if (this.file_src === null || this.file_src === undefined) {
        ret = false;
        this.errors.file_src = true;
      }
      if (this.description === null || this.description === '') {
        ret = false;
        this.errors.description = true;
      } else {
        this.errors.description = false;
      }
      if (this.price === null || this.price === '' || this.price === '0' || this.price === 0 || parseInt(this.price) < 0) {
        ret = false;
        this.errors.price = true;
      } else {
        this.errors.price = false;
      }

      return ret;
    },

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>
