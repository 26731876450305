<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PEditPhotoDescription",
  data: function () {
    return {
      is_open: false,
      description: '',
      block: false,

      errors: {
        description: false,
      }
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      popup_additional_data: state => state.Popups.popup_additional_data,
      private_flag: state => state.Popups.private_flag,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
      this.description = this.popup_additional_data.photo.description;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData']),
    ...mapActions(['PutCall',]),

    ClearDescription() {
      this.description = ''
    },

    Save(e) {
      e.preventDefault();
      if (this.CheckInputs()) {
        this.block = true;

        const sendData = {type:'photos',description:this.description};

        let id = this.popup_additional_data.photo.id;

        this.PutCall({
          endpoint: this.private_flag?`/users/private_photos/${id}`:`/users/photos/${id}`,
          options: sendData,
          callback: (data) => {
            if (data) {
              if(!this.private_flag) {
                this.SetUserData({photos: data});
              }else{
                this.SetUserData({private_photos: data});
              }
            }
            this.hide();
          },
          on_error: (error) => {
            let error_status = error.response.status;
            console.log(error_status);
          },
        })
      }
    },

    CheckInputs() {
      let ret = true;

      if (this.description === null || this.description === '') {
        ret = false;
        this.errors.description = true;
      } else {
        this.errors.description = false;
      }

      return ret;
    },

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<template>
  <dialog class="popup" id="add-photos" :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 480px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Add photos</div>
      <div class="form">
        <div class="popup-content">
          <fieldset>
            <label>Enter description</label>
            <div class="textfield-clear">
              <input type="text" class="textfield" :class="errors.description?'error':''" v-model="description">
              <div type="button" v-if="description.length > 0" @click="ClearDescription"></div>
            </div>
          </fieldset>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottom-buttons">
            <div class="button" @click="Save">Save</div>
          </div>
        </div>
      </div>
      <div class="popup-cover" v-if="block">
        <div class="spinner"></div>
      </div>
    </div>
  </dialog>
</template>

<style scoped>

</style>
