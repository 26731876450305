<template>
  <div class="tip-content" id="burger-nav">
    <ul class="burger-nav">
<!--      <li>-->
<!--        <a href="#" class="burger-nav-search is-active">Let’s find someone</a>-->
<!--      </li>-->
      <!--		<li>-->
      <!--			<a href="#" class="burger-nav-stream">Let’s STREAM</a>-->
      <!--		</li>-->
      <li v-if="user_data && !user_data.is_seller">
        <router-link to="/billing" class="burger-nav-wallet">Мovement of coins</router-link>
      </li>
      <li>
        <router-link to="/blacklist" class="burger-nav-blacklist">BlackList</router-link>
      </li>
      <!--
            <li class="burger-nav-divider"></li>
            <li>
              <a href="#" class="burger-nav-sound is-enabled">Sound enabled</a>
              &lt;!&ndash;<a href="#" class="burger-nav-sound is-disabled">Sound disabled</a>&ndash;&gt;
            </li>
      -->
      <li class="burger-nav-divider"></li>
      <li>
        <div class="burger-nav-logout is-enabled" @click="Logout">Logout</div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "DDBurgerNav",
  computed: {
    ...mapState({
      user_data: state => state.User.user_data,
    }),
  },
  methods: {
    ...mapActions(['Logout']),
  }
}
</script>

<style scoped>

</style>
