<template>
  <section class="box open-box"
           :class="is_opened ? 'is-opened':''">
    <div class="box-header">
      <div class="box-header-item">
        <h3 v-if="gifts && gifts.length > 0">My presents - {{ gifts.length}}</h3>
        <h3 v-else>My presents</h3>
      </div>
      <div class="close-btn" @click="ToggleOpened"></div>
    </div>
    <div class="box-content">
      <div class="presents">
        <template v-for="(value, index) in gifts" v-bind:key="index">
          <figure class="present">
            <picture class="present-image">
              <!--            <source srcset="/pic/present-1.webp 1x, /pic/present-1@2x.webp 2x" type="image/webp">-->
              <!--            <source srcset="/pic/present-1.png 1x, /pic/present-1@2x.png 2x" type="image/png">-->
              <img :src="static_server+'/'+value.image">
            </picture>
            <div class="present-user">
              <picture class="present-user-avatar">
                <!--              <source srcset="/pic/avatar.webp 1x, /pic/avatar@2x.webp 2x" type="image/webp">-->
                <!--              <source srcset="/pic/avatar.png 1x, /pic/avatar@2x.png 2x" type="image/png">-->
                <img :src="static_server+'/'+value.main_photo">
              </picture>
              <div class="present-user-info">
                <span>{{value.user_name}}</span>
                <p>{{value.date}}</p>
              </div>
            </div>
          </figure>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
//import { mapState } from "vuex";

import {mapState} from "vuex";

export default {
  name: "UP_Gifts",
  data:()=>{
    return {
      is_opened:false,
    }
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      gifts: state => state.User.user_data.gifts,
    }),
  },

  methods:{
    ToggleOpened(){
      this.is_opened = !this.is_opened;
    }
  },
}
</script>

<style scoped>

</style>