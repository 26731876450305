<script>
import { mapState } from "vuex";
import PAddPhotos from "./parts/PAddPhotos";
import PAddAvatar from "./parts/PAddAvatar";
import PAddBg from "./parts/PAddBg";
import PAddVideo from "./parts/PAddVideo";
import PSearch from "./parts/PSearch";
import PViewPhotos from "./parts/PViewPhotos";
import PChatPhotoAdd from "./parts/PChatPhotoAdd";
import PAddPhotosPrivate from "./parts/PAddPhotosPrivate";
import PAgencyCall from "./parts/PAgencyCall";
import PMood from "./parts/PMood";
import PAddFavorites from "./parts/PAddFavorites";
import PDeleteFav from "./parts/PDeleteFav";
import PBuyCoins from "./parts/PBuyCoins";
import PNoCoins from "./parts/PNoCoins";
import PShowPaymentInfo from "./parts/PShowPaymentInfo";
import PSendInvite from "./parts/PSendInvite";
import PViewVideos from "@/components/Popups/parts/PViewVideos";
import PAddVideoPrivate from "@/components/Popups/parts/PAddVideoPrivate.vue";
import PChatVideoAdd from "@/components/Popups/parts/PChatVideoAdd.vue";
import PAddBlock from "@/components/Popups/parts/PAddBlock.vue";
import PGoogleRemove from "@/components/Popups/parts/PGoogleRemove.vue";
import PDeleteBlock from "@/components/Popups/parts/PDeleteBlock.vue";
import PBlocked from "@/components/Popups/parts/PBlocked.vue";
import PError from "@/components/Popups/parts/PError.vue";
import PDeleteFavAll from "@/components/Popups/parts/PDeleteFavAll.vue";
import PAddPhoto from "@/components/Popups/parts/PAddPhoto.vue";
import PAddPhotoPrivate from "@/components/Popups/parts/PAddPhotoPrivate.vue";
import PEditPhotoDescription from "@/components/Popups/parts/PEditPhotoDescription.vue";

export default {
  name: "Popups",
  components:{
    PEditPhotoDescription: PEditPhotoDescription,
    PAddPhotoPrivate,
    PAddPhoto,
    PDeleteFavAll,
    PError,
    PBlocked,
    PDeleteBlock,
    PGoogleRemove,
    PAddBlock,
    PChatVideoAdd,
    PAddVideoPrivate,
    PViewVideos,
    PNoCoins,
    PBuyCoins,
    PShowPaymentInfo,
    PDeleteFav,
    PAddFavorites,
    PMood,
    PAgencyCall,
    PAddPhotosPrivate,
    PChatPhotoAdd,
    PViewPhotos,
    PSearch,
    PAddPhotos,
    PAddAvatar,
    PAddBg,
    PAddVideo,
    PSendInvite,
  },
  computed: {
    ...mapState({
      popup_name: state => state.Popups.popup_name,
    }),
  },
}
</script>

<template>
  <PEditPhotoDescription v-if="popup_name==='edit_description'"/>
  <PAddPhoto v-if="popup_name==='add_photo'"/>
  <PAddPhotos v-if="popup_name==='add_photos'"/>
  <PAddPhotoPrivate v-if="popup_name==='add_photo_private'"/>
  <PAddPhotosPrivate v-if="popup_name==='add_photos_private'"/>
  <PAddAvatar v-if="popup_name==='add_avatar'"/>
  <PAddBg v-if="popup_name==='add_bg'"/>
  <PAddVideo v-if="popup_name==='add_video'"/>
  <PAddVideoPrivate v-if="popup_name==='add_video_private'"/>
  <PSearch v-if="popup_name==='search'"/>
  <PViewPhotos v-if="popup_name==='view_photo'"/>
  <PViewVideos v-if="popup_name==='view_video'"/>
  <PChatPhotoAdd v-if="popup_name==='chat_photo_add' || popup_name==='chat_add'"/>
  <PChatVideoAdd v-if="popup_name==='chat_video_add'"/>
  <PAgencyCall v-if="popup_name==='agency_call'"/>
  <PMood v-if="popup_name==='mood'"/>
  <PAddFavorites v-if="popup_name==='add_fav'"/>
  <PDeleteFav v-if="popup_name==='delete_fav'"/>
  <PDeleteFavAll v-if="popup_name==='delete_fav_all'"/>
  <PAddBlock v-if="popup_name==='add_block'"/>
  <PDeleteBlock v-if="popup_name==='delete_block'"/>
  <PBuyCoins v-if="popup_name==='buy_coins'"/>
  <PNoCoins v-if="popup_name==='no_coins'"/>
  <PShowPaymentInfo v-if="popup_name==='payment_info'"/>
  <PSendInvite v-if="popup_name==='send_invite'"/>
  <PGoogleRemove v-if="popup_name==='google_remove'"/>
  <PBlocked v-if="popup_name==='blocked'"/>
  <PError v-if="popup_name==='error'"/>
</template>

<style scoped>

</style>
