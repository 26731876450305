<template>
  <div class="overlay" :class="overlay ? 'is-visible':''" @click="SetPopupData({popup_opened:false})"></div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "Overlay",
  computed: {
    ...mapState({
      overlay: state => state.Popups.overlay,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupData',]),
  }
}
</script>

<style scoped>

</style>