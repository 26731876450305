<template>
  <div class="chat-header">
    <div class="chat-user" v-if="communication">
      <div class="chat-user-avatar" :class="communication.online ? 'is-online':''" @click="GoToUserView">
        <picture v-if="communication && communication.user_photo_url">
          <!--          <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
          <!--          <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
          <img :src="static_server + '/' + communication.user_photo_url" alt="">
        </picture>
      </div>
      <div class="chat-user-info">
        <h1 class="chat-user-name">{{ communication.name }}</h1>
        <div class="chat-user-status" v-if="communication.online">Online</div>
        <div class="chat-user-status" v-else>Offline</div>
      </div>
    </div>
    <div class="chat-user" v-else>
      <div class="chat-user-info">
        <h1 class="chat-user-name">There's no one here yet</h1>
      </div>
    </div>

    <div class="chat-header-gallery">
      <div class="swiper-container" v-if="letters_data && letters_data.user_photos && letters_data.user_photos.length>0">
        <swiper
            :slides-per-view="'auto'"
            :space-between="6"

            @swiper="onSwiper"
            @slideChange="onSlideChange"
            ref="swiper"
        >
          <template v-for="(value, index) in letters_data.user_photos" v-bind:key="index">
            <swiper-slide class="swiper-slide">
              <div class="chat-header-gallery-image" @click="OpenViewPhotos(index)">
                <picture>
                  <!--                <source srcset="pic/chat-header-gallery.webp 1x, pic/chat-header-gallery@2x.webp 2x" type="image/webp">-->
                  <!--                <source srcset="pic/chat-header-gallery.jpg 1x, pic/chat-header-gallery@2x.jpg 2x" type="image/jpeg">-->
                  <img :src="static_server+'/'+value.preview" alt="">
                </picture>
              </div>
            </swiper-slide>
          </template>

        </swiper>
      </div>
      <template v-if="swiper_overflow">
        <button type="button" class="swiper-prev" @click="PrevSlide"></button>
        <button type="button" class="swiper-next" @click="NextSlide"></button>
      </template>
    </div>
    <div class="chat-header-toolbox" v-if="communication">
      <ul class="chat-header-actions">
        <!--        <li>-->
        <!--          <button type="button" class="chat-header-actions-webcam"></button>-->
        <!--        </li>-->
        <li>
          <button title="Add user to favorites" type="button"
                  class="chat-header-actions-fav" :class="letters_data && letters_data.favorites ? 'active':''"
                  @click="AddToFav"></button>
        </li>
        <li>
          <button title="Block user" type="button"
                  class="chat-header-actions-block" :class="letters_data && letters_data.blocked ? 'active':''"
                  @click="BlockUser"></button>
        </li>
      </ul>
      <button type="button" class="chat-header-actions-toggle tip" data-tip="#chat-actions" data-tippy-placement="bottom-end"></button>
    </div>
  </div>
</template>

<script>
// import Swiper core and required components
import SwiperCore from "swiper";
import {Navigation, Pagination, A11y} from "swiper/modules";

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue";

// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y]);

import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "L_Top",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
      swiper_overflow: false,
    };
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      user_data: state => state.User.user_data,

      communication: state => state.Letters.communication,
      letters_data: state => state.Letters.letters_data,
    }),
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 2000);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetLetterData']),
    ...mapActions(['PostCall', 'DeleteCall']),
    onResize() {
      if (this.letters_data && this.letters_data.user_photos && document.querySelector('.swiper-container')) {
        this.swiper_overflow = document.querySelector('.swiper-container').offsetWidth < (this.letters_data.user_photos.length * 58);
      } else {
        this.swiper_overflow = false;
      }
    },
    GoToUserView(e) {
      e.stopPropagation();
      this.$router.push({path: `/user-view`, query: {id: this.communication.user_id}});
    },
    OpenViewPhotos(index) {
      this.SetPopupData({
        photo_view_data: this.letters_data.user_photos,
        photo_view_index: index,
        private_flag: false,
        popup_name: 'view_photo',
        popup_opened: true,
      })
    },
    PrevSlide() {
      this.swiper.slidePrev();
    },
    NextSlide() {
      this.swiper.slideNext();
    },
    onSwiper(swiper) {
      //console.log(swiper);
      this.swiper = swiper;
    },
    onSlideChange() {
      // console.log('slide change')
    },

    AddToFav() {
      if (!this.letters_data.favorites) {
        this.SetPopupData({
          popup_additional_data: {
            user_id: this.communication.user_id,
            callback: (id) => {
              console.log('L_Top:AddToFav:callback:', id);
              this.SetLetterData({favorites: id});
            }
          },
          private_flag: false,
          popup_name: 'add_fav',
          popup_opened: true,
        })
      } else {
        this.SetPopupData({
          popup_additional_data: {
            fav_id: this.letters_data.favorites,
            callback: () => {
              this.SetLetterData({favorites: false});
            }
          },
          private_flag: false,
          popup_name: 'delete_fav',
          popup_opened: true,
        })
      }
    },

    BlockUser() {
      if (!this.letters_data.blocked) {
        this.SetPopupData({
          popup_additional_data: {
            user_name: this.communication.user_name,
            user_id: this.communication.user_id,
            callback: (id) => {
              this.SetLetterData({blocked: id});
            }
          },
          private_flag: false,
          popup_name: 'add_block',
          popup_opened: true,
        })
      } else {
        this.SetPopupData({
          popup_additional_data: {
            blocked: this.letters_data.blocked,
            callback: () => {
              this.SetLetterData({blocked: false});
            }
          },
          private_flag: false,
          popup_name: 'delete_block',
          popup_opened: true,
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
