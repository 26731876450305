<template>
  <section class="user">
    <div class="user-box">
      <picture class="user-box-bg">
        <img v-if="udata.banner_photo !== null" :src="static_server + '/' + udata.banner_photo" alt="">
      </picture>
      <div class="user-content">

        <div class="user-top">
          <div class="user-top-side">
            <!--              <GButton-->
            <!--                  text="Back"-->
            <!--                  button_style="back"-->
            <!--              />-->
          </div>
          <div class="user-top-side">
            <GButton
                text="Add cover"
                button_style="plus"
                @click="Bg_File_Add"
            />
            <GButton
                v-if="!udata.is_seller && udata.coins  || udata.coins === 0"
                :text="'Your balance: '+ udata.coins"
                button_style="wallet"
                @click="BuyCoins"
            />
            <GButton
                v-else-if="udata.coins || udata.coins === 0"
                :text="'Your balance: '+ udata.coins"
                button_style="wallet"
            />

          </div>
        </div>

        <div class="user-main">

          <div class="user-avatar">
            <div class="user-avatar-image">
              <picture>
                <img v-if="udata.main_photo !== null" :src="static_server + '/' + udata.main_photo" alt="">
              </picture>
              <template v-if="mood_img">
                <img :src="mood_img" class="user-avatar-mood" :title="mood_description">
              </template>
            </div>
            <GButton
                text="Upload new photo"
                button_style="plus"
                @click="Avatar_File_Add"
            />
          </div>

          <div class="user-settings">

            <div class="user-settings-block">
              <div class="user-info">

                <label>Name&nbsp;*</label>
                <fieldset>
                  <input type="text" class="textfield" v-model="udata.user_name">
                </fieldset>

                <label>Birthday&nbsp;*</label>
                <fieldset>
                  <div class="user-birthday">
                    <div class="user-birthday-field">
                      <GSelect
                          v-model="udata.birthday_year"
                          :options="ageSelectOptions"
                          text-label="name"
                          placeholder=""
                          unic_id="birthday_year"
                          input_type="number"
                          class="user-select"
                      />
                    </div>
                    <div class="user-birthday-field">
                      <GSelect
                          v-model="udata.birthday_month"
                          :options="month"
                          text-label="name"
                          placeholder=""
                          unic_id="birthday_year"
                          input_type=""
                          class="user-select"
                      />
                    </div>
                    <div class="user-birthday-field">
                      <GSelect
                          v-model="udata.birthday_day"
                          :options="daySelectOptions"
                          text-label="name"
                          placeholder=""
                          unic_id="birthday_day"
                          input_type=""
                          class="user-select"
                      />
                    </div>
                  </div>
                </fieldset>

                <label>I am&nbsp;*</label>
                <fieldset>
                  <div class="sex">
                    <label class="sex-male" title="Male">
                      <input type="radio" value="true" name="user-sex" v-model="udata.male">
                      <span></span>
                    </label>
                    <label class="sex-female" title="Female">
                      <input type="radio" value="false" name="user-sex" v-model="udata.male">
                      <span></span>
                    </label>
                  </div>
                </fieldset>

                <label>Language&nbsp;*</label>
                <fieldset>
                  <div class="user-info-lang">
                    <GSelect
                        v-model="udata.language"
                        :options="languages"
                        text-label="title"
                        placeholder=""
                        unic_id="main_language"
                        input_type=""
                        ZIndex="99"
                        class="user-select"
                    />
                  </div>
                </fieldset>

              </div>
            </div>

            <template v-if="user_data.id_telegram">
              <div class="user-settings-block linkbtn" v-if="user_data && !user_data.id_google">
                Link with Google account
                <GoogleLogin :callback="googleLoadedCallback" :client-id="google_cid"/>
              </div>
              <div class="user-settings-block" v-if="user_data && user_data.id_google && user_data.email">
                Linked with {{ user_data.email }}
                <div class="close-btn" @click="KillGoogle"></div>
              </div>
            </template>
            <template v-if="user_data.id_google">
              <div class="user-settings-block linkbtn" v-if="user_data && !user_data.id_telegram">
                Link with Telegram account
                <telegram-login-temp
                    mode="callback"
                    :telegram-login="telegram_login"
                    @loaded='telegramLoadedCallbackFunc'
                    @callback="telegramCallbackFunction"
                />
              </div>
              <div class="user-settings-block" v-if="user_data && user_data.id_telegram">
                Linked with telegram
                <div class="close-btn" @click="KillTelegram"></div>
              </div>
            </template>

            <div class="user-settings-block">
              <a class="button" @click="GoToPreview">Preview my page</a>
            </div>

          </div>

        </div>

        <div class="user-settings-fill-info">*&nbsp;Please fill in these fields so that you can be found</div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import GButton from "@/G_UI/GButton";
import GSelect from "@/G_UI/GSelect";
import {GoogleLogin} from "vue3-google-login";
import {telegramLoginTemp} from 'vue3-telegram-login'

export default {
  name: "UP_UserInfo",
  components: {telegramLoginTemp, GSelect, GButton, GoogleLogin},
  data: function () {
    return {
      minyear: 2003,
      deltayear: 99,
      month: [
        {id: 0, name: 'January'},
        {id: 1, name: 'February'},
        {id: 2, name: 'March'},
        {id: 3, name: 'April'},
        {id: 4, name: 'May'},
        {id: 5, name: 'June'},
        {id: 6, name: 'July'},
        {id: 7, name: 'August'},
        {id: 8, name: 'September'},
        {id: 9, name: 'October'},
        {id: 10, name: 'November'},
        {id: 11, name: 'December'},
      ],
      day: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      lang: ['English', 'French', 'Arabic'],

      balance: 150,

      avatar_file: null,
      bg_file: null,

      server_update: false,
      udata: {
        user_name: "",
        main_photo: null, //если пусто, то null
        banner_photo: null, //если пусто, то null
        male: true,
        language: null, //id from user_info.languages
        min_year: 2003,
        birthday_day: null,
        birthday_month: null,
        birthday_year: null,
      },
      pre_data: {},

      update_timeout: undefined,

      mood_img: null,
      mood_description: '',
    }
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      google_cid: state => state.Main.google_cid,
      telegram_login: state => state.Main.telegram_login,
      user_data: state => state.User.user_data,
      user_id: state => state.User.user_data.id,
      languages: state => state.User.languages,
    }),
    ageSelectOptions() {
      let tempArray = [];

      for (let i = 1; i < this.deltayear; i++) {
        //console.log(this.data.min_year, i);

        let tempObj = {
          id: this.udata.min_year - i,
          name: this.udata.min_year - i
        }

        tempArray.push(tempObj);
      }

      return tempArray;
    },
    daySelectOptions() {
      let tempArray = [];

      if (this.udata.birthday_month !== null && this.udata.birthday_month !== undefined) {
        for (let i = 1; i <= this.day[this.udata.birthday_month]; i++) {
          let tempObj = {
            id: i,
            name: i
          }
          tempArray.push(tempObj);
        }
      }

      return tempArray;
    }
  },
  mounted() {
    this.server_update = true;
    Object.assign(this.udata, this.user_data);
    Object.assign(this.pre_data, this.user_data);

    if (this.user_data.mood_id !== null && this.user_data.mood_id !== undefined) {
      this.mood_img = `img/mood-${parseInt(this.user_data.mood_id) + 1}.svg`;
    }
    if (this.user_data.mood_description) {
      this.mood_description = this.user_data.mood_description;
    }

    console.log(this.udata.user_name);

    setTimeout(() => {
      this.server_update = false
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData',]),
    ...mapActions(['PostCall', 'PutCall']),
    googleLoadedCallback(response) {
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup
      console.log("Handle the response", response)
      this.SetUserData({auth_pending: true});
      this.PostCall({
        endpoint: '/auth/google',
        options: response,
        callback: (data) => {
          this.SetUserData({auth_pending: false});
          if (data.result === 'ok') {
            this.SetUserData({
              email: data.google_email,
              id_google: '01010101'
            });
          } else {
            if (data.result === 'error' && data.error === 'exist') {
              this.SetPopupData({
                popup_additional_data: {
                  text: 'Account already exists',
                },
                private_flag: false,
                popup_name: 'error',
                popup_opened: true,
              })
            }
          }
        },
        on_error: (error) => {
          console.log(error);
        },
      })
    },
    KillGoogle() {
      this.SetPopupData({
        popup_additional_data: {},
        private_flag: false,
        popup_name: 'google_remove',
        popup_opened: true,
      })
    },

    telegramLoadedCallbackFunc() {
      console.log('script is loaded')
    },
    telegramCallbackFunction(user) {
      // gets user as an input
      // id, first_name, last_name, username,
      // photo_url, auth_date and hash
      console.log(user)//https://dating.mir.digital/telegram/login

      this.SetUserData({auth_pending: true});
      this.PostCall({
        endpoint: '/auth/telegram',
        options: user,
        callback: (data) => {
          this.SetUserData({auth_pending: false});
          if (data.result === 'ok') {
            this.SetUserData({is_auth: true});

            // let l_storage = window.localStorage;
            // l_storage.setItem('token', data.access_token);
            this.$router.push({path: '/user-profile'});
            // setTimeout(()=>{
            // },3000);
          } else {
            if (data.result === 'error' && data.error === 'exist') {
              this.SetPopupData({
                popup_additional_data: {
                  text: 'Account already exists',
                },
                private_flag: false,
                popup_name: 'error',
                popup_opened: true,
              })
            }
          }
        },
        on_error: (error) => {
          console.log(error);
        },
      })
    },
    KillTelegram() {
      this.SetPopupData({
        popup_additional_data: {},
        private_flag: false,
        popup_name: 'telegram_remove',
        popup_opened: true,
      })
    },

    BuyCoins() {
      if (this.udata.invoice_url) {
        this.SetUserData({
          payment: {invoice_url: this.udata.invoice_url}
        });
        this.SetPopupData({
          private_flag: false,
          popup_name: 'payment_info',
          popup_opened: true
        })
      } else {
        this.SetPopupData({
          private_flag: false,
          popup_name: 'buy_coins',
          popup_opened: true,
        })
      }
    },
    GoToPreview() {
      this.$router.push({path: '/user-view', query: {id: this.user_id}});
    },
    Avatar_File_Add() {
      this.SetPopupData({
        private_flag: false,
        popup_name: 'add_avatar',
        popup_opened: true
      })
    },
    Bg_File_Add() {
      this.SetPopupData({
        private_flag: false,
        popup_name: 'add_bg',
        popup_opened: true
      })
    },

    Save(key, value) {

      let form = {
        key: key,
        value: value
      }

      this.PutCall({
        endpoint: '/users/update',
        options: form,
        callback: (data) => {
          console.log(data);
          this.SetUserData({user_data: data});
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    }
  },
  watch: {
    user_data: {
      handler(val) {
        this.server_update = true;
        console.log('user_data:val:', val);
        Object.assign(this.udata, val);
        Object.assign(this.pre_data, val);

        if (val.mood_id !== null && val.mood_id !== undefined) {
          this.mood_img = `img/mood-${parseInt(val.mood_id) + 1}.svg`;
        }
        if (val.mood_description) {
          this.mood_description = val.mood_description;
        }

        console.log(this.udata.user_name);

        setTimeout(() => {
          this.server_update = false;
        }, 100);
      },
      deep: true
    },
    udata: {
      handler(val) {
        console.log('UserInfo:watch:udata', this.server_update);
        if (!this.server_update) {

          let val_keys = Object.keys(val);
          let oldVal_keys = Object.keys(this.pre_data);

          let i, j, val_length = val_keys.length, oldVal_length = oldVal_keys.length;
          // console.log(Object.keys(this.pre_data));
          for (i = 0; i < val_length; i++) {
            for (j = 0; j < oldVal_length; j++) {
              if (val_keys[i] === oldVal_keys[j]) {
                if (val[val_keys[i]] !== this.pre_data[oldVal_keys[j]]) {

                  // console.log(val_keys[i], val[val_keys[i]], this.pre_data[oldVal_keys[j]]);

                  if (val_keys[i] === 'user_name') {
                    if (this.update_timeout !== undefined) {
                      clearTimeout(this.update_timeout);
                    }
                    this.update_timeout = setTimeout(() => {
                      this.Save('user_name', this.udata.user_name);
                    }, 1000);
                  } else {
                    this.Save(val_keys[i], val[val_keys[i]]);
                  }
                  Object.assign(this.pre_data, val);
                }
              }
            }
          }
        }
      },
      deep: true,
    }
  },

}
</script>

<style scoped>

</style>
