<template>
  <perfect-scrollbar
      :watchOptions="true"
      :options="scroll_options"
      class="tip-content" id="notifications">
    <div class="notifications">
      <template v-for="(value,index) in obj" v-bind:key="index">
        <div class="notification" v-if="value.type==='refill'">
          <div class="notification-image">
            <img src="/img/warning.svg" alt="">
          </div>
          <div class="notification-content">
            <div class="notification-header">{{ value.title }}</div>
            <div class="notification-text">{{ value.message }}</div>
            <div class="button button-block button-wallet" @click="RefillClicked">Refill</div>
          </div>
        </div>
      </template>
    </div>
  </perfect-scrollbar>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "DDNotifications",
  computed: {
    ...mapState({
      dd_notifications: state => state.Dropdowns.dd_notifications,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
        // scrollYMarginOffset: -15
      },
      obj: [
        {
          id: 1,
          uuid: "1488624a-ee81-4c76-8d84-e03c013166f7",
          created_at: "2022-09-18T20:59:11.423Z",
          type: "refill",
          title: "Warning:",
          message:'you don\'t have enough cash'
        },
      ],
    }
  },
  methods: {
    ...mapMutations(['SetPopupData']),
    RefillClicked() {
      this.SetPopupData({
        private_flag: false,
        popup_name: 'buy_coins',
        popup_opened: true,
      })
    },
  },
}
</script>

<style scoped>

</style>