<template>
  <div class="chat-header">
    <div class="chat-user" v-if="chat">
      <div class="chat-user-avatar" :class="chat.online ? 'is-online':''" @click="GoToUserView">
        <picture v-if="chat && chat.user_photo_url">
          <!--          <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
          <!--          <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
          <img :src="static_server + '/' + chat.user_photo_url" alt="">
        </picture>
      </div>
      <div class="chat-user-info">
        <h1 class="chat-user-name">{{ chat.name }}</h1>
        <div class="chat-user-status" v-if="chat.online">Online</div>
        <div class="chat-user-status" v-else>Offline</div>
      </div>
    </div>
    <div class="chat-user" v-else>
      <div class="chat-user-info">
        <h1 class="chat-user-name">There's no one here yet</h1>
      </div>
    </div>
    <div class="chat-header-gallery">
      <div class="swiper-container" v-if="chat_data && chat_data.user_photos && chat_data.user_photos.length > 0">
        <swiper
            :slides-per-view="'auto'"
            :space-between="6"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            ref="swiper"
        >
          <template v-for="(value, index) in chat_data.user_photos" v-bind:key="index">
            <swiper-slide class="swiper-slide">
              <div class="chat-header-gallery-image" @click="OpenViewPhotos(index)">
                <picture>
                  <!--                <source srcset="pic/chat-header-gallery.webp 1x, pic/chat-header-gallery@2x.webp 2x" type="image/webp">-->
                  <!--                <source srcset="pic/chat-header-gallery.jpg 1x, pic/chat-header-gallery@2x.jpg 2x" type="image/jpeg">-->
                  <img :src="static_server+'/'+value.preview" alt="" loading="lazy">
                </picture>
                <div class="swiper-lazy-preloader"></div>
              </div>
            </swiper-slide>
          </template>

        </swiper>
      </div>
      <template v-if="swiper_overflow">
        <button type="button" class="swiper-prev" @click="PrevSlide"></button>
        <button type="button" class="swiper-next" @click="NextSlide"></button>
      </template>
    </div>
    <div class="chat-header-toolbox" v-if="chat">
      <time class="chat-header-time"
            v-if="this.chat_data && this.chat_data.timer && this.chat_data.timer.duration !== undefined && this.chat_data.timer.duration !== null">
        <span><i>{{ new Date(chat_time * 1000).getMinutes() }}</i> min <i>{{ new Date(chat_time * 1000).getSeconds() }}</i> sec</span>
        <!--        <button type="button" class="button button-pause" @click="StopTimer">Stop Chat</button>-->
        <button type="button" class="button" @click="StopTimer">Stop Chat</button>
      </time>
      <div class="chat-header-time" v-else-if="user_data && !user_data.is_seller && chat_price">
        Price {{ chat_price }} coins/min
      </div>
      <ul class="chat-header-actions">
        <!--        <li>-->
        <!--          <button type="button" class="chat-header-actions-webcam"></button>-->
        <!--        </li>-->
        <li>
          <button title="Add user to favorites" type="button"
                  class="chat-header-actions-fav" :class="chat_data && chat_data.favorites ? 'active':''"
                  @click="AddToFav"></button>
        </li>
        <li>
          <button title="Block user" type="button"
                  class="chat-header-actions-block" :class="chat_data && chat_data.blocked ? 'active':''"
                  @click="BlockUser"></button>
        </li>
      </ul>
      <button type="button" class="chat-header-actions-toggle tip" data-tip="#chat-actions" data-tippy-placement="bottom-end"></button>
    </div>
  </div>
</template>

<script>
// import Swiper core and required components
import SwiperCore from "swiper";
import {Navigation, Pagination, A11y} from "swiper/modules";

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue";

// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y]);

import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "CHT_Top",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
      swiper_overflow: false,
      chat_time: 0,
      chat_timer_interval: undefined,
      chat_timer_watch_block: false,
    };
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      user_data: state => state.User.user_data,
      chat: state => state.Chat.chat,
      chat_price: state => state.Chat.chat_price,
      chat_data: state => state.Chat.chat_data,
      // chat_data_timer: state => state.Chat.chat_data.timer,
      chat_timer_data: state => state.Chat.chat_timer_data,
    }),
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 2000);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
    if (this.chat_timer_interval) {
      clearInterval(this.chat_timer_interval);
    }
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetChatData']),
    ...mapActions(['PostCall', 'DeleteCall', 'SocketEmit']),
    onResize() {
      if (this.chat_data && this.chat_data.user_photos && document.querySelector('.swiper-container')) {
        this.swiper_overflow = document.querySelector('.swiper-container').offsetWidth < (this.chat_data.user_photos.length * 58);
      } else {
        this.swiper_overflow = false;
      }
    },
    GoToUserView(e) {
      e.stopPropagation();
      this.$router.push({path: `/user-view`, query: {id: this.chat.user_id}});
    },
    OpenViewPhotos(index) {
      this.SetPopupData({
        photo_view_data: this.chat_data.user_photos,
        photo_view_index: index,
        private_flag: false,
        popup_name: 'view_photo',
        popup_opened: true,
      })
    },
    PrevSlide() {
      this.swiper.slidePrev();
    },
    NextSlide() {
      this.swiper.slideNext();
    },
    onSwiper(swiper) {
      //console.log(swiper);
      this.swiper = swiper;
    },
    onSlideChange() {
      // console.log('slide change')
    },

    AddToFav() {
      if (!this.chat_data.favorites) {
        this.SetPopupData({
          popup_additional_data: {
            user_id: this.chat.user_id,
            callback: (id) => {
              this.SetChatData({favorites: id});
            }
          },
          private_flag: false,
          popup_name: 'add_fav',
          popup_opened: true,
        })
      } else {
        this.SetPopupData({
          popup_additional_data: {
            fav_id: this.chat_data.favorites,
            callback: () => {
              this.SetChatData({favorites: false});
            }
          },
          private_flag: false,
          popup_name: 'delete_fav',
          popup_opened: true,
        })
      }
    },
    BlockUser() {
      if (!this.chat_data.blocked) {
        this.SetPopupData({
          popup_additional_data: {
            user_name: this.chat.user_name,
            user_id: this.chat.user_id,
            callback: (id) => {
              this.SetChatData({blocked: id});
            }
          },
          private_flag: false,
          popup_name: 'add_block',
          popup_opened: true,
        })
      } else {
        this.SetPopupData({
          popup_additional_data: {
            blocked: this.chat_data.blocked,
            callback: () => {
              this.SetChatData({blocked: false});
            }
          },
          private_flag: false,
          popup_name: 'delete_block',
          popup_opened: true,
        })
      }
    },
    StartTimer() {
      if (this.chat_timer_interval) {
        clearInterval(this.chat_timer_interval);
      }
      this.chat_time = 0;
      if (this.chat_data && this.chat_data.timer && this.chat_data.timer.duration !== null && this.chat_data.timer.duration !== undefined) {
        console.log('this.chat_data.timer.duration', this.chat_data.timer.duration);
        this.chat_time = this.chat_data.timer.duration;
        // this.chat_data.timer.duration = null;
      }
      this.chat_timer_interval = setInterval(() => {
        this.chat_time++;
      }, 1000);
    },
    StopTimer() {
      let send = {
        type: 'chat_timer',
        data: {
          method: 'stop',
          chat_id: this.chat.id
        },
        callback: () => {
          if (this.chat_timer_interval) {
            clearInterval(this.chat_timer_interval);
          }
          this.SetChatData({chat_timer_data: null, set_timer_duration: null});
        }
      }
      this.SocketEmit(send);
    }
  },
  watch: {
    chat_timer_data: {
      handler(newVal) {
        if (newVal.method === 'start') {
          console.log('CHT_Top: watch: chat_timer_data:', newVal);
          this.chat_time = parseFloat(newVal.duration);
          this.SetChatData({set_timer_duration: 0});
          this.StartTimer();
        }
        if (newVal.method === 'update') {
          console.log('CHT_Top: watch: chat_timer_data:', newVal);
          this.chat_time = parseFloat(newVal.duration);
          this.StartTimer();
        }
      },
      deep: true
    },
    chat_data: {
      handler() {
        this.$nextTick(() => {
          if (this.chat_data) {
            this.onResize();
          }
        })
      },
      deep: true
    },
    // chat_data_timer: {
    //   handler(newVal, oldval) {
    //     if (newVal && newVal.timer && newVal.timer.duration !== undefined && newVal.timer.duration !== null) {
    //       console.log('CHT_Top: watch: chat_data.timer:', newVal);
    //       this.chat_time = parseFloat(newVal.timer.duration);
    //       this.StartTimer();
    //     } else {
    //       if (oldval && oldval.timer && oldval.duration) {
    //         this.StopTimer();
    //       }
    //     }
    //   },
    //   deep: true
    // },

  }
}
</script>

<style scoped>

</style>
