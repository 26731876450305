<template>
  <div class="container">
    <div class="panel-top">
      <div class="panel-top-side">
        <div class="panel-top-box">
          <h1>Blacklist</h1>
        </div>
      </div>
      <div class="panel-top-side">
        <div class="panel-top-box">
          <div class="search">
            <input type="text" class="textfield" placeholder="Start typing a name" v-model="search">
            <div type="button" class="search-go" @click="GetBlackList"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-wrapper" v-if="blacklist && blacklist.length>0">
      <table class="table">
        <thead>
        <tr>
          <th>User</th>
          <th>Reason</th>
          <th>
            <div class="table-sortable">Date</div>
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(value, index) in blacklist" v-bind:key="index">
          <tr>
            <td>
              <div class="table-user">
                <div class="table-user-avatar" :class="value.blocked.online ? 'is-online':''">
                  <picture v-if="value.blocked.main_photo">
                    <img :src="static_server + '/' + value.blocked.main_photo" alt="">
                  </picture>
                </div>
                <span>{{ value.blocked.user_name }}, {{ value.blocked.age }}</span>
              </div>
            </td>
            <td data-title="Reason">{{ value.comment }}</td>
            <td data-title="Date">
              <time style="justify-content: flex-start">
                <span>{{
                    new Date(value._created_at).getDate()
                      }}<sup>{{ nth(new Date(value._created_at).getDate()) }}</sup> {{ month[new Date(value._created_at).getMonth()] }} {{
                    new Date(value._created_at).getFullYear()
                      }}</span>
                <span>{{
                    new Date(value._created_at).getHours()
                      }}:{{
                    new Date(value._created_at).getMinutes() <= 9 ? '0' + new Date(value._created_at).getMinutes() : new Date(value._created_at).getMinutes()
                      }}</span>
              </time>
            </td>
            <td>
              <button type="button" class="button-link button-link-unblock" @click="Unblock(value.id)">Unblock</button>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <template v-else>
      <div class="search-result-empty">Nothing found</div>
    </template>

    <!--    <div class="panel-bottom">
          <div class="panel-bottom-box">
            <ul class="pager">
              <li>
                <a href="#" class="pager-next disabled"></a>
              </li>
              <li>
                <span>630</span>
              </li>
              <li>...</li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">1</a>
              </li>
              <li>
                <a href="#" class="pager-prev"></a>
              </li>
            </ul>
          </div>
          <div class="panel-bottom-box">
            <a href="#" class="button">Load more</a>
          </div>
        </div>
        -->
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "Blacklist",
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      month: state => state.Main.month,
    }),
  },
  data: () => {
    return {
      blacklist: [],
      search: '',
      search_timeout: undefined,
    }
  },
  mounted() {
    this.GetBlackList();
  },
  methods: {
    ...mapMutations(['SetPopupData',]),
    ...mapActions(['GetCall', 'DeleteCall']),
    nth(n) {
      return n > 3 && n < 21 ? "th" : n % 10 == 2 ? "nd" : n % 10 == 2 ? "nd" : n % 10 == 3 ? "rd" : "th"
    },
    GetBlackList() {

      let search_str = (this.search && this.search.length > 0) ? `?q=${this.search}` : '';
      console.log('Blacklist: GetBlackList: search_str:', search_str);

      this.GetCall({
        endpoint: '/users/blacklist' + search_str,
        callback: (data) => {
          this.blacklist = Array.from(data.rows);
        },
        on_error: (error) => {
          let error_status = error.status;
          console.log(error_status);
        },
      })
    },
    Unblock(id) {
      this.SetPopupData({
        popup_additional_data: {
          blocked: id,
          callback: () => {
            this.GetBlackList();
          }
        },
        private_flag: false,
        popup_name: 'delete_block',
        popup_opened: true,
      })
    }
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (this.search_timeout) {
            clearTimeout(this.search_timeout);
          }
          this.search_timeout = setTimeout(() => {
            this.GetBlackList();
          }, 300);
        }
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
