<template>
  <dialog class="popup is-opened" id="comment">
    <div class="popup-box" style="max-width: 464px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Send invite</div>
      <div class="form">
        <div class="popup-content">
          <fieldset>
            <textarea class="textfield autosize" rows="1" v-model="invite_text">
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here
            </textarea>
          </fieldset>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <div class="button" @click="Send">Send</div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PSendInvite",
  data: function () {
    return {
      is_open: false,
      invite_text: ''
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      invite_send_obj: state => state.Invites.invite_send_obj,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData',]),
    ...mapActions(['PostCall', 'SendInvite']),

    Send() {
      let trimmed = '';
      if (this.invite_text) {
        trimmed = this.invite_text.trim();
      }

      if (trimmed && trimmed.length > 0 && trimmed !== '') {
        let sendObj = {
          is_invite: true,
          user_id: this.invite_send_obj.user_id,
          message: trimmed,
          callback: () => {
            this.hide();
          }
        }
        this.SendInvite(sendObj);
      }
    },

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>
