<template>
  <section class="box">
    <div class="box-header">
      <div class="box-header-item">
        <h3>A few words about myself</h3>
      </div>
    </div>
    <div class="box-content">
      <form class="form">
        <fieldset>
          <GTextField
              v-model="text"
              placeholder=""
              class="textfield"
          />
          <!--          <textarea class="textfield autosize" rows="1" v-model="text"></textarea>-->
        </fieldset>
        <!--        <GButton text="Save" class="button" @click="Save"/>-->
      </form>
    </div>
  </section>
</template>

<script>
import {mapActions, mapState} from "vuex";
import GTextField from "@/G_UI/GTextField";
// import GButton from "@/G_UI/GButton";

export default {
  name: "UP_FewWords",
  components: {GTextField},
  data: function () {
    return {
      text: '',
      server_update: false,
    }
  },
  computed: {
    ...mapState({
      user_data: state => state.User.user_data,
    }),
  },
  mounted() {
    if (this.user_data) {
      if (this.user_data.about_text !== null) {
        this.server_update = true;
        this.text = this.user_data.about_text;
        setTimeout(() => {
          this.server_update = false
        }, 100);
      }
    }
  },
  methods: {
    ...mapActions(['PutCall']),

    Save() {
      // e.preventDefault();

      if (!this.server_update) {
        let form = {
          key: 'about_text',
          value: this.text
        }

        this.PutCall({
          endpoint: '/users/update',
          options: form,
          callback: (data) => {
            console.log(data);
            // this.SetUserData(data)
          },
          on_error: (error) => {
            let error_status = error.response.status;
            console.log(error_status);
          },
        })
      }
    }
  },
  watch: {
    text: {
      handler(val, old) {
        //console.log(val,old,this.server_update);
        if (!this.server_update) {
          if (val !== old) {
            if (this.update_timeout !== undefined) {
              clearTimeout(this.update_timeout);
            }
            this.update_timeout = setTimeout(() => {
              this.Save();
            }, 1000);
          }
        }
      },
      deep: false
    },
    user_data: {
      handler(val) {
        if (val) {
          if (val.about_user !== null) {
            this.server_update = true;
            this.text = val.about_user;
            setTimeout(() => {
              this.server_update = false
            }, 100);
          }
        }
      },
      deep: true
    },
  },

}
</script>

<style scoped>

</style>
