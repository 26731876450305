<template>
  <div class="notifications-fixed">
    <template v-for="(value, index) in alerts_stack" v-bind:key="index">
      <Alert :alert="value"/>
    </template>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Alert from "@/components/Alerts/Alert.vue";

export default {
  name: "Alerts",
  components: {
    Alert,
  },
  computed: {
    ...mapState({
      alerts_stack: state => state.Alerts.alerts_stack,
    }),
  },
  data: function () {
    return {
      alerts_stack_id: [],
      alerts_to_view: 3,
    }
  },
  methods: {
    ...mapMutations(['SetAlertsData']),
  },
  watch: {
    alerts_stack: {
      handler(newVal) {
        if (newVal.length > this.alerts_to_view) {
          for (let i = 0; i < newVal.length - this.alerts_to_view; i++) {
            this.SetAlertsData({
              hide_id: newVal[i].id
            });
          }
        }
        this.$nextTick(() => {
          console.log(newVal)
          if (newVal.length > this.alerts_stack_id.length) {
            for (let i = this.alerts_stack_id.length; i < newVal.length; i++) {
              if (newVal[i].state !== 'hide') {
                setTimeout(() => {
                  this.SetAlertsData({
                    show_id: newVal[i].id
                  });
                }, 500 * (i - this.alerts_stack_id.length))
              }
            }
          }
          this.alerts_stack_id = Array.from(newVal.map(e => e.id));
        })

      },
      deep: true
    },
  }

}

</script>
<style scoped></style>