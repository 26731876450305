<template>
  <main class="intro">
    <button type="button" class="button button-black-transparent intro-video-toggle"></button>
    <!--
    <video id="intro-video" preload="auto" autoplay loop playsinline muted>
      <source src="video/intro.mp4" type="video/mp4">
      <source src="video/intro.webm" type="video/webm">
    </video>
    -->
    <div class="vimeo-wrapper">
      <iframe src="https://player.vimeo.com/video/640014466?background=1&autoplay=1&loop=1&byline=0&title=0&muted=1"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"></iframe>
    </div>
    <header class="intro-logo">
      <h1 style="opacity: 0;position: fixed;">Dating.Wedding</h1>
      <img src="img/intro-logo.svg" alt="">
    </header>
    <aside class="intro-box">
      <!--
            <h1 class="intro-info">
              <span class="intro-info-number">240 136</span>
              <span class="intro-info-text">people have already joined, <strong>jump in!</strong></span>
            </h1>
      -->
      <template v-if="session">
        <div class="intro-header">You authorized</div>
        <ul class="intro-buttons">
          <li>
            <div class="button button-block" @click="GoProfile">
              Open profile
            </div>
          </li>
          <li>
            <div class="button button-block button-transparent" @click="Logout">
              Logout
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="intro-header">sign up / Sign in</div>
        <ul class="intro-buttons">
          <li>
            <div class="button button-block button-telegram">
              Sign in with Telegram
              <telegram-login-temp
                  mode="callback"
                  :telegram-login="telegram_login"
                  @loaded='telegramLoadedCallbackFunc'
                  @callback="telegramCallbackFunction"
              />
            </div>
          </li>
          <li>
            <div class="button button-block button-google">
              Sign in with Google
              <GoogleLogin :callback="googleLoadedCallback" :client-id="google_cid"/>
            </div>
          </li>
        </ul>
      </template>
      <small class="intro-terms">By accessing the Site and/or using any other part of the Service, you agree to be bound by these <a
          href="/terms_and_conditions.pdf">terms and conditions</a></small>
    </aside>
  </main>
</template>

<script>
import {telegramLoginTemp} from 'vue3-telegram-login'
import {mapActions, mapMutations, mapState} from "vuex";
import {GoogleLogin} from "vue3-google-login";

export default {
  name: "Main",
  components: {
    GoogleLogin,
    telegramLoginTemp
  },
  data: function () {
    return {
      session: false,
    }
  },
  computed: {
    ...mapState({
      telegram_login: state => state.Main.telegram_login,
      google_cid: state => state.Main.google_cid,
    }),
  },
  mounted() {
    this.GetCall({
      endpoint: '/auth/status',
      callback: (data) => {
        console.log('Main: Status: ', data);
        this.session = !!(data && data.auth);
      },
      on_error: (error) => {
        console.log(error);
      },
    });
  },
  methods: {
    ...mapMutations(['SetUserData']),
    ...mapActions(['GetCall', 'PostCall','Logout']),
    GoProfile(){
      this.$router.push({path: '/user-profile'});
    },
    googleLoadedCallback(response) {
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup
      console.log("Handle the response", response)
      this.SetUserData({auth_pending: true});
      this.PostCall({
        endpoint: '/auth/google',
        options: response,
        callback: (data) => {
          this.SetUserData({auth_pending: false});
          if (data.result === 'ok') {
            this.SetUserData({is_auth: true});
            this.$router.push({path: '/user-profile'});
          }
        },
        on_error: (error) => {
          console.log(error);
        },
      });
    },
    telegramLoadedCallbackFunc() {
      console.log('script is loaded')
    },
    telegramCallbackFunction(user) {
      // gets user as an input
      // id, first_name, last_name, username,
      // photo_url, auth_date and hash
      console.log(user)//https://dating.mir.digital/telegram/login

      this.SetUserData({auth_pending: true});
      this.PostCall({
        endpoint: '/auth/telegram',
        options: user,
        callback: (data) => {
          this.SetUserData({auth_pending: false});
          if (data.result === 'ok') {
            this.SetUserData({is_auth: true});

            // let l_storage = window.localStorage;
            // l_storage.setItem('token', data.access_token);
            this.$router.push({path: '/user-profile'});
            // setTimeout(()=>{
            // },3000);
          }
        },
        on_error: (error) => {
          console.log(error);
        },
      });
    }
  }
}
</script>

<style scoped>

</style>
