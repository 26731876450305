<template>
  <dialog class="popup" id="add-photos"
          :class="is_open ? 'is-opened' : ''">
    <div class="popup-box " style="max-width: 464px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header" v-if="popup_additional_data.agency_name">The agency "{{popup_additional_data.agency_name}}"<br>offers you cooperation</div>
      <div class="popup-header" v-else>The agency offers you cooperation</div>
      <div class="popup-content">
        <ul class="popup-buttons-centered">
          <li>
            <button type="button" class="button button-block button-block-on" @click="Dismiss">Dismiss</button>
          </li>
          <li>
            <button type="button" class="button button-block button-reply" @click="Accept">Accept</button>
          </li>
        </ul>
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PAgencyCall",
  data: function () {
    return {
      is_open: false,
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth,
      popup_additional_data: state => state.Popups.popup_additional_data,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData',]),
    ...mapActions(['PutCall',]),


    Dismiss() {
      this.Call(false);
    },
    Accept() {
      this.Call(true);
    },

    Call(accept) {
      this.PutCall({
        endpoint: '/users/agency',
        options: { accept: accept },
        callback: () => {
          this.hide();
          this.$router.go(this.$router.currentRoute);
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },


    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({ popup_opened: false });
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if ( newVal === false ) {
        this.hide();
      }
    },
  },

}
</script>

<style scoped>

</style>
