<template>
  <main>
    <CommunicationTabs/>
    <div class="container">
      <section class="chat" :class="open_mobile?'is-shifted':''">
        <CHT_LeftPart/>
        <CHT_Top/>
        <CHT_Window/>
        <CHT_SendField/>
      </section>
    </div>
  </main>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import CHT_LeftPart from "./Parts/CHT_LeftPart.vue";
import CHT_Top from "./Parts/CHT_Top";
import CHT_Window from "./Parts/CHT_Window";
import CHT_SendField from "./Parts/CHT_SendField";
import CommunicationTabs from "../Misc/CommunicationTabs";


export default {
  name: "Chat",
  components: {CommunicationTabs, CHT_SendField, CHT_Window, CHT_Top, CHT_LeftPart},
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      chats_list: state => state.Chat.chats_list,
      open_mobile: state => state.Chat.open_mobile,
      chat_page_size: state => state.Chat.chat_page_size,
    }),
  },
  mounted() {
    this.SetCommunicationData({communication_type: 1});
    this.SetIdFromUrl(this.id);
  },
  methods: {
    ...mapMutations(['SetChatData', 'SetCommunicationData']),
    ...mapActions(['UpdateChat']),
    SetIdFromUrl(newVal) {
      let elem = this.chats_list.find(item => item.user_id.toString() === newVal.toString());
      if (elem) {
        this.SetChatData({chat: elem, chat_id: newVal});
        // this.UpdateChat({user: elem, options: {size: this.chat_page_size, page: 0}});
        this.UpdateChat({
          user: elem,
          options: {size: this.chat_page_size},
          callback: (data) => {
            console.log('Chat: SetIdFromUrl: callback: ', data);
          }
        });
      } else {
        this.SetChatData({chat_id: newVal});
      }
    },
  },
  watch: {
    // id: {
    //   handler(newVal) {
    //     this.SetIdFromUrl(newVal);
    //   },
    //   deep: false
    // },
  },
}
</script>

<style scoped>

</style>
