export default {
  state: {
    popup_name: '',
    popup_opened: false,
    private_flag: false,
    popup_additional_data: null,

    overlay: false,
    overlay_target: '',

    photo_view_data: [], // массив фоток для отображения в слайдере просмотра
    photo_view_index: 0, // индекс фотки на которой должен открытся слайдер просмотра фоток
    video_view_data: [], // массив видео для отображения в слайдере просмотра
    video_view_index: 0, // индекс видео на которой должен открытся слайдер просмотра фоток
  },
  mutations: {
    SetPopupData(state, data) {
      if ( data.popup_name !== undefined ) {
        console.log('Set Popup Name', data.popup_name);
        state.popup_name = data.popup_name;
      }
      if ( data.popup_opened !== undefined ) {
        console.log('Set Popup Status', data.popup_opened);
        state.popup_opened = data.popup_opened;
        state.overlay = data.popup_opened;
      }
      if ( data.private_flag !== undefined ) {
        state.private_flag = data.private_flag;
      }
      if ( data.photo_view_data !== undefined ) {
        state.photo_view_data = Array.from(data.photo_view_data);
      }
      if ( data.photo_view_index !== undefined ) {
        state.photo_view_index = data.photo_view_index;
      }
      if ( data.video_view_data !== undefined ) {
        state.video_view_data = Array.from(data.video_view_data);
      }
      if ( data.video_view_index !== undefined ) {
        state.video_view_index = data.video_view_index;
      }
      if ( data.popup_additional_data !== undefined ) {
        state.popup_additional_data = {};
        state.popup_additional_data = { ...data.popup_additional_data };
      }
    },
  },
  actions: {},
  getters: {}
}
