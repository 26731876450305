<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PAddPhotos",
  data: function () {
    return {
      is_open: false,
      description: '',
      files: null,
      files_src: null,
      image: null,
      block: false,

      upload: false,
      uploaded: 0,

      errors: {
        description: false,
        file_src: false,
      }
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData']),
    ...mapActions(['PostCall',]),

    FileAdded(e) {
      this.files_src = [];
      this.files = e.target.files || e.dataTransfer.files;
      for (let file of this.files) {
        this.files_src.push(URL.createObjectURL(file));
      }
      this.errors.file_src = false;
      // if (files.length > 0) {
      //   this.file = files[0];
      //   this.file_src = URL.createObjectURL(this.file);
      //   this.errors.file_src = false;
      // }
    },

    ClearFile() {
      this.files = null
      this.files_src = [];
    },

    Save(e) {
      e.preventDefault();
      if (this.files) {
        if (this.CheckInputs()) {
          this.block = true;
          this.upload = true;
          this.SendFile(this.files[0], 0)
        }
      } else {
        this.SetPopupData({
          popup_additional_data: {
            text: 'Please select image',
          },
          private_flag: false,
          popup_name: 'error',
          popup_opened: true,
        })
      }
    },
    SendFile(file, index) {
      this.uploaded = index;
      const formData = new FormData();

      formData.append('type', 'photos');
      formData.append('description', '');
      formData.append('file', file, `photo.png`);
      this.PostCall({
        endpoint: '/users/photos',
        options: formData,
        callback: (data) => {
          if (data) {
            this.SetUserData({photos: data,toggle_photo:true});
          }
          if (index < this.files.length - 1) {
            this.SendFile(this.files[index + 1], index + 1);
          } else {
            this.upload = false;
            this.hide();
          }
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },

    CheckInputs() {
      let ret = true;

      if (this.files === null || this.files === undefined) {
        ret = false;
        this.errors.file_src = true;
      }
      // if (this.description === null || this.description === '') {
      //   ret = false;
      //   this.errors.description = true;
      // } else {
      //   this.errors.description = false;
      // }

      return ret;
    },

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },

}
</script>

<template>
  <dialog class="popup" id="add-photos" :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 480px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Add photos</div>
      <form class="form">
        <div class="popup-content">
          <!--          <div class="popup-icons">-->
          <!--            <img src="img/facebook.svg" alt="">-->
          <!--          </div>-->
          <fieldset>
            <template v-if="!upload">
              <label v-if="files === null">Select photos</label>
              <label v-else>Selected photos</label>
            </template>
            <template v-else>
              <label>Uploaded {{ uploaded }}/{{ files.length }} photos</label>
            </template>
            <div class="popup-images">
              <template v-for="(file_src,index) in files_src" :key="index">
                <div class="popup-image micro-photo" v-if="file_src">
                  <img :src="file_src">
                </div>
              </template>
            </div>
<!--
            <template v-if="files !== null">
              <div class="popup-info-text-margin">
                <template v-for="(file,index) in files" :key="index">
                  {{ file.name }}{{ (index + 1 < files.length ? ', ' : '') }}
                </template>
              </div>
            </template>
-->
            <div class="textfield-clear file-input-holder" :class="errors.file_src?'error':''">
              <input type="file" class="file-input" @change="FileAdded" accept=".png,.jpg" multiple>
              <span v-if="files === null">*.png,*.jpg</span>
              <span v-else class="file-selected">Files selected</span>
              <button v-if="files !== null" type="button" @click="ClearFile"></button>
            </div>
          </fieldset>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottom-buttons">
            <div class="button" @click="Save">Save photos</div>
          </div>
        </div>
      </form>
      <div class="popup-cover" v-if="block">
        <div class="spinner"></div>
      </div>
    </div>
  </dialog>
</template>

<style scoped>

</style>
