export default {
  state: {
    communication_type: -1, //тип чата 0 инвойс/ 1 чат / 2 письмо

    photo_selected: [],
    photo_selected_target: null,

    video_selected: [],
    video_selected_target: null,
  },
  mutations: {
    SetCommunicationData(state, data) {
      console.log('Communication: SetCommunicationData', data);

      if ( data.communication_type || data.communication_type === 0 ) {
        state.communication_type = data.communication_type;
      }
      if ( data.write_letter !== undefined ) {
        state.write_letter = data.write_letter;
      }
      if ( data.photo_selected ) {
        state.photo_selected = Array.from(data.photo_selected);
      }
      if ( data.photo_selected_target ) {
        state.photo_selected_target = data.photo_selected_target;
      }
      if ( data.video_selected ) {
        state.video_selected = Array.from(data.video_selected);
      }
      if ( data.video_selected_target ) {
        state.video_selected_target = data.video_selected_target;
      }
    },
    ResetCommData(state) {
      // state.chat_id = '';
      state.communication_type = 1;
      // state.chat_num = 0;
      // state.chat_users_list = null;
      // state.chat_user = null;
      // state.chat_data = null;
      // state.letter = null;
      state.write_letter = false;
      // state.write_letter_user = null;
      state.photo_selected = [];
      state.photo_selected_target = null;
      state.video_selected = [];
      state.video_selected_target = null;
    },
  },
  actions: {},
  getters: {}
}
