<template>
  <div class="notification" :class="alert.state" v-if="alert">
    <template v-if="alert.type==='message'">
      <div class="notification-image is-online">
        <picture v-if="alert && alert.img">
          <!--        <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
          <!--        <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
          <img :src="static_server + '/' + alert.img" alt="">
        </picture>
      </div>
      <div class="notification-content">
        <div class="notification-header">{{ alert.header }}</div>
        <div class="notification-text notification-overflow">{{ alert.text }}</div>
        <div v-if="button_timed_out" @click="MessageClicked" class="button button-block button-reply">{{ alert.link_name }}</div>
      </div>
    </template>
    <template v-if="alert.type==='letter'">
      <div class="notification-image is-online">
        <picture v-if="alert && alert.img">
          <!--        <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
          <!--        <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
          <img :src="static_server + '/' + alert.img" alt="">
        </picture>
      </div>
      <div class="notification-content">
        <div class="notification-header">{{ alert.header }}</div>
        <div class="notification-text notification-overflow">{{ alert.text }}</div>
        <div v-if="button_timed_out" @click="LetterClicked" class="button button-block button-reply">{{ alert.link_name }}</div>
      </div>
    </template>

    <template v-if="alert.type==='system'">
      <div class="notification-image">
        <img src="/img/warning.svg" alt="">
      </div>
      <div class="notification-content">
        <div class="notification-header">{{ alert.header }}</div>
        <div class="notification-text">{{ alert.text }}</div>
        <a href="#" class="button button-block button-wallet">{{ alert.link_name }}</a>
      </div>
    </template>
    <template v-if="alert.type==='refill'">
      <div class="notification-image">
        <img src="/img/buy-coins-1.svg" alt="">
      </div>
      <div class="notification-content">
        <div class="notification-header">{{ alert.header }}</div>
        <div class="notification-text">{{ alert.text }}</div>
        <!--        <a href="#" class="button button-block button-wallet">{{ alert.link_name }}</a>-->
      </div>
    </template>

    <div class="close-btn" @click="Close"></div>
  </div>

</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "Alert",
  props: {
    alert: Object,
    /*
    * alert:{
    * type //system, message
    * img
    * header
    * text
    * link_name,
    * uid
    * }
    * */
  },
  data() {
    return {
      button_timed_out: false
    };
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.button_timed_out = true;
    }, 500);
  },
  methods: {
    ...mapMutations(['SetAlertsData']),
    MessageClicked() {
      this.$router.push({path: `/chat/${this.alert.uid}`});
      this.Close();
    },
    LetterClicked() {
      this.$router.push({path: `/letters/${this.alert.uid}`});
      this.Close();
    },
    Close() {
      this.SetAlertsData({
        hide_id: this.alert.id
      });
      setTimeout(() => {
        this.SetAlertsData({
          remove_id: this.alert.id
        });
      }, 500);
    }
  },
}
</script>

<style scoped></style>
